.collectible-modal {
  width: 640px !important;
  .ant-modal-content {
    border-radius: 16px;
    padding: 20px 0;
    background-color: #f9f9f9;
    label {
      font-family: ClashGrotesk-Regular;
      color: #3f3f46;
      font-size: 16px;
      margin-top: 20px;
      margin-bottom: 6px;
      font-weight: 600;
      &:first-of-type {
        margin-top: 0px;
      }
    }

    .upload-btn {
      max-width: 104px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .upload-text {
        font-family: ClashGrotesk-Regular;
        font-size: 9px;
      }
      .plus-icon-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 41px;
        height: 41px;
        border-radius: 360px;
        background-color: #e4e4e7;
      }
    }
    .buttons {
      display: flex;
      > div {
        margin-top: 30px;

        &:first-of-type {
          margin-right: 12px;
        }
      }
    }
    input,
    textarea {
      padding: 10px 14px;
      font-family: ClashGrotesk-Regular;
      border-radius: 20px;
      border: 1px solid #d1d1d6;
      line-height: 14px;
      background-color: #f9f9f9;
    }
    textarea {
      min-height: 90px;
    }

    .inner-nft {
      justify-content: space-between !important;
      align-items: flex-start !important;
      .loader-part {
        width: 60% !important;
        > div {
          b {
            color: #51525c;
            font-weight: normal;
            font-size: 14px;
          }
        }
        > div > div:last-of-type {
          width: 220px !important;
        }
        #thumbnail-input {
          display: none;
        }
      }
    }

    .explanatory {
      font-family: ClashGrotesk-Regular;
      font-weight: normal;
      font-size: 14px;
    }
    .ant-modal-header {
      border: none;
      > div {
        font-family: MicrogrammaStd-BoldExtended;
        font-size: 20px !important;
      }
    }
    .ant-modal-body {
      display: flex;
      flex-direction: column;
    }
    .overflow-part {
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      max-height: 400px;
      #form-file-upload-nft {
        #label-file-upload {
          min-height: 188px;
        }
      }
      .supply-box {
        width: 100%;
        input {
          width: 100%;
        }
      }
      .not-allowed-supply {
        input {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }
      .supply-label {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-top: 15px;
        .switchLabel {
          margin: 0px 5px;
          opacity: 0.7;
          font-family: ClashGrotesk-Regular;
        }
        .switchLabelOpactiyText {
          margin: 0px 5px;
          opacity: 1;
        }
      }
      .cap-box {
        width: 100%;
        input {
          width: 100%;
        }
        .not-allowed-supply{
          input {
            opacity: 0.5;
            cursor: not-allowed;
          }
        }
      }
    }
  }
}
