.indicator-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  .icon-wrapper {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    width: 50px;
    aspect-ratio: 1;
    justify-content: center;
    background-color: white;
    border-radius: 60px;
    margin-bottom: 20px;
    transform-style: preserve-3d;
    .rotate {
      transform: rotate(180deg);
    }
    &::before {
      content: "";
      border-radius: 60px;
      width: 80px;
      aspect-ratio: 1;
      position: absolute;
      background-color: rgba(255, 255, 255, 0.493);
      transform: translateZ(-1px);
      animation: pulseanimation 0.7s ease-in-out infinite;
    }
  }
}
@keyframes pulseanimation {
  0% {
    width: 50px;
  }
  50% {
    width: 80px;
  }
  100% {
    width: 50px;
  }
}
