.wrapper {
  width: 100%;
  margin-top: 55px;
  button {
    span {
      font-family: "MicrogrammaStd-BoldExtended";
      font-size: 16px;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    width: 100%;
    height: 48px;
    border-radius: 60px;
    font-size: 16px;
    background-color: #f3ec00;
    font-family: "MicrogrammaStd-BoldExtended";
    padding: 0 20px;
    border: 1px solid black;
    display: flex;
    align-items: center;
    transition: all 150ms linear;
    color: black;
    svg {
      margin-right: 4px;
    }
    .rightIcon {
      position: absolute;
      right: 8px;
    }
    .leftIcon {
      position: absolute;
      left: 8px;
    }
  }
}
.leftAligned {
  button {
    display: flex;
    justify-content: flex-start;
  }
}
.rightAligned {
  button {
    display: flex;
    justify-content: flex-end;
  }
}
.noLabel {
  width: 60px;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  img {
    position: relative;
    left: auto !important;
  }
}
.disabled {
  button {
    border-color: #fffc8d;
    background-color: #fffc8d;
    color: #d4bd00;
  }
}
.noBackground {
  button {
    background-color: transparent;
  }
}
