.book-a-meet-wrapper {
  display: flex;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: #f9f9f9;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    text-transform: none !important;
    font-size: 36px;
    text-align: center;
    margin-top: 48px;
    margin-bottom: 24px;
  }
  h6 {
    font-family: "ClashGrotesk-Regular";
    font-size: 18px;
    width: 33%;
    text-align: center;
    margin-bottom: 48px;
  }
  img {
    width: 120px;
    height: 124px;
  }
  .bottom-part {
    display: flex;
    justify-content: space-between;
    width: 60%;
    align-items: center;
    .left-icon {
      img {
        width: 120px;
      }
    }
    .right-icon {
      img {
        margin-top: -200px;
        width: 100px;
      }
    }
    .buttons {
      display: flex;
      flex-direction: column;
      margin-bottom: 40px;
      div[class^="Button"] {
        margin-top: 20px;
        width: 400px;
        button {
          justify-content: center;
        }
        img {
          position: relative;
          width: 18px;
          height: 18px;
          left: 0px;
          margin-right: 15px;
        }
      }
    }
  }
}
