.playbook-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70vw;
  height: 100%;
}
.create-raffle-wrapper {
  width: 70vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;

  .create-raffle-header {
    position: absolute;
    top: 0px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    z-index: 2;
    padding: 32px 10% 12px 10%;
    background-color: #f9f9f9;
    div[class^="Button"] {
      margin-top: 0px;
      width: fit-content;
      button {
        width: 124px;
        height: 44px;
        img {
          margin-right: 28px;
        }
      }
    }
  }
  .raffle-editor {
    width: 720px;
    display: inline;
    padding-top: 30px;
    @media screen and (min-width: 690px) and (max-width: 980px) {
      width: 590px !important;
    }
    .raffle-title {
      margin-top: 50px;
      font-family: MicrogrammaStd-BoldExtended;
      font-size: 30px;
      display: flex;
      .edit-icon {
        margin-left: 16px;
        background-color: #ececed;
        width: 48px;
        height: 48px;
        border-radius: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
  }
  .section {
    margin-top: 32px;
    border: 1px solid var(--gray-300, #d1d1d6);
    border-radius: 32px;
    padding: 32px;
    position: relative;
    transition: all 100ms ease-in;
    cursor: pointer;
    .step-complete {
      position: absolute;
      top: 32px;
      right: 32px;
      div[class^="Button"] {
        margin-top: 0px;
      }
    }
    .top-part {
      display: flex;
      .icon {
        border-radius: 44px;
        background: var(--gray-150, #ececed);
        width: 64px;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 16px;
        svg {
          width: 40px;
          height: 40px;
        }
      }
      .title-description {
        color: #18181b;
        h3 {
          font-family: MicrogrammaStd-BoldExtended;
          font-size: 24px;
          margin-bottom: 0px;
        }
        h5 {
          font-family: ClashGrotesk-Regular;
          color: #3f3f46;
          font-size: 16px;
        }
      }
    }
  }
  .access-rules {
    .selector-wrapper {
      margin-top: 24px;
      position: relative;
      .chip-holder {
        cursor: pointer;
        border-radius: 24px;
        border: 1px solid var(--gray-300, #d1d1d6);
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 14px;
        font-family: ClashGrotesk-Regular;
        color: #70707b;
        .left-part {
          display: flex;
        }
        .tag {
          border: 1px solid #000000;
          padding: 2px 10px;
          border-radius: 60px;
          margin-right: 6px;
          display: flex;
          align-items: center;
          svg {
            margin-left: 8px;
            cursor: pointer;
          }
        }
      }
      .raffle-segment-dropdown {
        top: 54px;
        left: 0px;
        margin-left: 0px;
      }
    }
  }
  .access-page {
    .access-page-info {
      display: flex;
      flex-direction: column;
      margin-top: 24px;
      border-radius: 20px;
      border: 1px solid var(--gray-300, #d1d1d6);
      padding: 24px;
      h3 {
        font-family: MicrogrammaStd-BoldExtended;
        font-size: 20px;
      }
      .avatar-uploader {
        width: 140px;
        height: 140px;
        position: relative;
        .remove-access-img {
          width: 32px;
          height: 32px;
          background-color: #18181b;
          position: absolute;
          top: -10px;
          right: -10px;
          border-radius: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          svg {
            width: 20px;
            height: 20px;
            path {
              stroke: white;
            }
          }
        }
        .ant-upload {
          width: 100%;
          height: 100%;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .access-title {
        margin-top: 16px;
        height: 52px;
        border-radius: 24px;
        border: 1px solid var(--gray-300, #d1d1d6);
        background: var(--gray-50, #f9f9f9);
        padding: 10px 16px;
        font-family: ClashGrotesk-Medium;
        font-size: 24px;
      }
      textarea {
        margin-top: 16px;
        border-radius: 24px;
        height: 154px;
        border: 1px solid var(--gray-300, #d1d1d6);
        background: var(--gray-50, #f9f9f9);
        font-family: ClashGrotesk-Regular;
        padding: 12px 14px;
        font-size: 16px;
        color: #18181b;
      }

      .max-20 {
        font-family: ClashGrotesk-Regular;
        font-weight: normal;
        color: #51525c;
        font-size: 14px;
      }
    }
  }
  .appearance-editor {
    border: 1px solid #d1d1d6;
    border-radius: 24px;
    margin-top: 24px;
    padding: 24px;
    h3 {
      font-family: MicrogrammaStd-BoldExtended;
      font-size: 20px;
    }
    .appearance-fields {
      .appearance-field {
        margin-top: 16px;
        display: flex;
        b {
          width: 33%;
          font-family: ClashGrotesk-Medium;
          color: #3f3f46;
        }
        .field-type {
          width: 60%;

          .color-picker {
            position: relative;
            border-radius: 24px;
            padding: 10px 14px;
            cursor: pointer;
            display: flex;
            border: 1px solid #d1d1d6;
            height: 44px;
            align-items: center;
            font-family: ClashGrotesk-Medium;
            font-size: 16px;
            .color-circle {
              margin-right: 8px;
              border-radius: 60px;
              width: 24px;
              aspect-ratio: 1;
              border: 1px solid #d1d1d6;
            }
            .color-picker-sketch {
              position: absolute;
              top: 100%;
              z-index: 9;
            }
          }
          .raffle-input {
            border-radius: 24px;
            padding: 10px 14px;
            display: flex;
            border: 1px solid #d1d1d6;
            height: 44px;
            align-items: center;
            font-family: ClashGrotesk-Medium;
            font-size: 16px;
            background-color: transparent;
            width: 100%;
          }
        }
        div[class^="Button"] {
          width: fit-content;
          margin-top: 0px;
          button {
            height: 40px !important;
            font-family: MicrogrammaStd-BoldExtended;
          }
        }
      }
    }
  }

  .enabled {
    background: var(--gray-50, #f9f9f9);
    box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
      0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  }
}
