.hotline-builder {
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  background-color: #f9f9f9;
  .builder {
    width: 55%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 5%;
    height: 100%;
    padding-top: 8%;
    .page-title {
      font-family: MicrogrammaStd-BoldExtended;
      margin-bottom: 5%;
      font-size: 24px;
    }
    .images-upload-section {
      width: 30%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }
  }
  .preview {
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f3ec00;
    height: 100%;
    .preview-container {
      position: relative;
      width: 372px;
      height: 730px;
      display: flex;
      align-items: center;
      justify-content: center;
      .frame {
        position: absolute;
        width: 110%;
        height: 110%;
      }
      .desktop-wrapper {
        display: none;
      }
      .landing-template-1-background {
        border-radius: 20px !important;
        height: 765px !important;
        top: -18px;
        .content-wrapper {
          bottom: 70px !important;
        }
      }
      .landing-template-1-wrapper {
        width: 100%;
        height: 760px !important;

        // background-position: center center;
        // background-size: cover;
      }
    }
  }
}
