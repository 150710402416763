.playbook-stop-modal {
  .ant-modal-body {
    .icon-holder {
      background-color: #fee4e2;
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 60px;
      svg {
        width: 20px;
        height: 20px;
        fill: #d92d20;
      }
    }
    .modal-btns {
      display: flex;
      div {
        margin-top: 24px;
        &:first-of-type {
          margin-right: 12px !important;
        }
      }
    }
  }
}
