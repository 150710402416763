.goals-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  .goals-objective {
    display: flex;
    align-items: center;
    margin-top: 5%;
    font-size: 32px;
    text-transform: none !important;
  }
  .goals-items {
    display: flex;
    margin-top: 5%;
    .goals-item {
      width: 250px;
      height: 170px;
      border-radius: 24px;
      display: flex;
      flex-direction: column;
      text-align: left;
      font-size: 16px;
      cursor: pointer;
      margin-right: 24px;
      text-transform: none;
      font-family: "MicrogrammaStd-BoldExtended";
      padding-left: 24px;
      padding-top: 24px;
      b {
        width: 85%;
      }
      img {
        width: 56px;
        aspect-ratio: 1;
        margin-bottom: 12px;
      }
    }
  }
  .footer-icon {
    position: absolute;
    bottom: 120px;
  }
}
