.tips-modal {
  .ant-modal-content {
    border-radius: 12px;
    overflow: hidden;
  }
  h2 {
    font-family: "MicrogrammaStd-BoldExtended";
    font-size: 24px;
  }
  p {
    font-family: "ClashGrotesk-Regular";
    font-size: 14px;
    margin-top: 32px;
    li {
      margin-left: 12px;
    }
  }
  div[class^="Button"] {
    width: fit-content;
    margin-top: 32px;
    margin-left: auto;
  }
}
.ant-modal-root {
  position: absolute;
  z-index: 1030 !important;
}
