@import "../../App.scss";

.voter-wrapper {
  display: flex;
  width: 230px;
  height: 58px;
  border-radius: 60px;
  background-color: var(--primary-color);
  overflow: hidden;
  position: absolute;
  bottom: 140px;
  z-index: 5;
  .voter-option {
    width: 50%;
    height: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 31px;
    font-family: var(--primary-font);
    font-weight: 600;
    &:hover {
      filter: saturate(2.2);
    }
  }
  .left {
    border-right: 1px solid #e7e7e7;
  }
  .right {
    border-left: 1px solid #e7e7e7;
  }
  .clicked {
    background-color: rgb(235, 235, 235);
  }
  .column {
    display: flex;
    flex-direction: row !important;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: 22px;

    & > div:nth-child(2) {
      margin-left: 6px;
    }
  }
}
.voter-loading {
  z-index: 0;
  border-radius: 60px;
  overflow: hidden;
  background-color: var(--poll-background-color);
  .voter-option {
    display: none;
  }
  &::before {
    content: "";
    position: absolute;
    z-index: -2;
    left: -50%;
    top: -50%;
    width: 200%;
    height: 200%;
    background-color: white;
    background-repeat: no-repeat;
    background-size: 50% 50%, 50% 50%;
    background-position: 0 0, 100% 0, 100% 100%, 0 100%;
    background-image: linear-gradient(transparent, transparent),
      linear-gradient(transparent, transparent),
      linear-gradient(transparent, transparent), linear-gradient(#fff, #fff);
    animation: rotate 4s linear infinite;
  }

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    left: 3px;
    top: 3px;
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    background: var(--primary-color);
    border-radius: 60px;
  }
}
@media only screen and (max-width: 600px) {
  .voter-wrapper {
    height: 62px;
  }
}
@media only screen and (min-width: 600px) {
  .voter-wrapper {
    bottom: 40px;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}
