.phone-sim {
  width: 300px;
  height: 650px;
  border: 8px solid black;
  border-radius: 30px;
  box-sizing: border-box;
  background-color: #fff;
  .scroll-container {
    height: calc(100% - 105px - 26px - 35px - 35px);
    overflow-y: auto;
    .imessage {
      background-color: #fff;
      border-radius: 30px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      font-size: 14px;
      max-width: 600px;
      padding: 0px 22px;
      min-height: min-content;
      overflow-y: auto;
    }
    .audio-upload {
      padding: 0px;
    }
  }
  .imessage-top-part {
    height: 105px;
    width: 100%;
    background: linear-gradient(
        0deg,
        rgba(249, 249, 249, 0.94),
        rgba(249, 249, 249, 0.94)
      ),
      rgba(30, 30, 30, 0.75);
    border-radius: 30px 30px 0px 0px;

    .status {
      width: 100%;
    }
    .row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 15px;
      box-sizing: border-box;
    }
    .avatar {
      display: flex;
      flex-direction: column;
      align-items: center;
      span {
        margin-top: 4px;
        font-size: 12px;
      }
      img {
        width: 100%;
        width: 40px;
        aspect-ratio: 1;
        border-radius: 60px;
        object-fit: cover;
      }
    }
  }
  .imessage-bottom-part {
    height: 28px;
    width: 100%;
    background: #d1d3d9;
    border-radius: 0px 0px 24px 24px;
    position: relative;
    .home-indicator {
      height: 4px;
      width: 110px;
      background-color: black;
      position: absolute;
      bottom: 7.5px;
      left: 50%;
      margin-left: -55px;
      border-radius: 60px;
    }
  }
  .suggestion {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-color: #d1d3d9;
    img {
      width: 100%;
    }
  }
  .input {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    img {
      width: 100%;
    }
  }

  .imessage p {
    border-radius: 1.15rem;
    line-height: 1.25;
    max-width: 75%;
    padding: 0.5rem 0.875rem;
    position: relative;
    word-wrap: break-word;
    span {
      color: #5975ff;
    }
  }

  .imessage p::before,
  .imessage p::after {
    bottom: -0.1rem;
    content: "";
    height: 1rem;
    position: absolute;
  }

  p.from-me {
    align-self: flex-end;
    background-color: #248bf5;
    color: #fff;
  }
  .contact-info {
    display: flex;
    align-items: center !important;
    line-height: 40px;
    img {
      width: 40px;
      aspect-ratio: 1;
      border-radius: 60px;
      margin-left: 8px;
      object-fit: cover;
    }
  }
  .audio-upload {
    display: flex;
    align-items: center !important;
    line-height: 40px;
    .nft-preview {
      border-radius: 1.15rem;
      overflow: hidden;
      img {
        width: 180px;
        height: 150px;
        object-fit: cover;
      }
      .nft-preview-text {
        padding: 0.5rem 0.875rem;
      }
    }
    .file-btn {
      width: 30px;
      aspect-ratio: 1;
      object-fit: contain;
      margin-right: 8px;
    }
    .play-btn {
      width: 25px;
      aspect-ratio: 1;
      margin-left: 20px;
    }
    div {
      display: flex;
      flex-direction: column;
      span {
        font-size: 9px;
        color: #3c3c4399;
      }
    }
  }

  p.from-me::before {
    border-bottom-left-radius: 0.8rem 0.7rem;
    border-right: 1rem solid #248bf5;
    right: -0.35rem;
    transform: translate(0, -0.1rem);
  }

  p.from-me::after {
    background-color: #fff;
    border-bottom-left-radius: 0.5rem;
    right: -40px;
    transform: translate(-30px, -2px);
    width: 10px;
  }

  p[class^="from-"] {
    margin: 0.5rem 0;
    width: fit-content;
  }

  p.from-me ~ p.from-me {
    margin: 0.25rem 0 0;
  }

  p.from-me ~ p.from-me:not(:last-child) {
    margin: 0.25rem 0 0;
  }

  p.from-me ~ p.from-me:last-child {
    margin-bottom: 0.5rem;
  }

  p.from-them {
    align-items: flex-start;
    background-color: #e5e5ea;
    color: #000;
  }

  p.from-them:before {
    border-bottom-right-radius: 0.8rem 0.7rem;
    border-left: 1rem solid #e5e5ea;
    left: -0.35rem;
    transform: translate(0, -0.1rem);
  }

  p.from-them::after {
    background-color: #fff;
    border-bottom-right-radius: 0.5rem;
    left: 20px;
    transform: translate(-30px, -2px);
    width: 10px;
  }

  p[class^="from-"].emoji {
    background: none;
    font-size: 2.5rem;
  }

  p[class^="from-"].emoji::before {
    content: none;
  }

  .no-tail::before {
    display: none;
  }

  .margin-b_none {
    margin-bottom: 0 !important;
  }

  .margin-b_one {
    margin-bottom: 1rem !important;
  }

  .margin-t_one {
    margin-top: 1rem !important;
  }

  body {
    font-weight: normal;
    margin: 0;
  }

  .container {
    margin: 0 auto;
    max-width: 600px;
    padding: 1rem;
  }

  h1 {
    font-weight: normal;
    margin-bottom: 0.5rem;
  }

  h2 {
    border-bottom: 1px solid #e5e5ea;
    color: #666;
    font-weight: normal;
    margin-top: 0;
    padding-bottom: 1.5rem;
  }

  .comment {
    color: #222;
    font-size: 1.25rem;
    line-height: 1.5;
    margin-bottom: 1.25rem;
    max-width: 100%;
    padding: 0;
  }

  @media screen and (max-width: 800px) {
    body {
      margin: 0 0.5rem;
    }

    .container {
      padding: 0.5rem;
    }

    .imessage {
      font-size: 17px;
      margin: 0 auto 1rem;
      max-width: 600px;
      padding: 0.25rem 0.875rem;
    }

    .imessage p {
      margin: 0.5rem 0;
    }
  }
}
