.overall-card {
  margin-right: 24px;
  cursor: pointer;
  width: 28%;
  height: 480px;
  position: relative;
  .avatar-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 24px;
    img {
      width: 80px;
      aspect-ratio: 1;
      object-fit: cover;
      border-radius: 60px;
    }
    b {
      font-family: "MicrogrammaStd-BoldExtended";
      text-transform: uppercase;
      margin-top: 12px;
      font-size: 20px;
    }
  }
  .items {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    .item {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 20px;
      b {
        font-family: "MicrogrammaStd-BoldExtended";
        text-transform: uppercase;
        font-size: 16px;
      }
      div {
        font-family: "NON-Natural-Grotesk-Regular";
        font-weight: bold;
        margin-top: 4px;
      }
    }
  }
}
.top-stats-card {
  cursor: pointer;
  margin-right: 24px;
  width: 28%;
  height: 480px;
  position: relative;
  .avatar-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 24px;
    b {
      font-family: "MicrogrammaStd-BoldExtended";
      text-transform: uppercase;
      margin-top: 12px;
      font-size: 20px;
      text-align: center;
      width: 70%;
    }
  }
  .items {
    display: flex;
    margin-top: 10px;
    justify-content: center;
    align-items: flex-end;
    .item {
      width: 25%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 20px;
      border: 8px solid black;
      &:first-of-type {
        height: 170px;
      }
      &:nth-of-type(2) {
        height: 220px;
        color: white;
      }
      &:nth-of-type(3) {
        height: 120px;
      }
      b {
        font-family: "MicrogrammaStd-BoldExtended";
        text-transform: uppercase;
        font-size: 16px;
        margin-top: 12px;
      }
      div {
        font-family: "NON-Natural-Grotesk-Regular";
        font-weight: bold;
        margin-top: 4px;
        font-size: 12px;
      }
    }
  }
}
.top-fan-card {
  cursor: pointer;
  margin-right: 24px;
  width: 28%;
  height: 480px;
  position: relative;
  .avatar-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 24px;
    img {
      width: 80px;
      aspect-ratio: 1;
      object-fit: cover;
      border-radius: 60px;
    }
    b {
      font-family: "MicrogrammaStd-BoldExtended";
      text-transform: uppercase;
      margin-top: 12px;
      font-size: 20px;
      text-align: center;
    }
  }
  .subTxt {
    font-family: "NON-Natural-Grotesk-Regular";
    color: white;
    padding: 24px;
    text-align: center;
    margin-top: -24px;
  }
  .action {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    div {
      transform: scale(0.7);
      width: 250px;
    }
  }
}
.card {
  position: relative;
}
.share-to-story {
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 24px;
  justify-content: center;
  font-family: "MicrogrammaStd-BoldExtended";
  text-transform: uppercase;
  font-size: 14px;
  text-decoration: underline;
  img {
    margin-right: 16px;
  }
}
.indicators {
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: 0 24px;
  position: absolute;
  top: 15px;
  .indicator {
    width: 30%;
    height: 4px;
    background-color: black;
  }
  .indicator-active {
    background-color: #f9f9f9;
  }
}
