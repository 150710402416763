.custom-insights-range {
  display: flex;
  width: 55%;
  gap: 10px;
  .custom-rangePicker {
    // display: flex;
    // flex-direction: row-reverse;
    transition: all 150ms ease-in;
    width: fit-content;
    border: 1px solid #000000d9 !important ;
    height: 40px !important;
    border-radius: 60px !important;
    font-size: 14px !important;
    font-family: "ClashGrotesk-Regular";
    font-weight: 600;

    .ant-picker-input > input {
      color: #fff;
    }
    .ant-picker-input > input::placeholder {
      color: #000000d9;
      font-family: "ClashGrotesk-Regular";
    }
    .ant-picker-separator {
      color: #fff;
    }
    .ant-picker-suffix {
      svg {
        path {
          stroke: #000000d9 !important;
        }
      }
    }
    .ant-picker-active-bar,
    .ant-picker-range-separator,
    > div:nth-child(3) {
      display: none;
    }
  }
  .custom-rangePicker-active {
    width: 52%;
    padding: 0px 25px;
    background: #5975ff !important;
    height: 40px !important;
    border-radius: 60px !important;
    font-size: 14px !important;
    font-family: "ClashGrotesk-Regular";
    font-weight: 600;
    > div:nth-child(3) {
      text-align: right;
    }
    .ant-picker-focused {
      box-shadow: none !important;
    }
    .ant-picker-input > input {
      color: #fff;
    }
    .ant-picker-input > input::placeholder {
      color: #fff;
      font-family: "ClashGrotesk-Regular";
    }
    .ant-picker-separator {
      color: #fff;
    }
    .ant-picker-suffix {
      svg {
        path {
          stroke: #fff !important;
        }
      }
    }
    .ant-picker-active-bar {
      display: none;
    }
  }
  div[class^="Button_"] {
    width: 12%;
    min-width: fit-content;
    margin-top: 0px;
    button {
      font-size: 14px;
      font-family: "ClashGrotesk-Regular";
      font-weight: 600;
      padding: 0px 17px;
    }
  }
  .ant-select-selector {
    border: none !important;
    padding: 3px 20px !important;
    height: 40px !important;
    border-radius: 30px !important;
    align-items: center;
    color: #000 !important;
    background-color: transparent !important;
    font-family: "MicrogrammaStd-BoldExtended";
    .ant-select-selection-placeholder {
      color: #000000d9;
      font-family: "MicrogrammaStd-BoldExtended";
    }
  }
  .ant-select-arrow {
    color: #000000d9;
  }
  .ant-select-clear {
    color: #000000d9 !important;
  }
}

.rangePicker-footerBtn {
  display: flex !important;
  justify-content: space-between;
  margin-top: 8px;
  .rangePicker-footerBtn-left {
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 0px 5px 10px;
    .footer-date-display {
      border-radius: 60px;
      padding: 10px;
      background-color: #f9f9f9;
      height: 40px;
      display: flex;
      align-items: center;
      border: 1px solid black;
      font-family: "ClashGrotesk-Regular";
      font-size: 14px;
      width: 116px;
    }
  }
  .rangePicker-footerBtn-right {
    display: flex;
    padding: 0px 5px 10px;
    gap: 12px;
    div[class^="Button"] {
      margin-top: 0px;
      button {
        width: fit-content;
        height: 40px !important;
      }
    }
  }
}

.ant-picker .ant-picker-input span.ant-picker-suffix,
.ant-picker.ant-picker-range span.ant-picker-suffix {
  width: 20px !important;
  height: 20px !important;
  margin-left: 1px;
  margin-right: 10px;
  order: -1;
}

td.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-today {
  border-radius: 20px !important;
  background: var(
    --gradient-primary-70060045-deg,
    linear-gradient(45deg, #f3ec00 0%, #fffc8d 100%)
  );
  border: none !important;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner:before {
  border: none !important;
}
.ant-picker-cell-in-view.ant-picker-cell-in-range:before {
  background: #e3e8ff !important;
  border-radius: 20px;
  height: 30px;
}
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-range-end-single
  ):before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-range-start-single
  ):before {
  background-color: transparent !important;
}
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  background: none !important;
}
td.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-start {
  border-radius: 20px !important;
  background: var(--Indigo-400, #7a91ff) !important;
}
td.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-end {
  border-radius: 20px !important;
  background: var(--Indigo-400, #7a91ff) !important;
}
