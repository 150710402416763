@import "../../App.scss";
.dynamic-button {
  background-color: var(--button-border-color) !important;
  border: 2px solid var(--button-border-color);
  width: var(--button-width);
  margin-top: 14px;
  border-radius: var(--border-radius);
  height: var(--input-height);
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: all 100ms linear;
  .icon-part {
    margin-left: 15px;
    img {
      width: 25px;
      height: 25px;
    }
  }
  .text-part {
    margin-left: -40px;
    font-size: 19px;
    font-family: var(--button-font);
    border-bottom: var(--button-bottom-border);
    padding-bottom: var(--button-bottom-padding);
    margin-top: 3px !important;
    color: var(--button-transparent-font-color);
    text-shadow: var(--button-text-shadow);
  }
  .no-icon {
    margin-left: -15px !important;
  }
}
.primary {
  background-color: #aefc4e;
  height: var(--input-height);
}
.secondary {
  background-color: white;
  height: var(--input-height);
}

.disabled {
  transition: all 100ms linear;
  @if var(--button-transparent) {
    background-color: transparent !important;
    border: 2px solid var(--button-border-color);
  } @else {
    background-color: var(--button-border-color) !important;
  }
  cursor: not-allowed;
  .text-part {
    color: var(--button-font-color);
    text-shadow: none;
  }
}
.text-me {
  cursor: pointer;
  margin-top: 8px;
  color: var(--button-font-color);
  text-align: center;
  text-decoration: underline;
  font-family: var(--button-font);
  font-size: 12px;
}
