input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-internal-autofill-previewed,
input:-internal-autofill-selected {
  transition: background-color 50000000s ease-in-out 0s !important;
  -webkit-box-shadow: 0 0 0 30px #fff inset !important;
  -webkit-text-fill-color: var(--otp-input-border-color) !important;
  background-color: transparent !important;
  background-clip: content-box !important;
  color: var(--otp-input-border-color) !important;
}
textarea,
input.text,
input[type="text"],
input[type="button"],
input[type="submit"],
input[type="tel"],
input[type="number"],
input[type="email"],
.input-checkbox {
  -webkit-appearance: none;
  border-radius: 0;
  background-color: transparent !important;
  color: var(--otp-input-border-color) !important;
  -webkit-text-fill-color: var(--otp-input-border-color) !important;
  background-color: transparent !important;
  color: var(--otp-input-border-color) !important;
  transition: background-color 100000s ease-in-out 0s !important;
}
img,
p,
a,
button,
h1,
h3,
h4,
h5,
h6,
label {
  -webkit-touch-callout: none; /* prevent callout to copy image, etc when tap to hold */
  -webkit-user-select: none;
}

@keyframes modal-appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
//Global Style
.ant-notification {
  z-index: 999999 !important;
  .custom_notification {
    padding: 0px 12px !important;
    height: 64px !important;
    .ant-notification-notice-with-icon {
      display: flex;
      align-items: center;
      height: 64px;
    }
    .anticon.ant-notification-notice-icon-success {
      color: #fff;
    }
    .ant-notification-notice-icon {
      .disable-icon {
        // display: none;
      }
    }
    .ant-notification-notice-message {
      color: white;
      font-family: ClashGrotesk-Regular;
      font-size: 14px;
      margin-bottom: 0px;
      .alignRight-msg {
        margin-left: -35px !important;
      }
    }
    .copy-notif-icon {
      background-color: #494cfd66;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 60px;
      color: #fff;
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
  .custom_notification_withDesc {
    padding: 8px 12px !important;
    .ant-notification-notice-with-icon {
      display: grid !important;
      align-items: center;
    }
    .anticon.ant-notification-notice-icon-success {
      color: #fff;
    }
    .ant-notification-notice-icon {
      .disable-icon {
        display: none;
      }
    }
    .ant-notification-notice-message {
      color: #fff !important;
      font-family: ClashGrotesk-Regular;
      font-size: 14px;
      margin-bottom: 0px;
      .alignRight-msg {
        margin-left: -35px !important;
      }
    }
    .ant-notification-notice-description {
      .alignRight-descp {
        margin-left: -35px !important;
      }
    }
    .copy-notif-icon {
      background-color: #494cfd66;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 60px;
      color: #fff;
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}
div[class^="react-switch-bg"] {
  border: 1px solid black !important;
  width: 40px !important;
  height: 24px !important;
}
div[class*="checked"] {
  div[class^="react-switch-handle"] {
    left: -8px;
  }
}
div[class^="react-switch-handle"] {
  width: 16px !important;
  height: 16px !important;
  top: 4px !important;
  left: 2px;
}

.switchComponent {
  display: flex;
  margin-top: 24px;
  margin-bottom: 12px;
  b {
    font-family: "ClashGrotesk-Regular";
    text-transform: none;
    margin-left: 8px;
    font-size: 16px;
    font-weight: 500;
    font-weight: normal;
    &:last-of-type {
      font-size: 14px;
      color: #51525c;
    }
  }
  // box-shadow: ;
  div[class^="react-switch-bg"] {
    border: none !important ;
  }
}
.enabled-switch {
  div[class^="react-switch-handle"] {
  }
  div[class^="react-switch-bg"] {
    border: none !important;
  }
}
