.platform-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  h1 {
    text-transform: none !important;
    font-size: 36px;
    width: 50%;
  }
  .platform-objective {
    display: flex;
    align-items: center;
    margin-top: 0%;
  }
  .platform-items {
    display: flex;
    margin-top: 5%;
    flex-wrap: wrap;
    overflow-y: auto;
    width: 80%;
    height: 50vh;
    .platform-item {
      cursor: pointer;
      width: 300px;
      height: 180px;
      border-radius: 24px;
      margin-bottom: 24px;
      margin-right: 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 16px;
      margin-right: 24px;
      text-transform: uppercase;
      font-family: "MicrogrammaStd-BoldExtended";
      border: 1px solid rgba(0, 0, 0, 0.1);
      padding: 24px 0px;
      box-sizing: border-box;
      transition: all 150ms linear;
      background-color: #fff;
      .row {
        display: flex;
        align-items: center;
        justify-content: center;
        height: fit-content;
        img {
          margin-right: 8px;
        }
      }
      .label {
        font-family: "ClashGrotesk-Medium";
        text-transform: none;
        font-size: 14px;
      }
      .goal-input {
        margin-top: -40px;
        background-color: #f9f9f9;
        border: 1px solid #d5d050;
        height: 35px;
        border-radius: 16px;
        outline: none;
        width: calc(100% - 80px);
        font-family: "ClashGrotesk-Regular";
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        &::placeholder {
          color: rgba(0, 0, 0, 0.4);
        }
      }
    }
    .selected {
      background-color: #f3ec00;
      justify-content: space-between;
    }
  }
  .action-continue {
    width: 180px;
    margin-top: -35px;
  }
  .footer-icon {
    position: absolute;
    bottom: 120px;
  }
}
