.segment-dropdown {
  background-color: #f9f9f9;
  z-index: 999;
  width: 400px;
  height: 422px;

  margin-left: 12px;
  border-radius: 12px;
  top: 45px;
  position: absolute;
  border: 1px solid #e4e4e7;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
    0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  padding: 16px 20px;
  h3 {
    font-family: MicrogrammaStd-BoldExtended;
    font-size: 14px;
    margin-bottom: 0px;
  }
  p {
    font-family: ClashGrotesk-Regular;
    color: #51525c;
    margin-top: 0px;
  }
  .search-box {
    display: flex;
    align-items: center;
    border: 1px solid #d1d1d6;
    border-radius: 24px;
    padding: 0 15px;
    .input-icon {
      width: 20px;
      height: 20px;
    }
    input {
      border: none;
      background-color: transparent;
      height: 36px;
      width: 100%;
      outline: none;
      font-family: ClashGrotesk-Regular;
    }
  }
  .select-all {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    padding-right: 12px;
    b {
      font-family: ClashGrotesk-Regular;
    }
    input {
      accent-color: #f3ec00;
    }
  }
}
.segment-dropdown-body {
  margin-top: 20px;
  height: 55%;
  overflow-y: auto;
  .segment-dropdown-body-element {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: space-between;
    padding-right: 12px;
    cursor: pointer;
    .left-part {
      display: flex;
    }
    input {
      accent-color: #f3ec00;
    }
    .icon-part {
      background-color: #0000000f;
      width: 48px;
      aspect-ratio: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 12px;
      .bot-fans {
        path {
          fill: #1c1b1f;
        }
      }
      svg {
        width: 24px;
        height: 24px;
      }
    }
    .segment-info {
      display: flex;
      flex-direction: column;
      b {
        font-family: ClashGrotesk-Regular;
        font-size: 16px;
      }
      .segment-meta {
        display: flex;
        .segment-type {
          background-color: rgba(0, 0, 0, 0.0588235294);
          padding: 2px 8px;
          font-family: ClashGrotesk-Regular;
          border-radius: 60px;
          margin-right: 4px;
        }
        .segment-members {
          display: flex;
          align-items: center;
          font-family: ClashGrotesk-Regular;
          svg {
            margin-right: 4px;
          }
        }
      }
    }
  }
}
.backdrop-dropdown {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  z-index: 8;
}
