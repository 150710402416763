.success-wrapper {
  transition: all 350ms linear;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  width: 100%;
  background-size: cover;
  .success-heading {
    color: white;
    font-family: Sequel100Wide65;
    font-size: 24px;
    margin-top: 40px;
  }
  .success-text-part {
    margin-top: 200px;
    .success-explain-h1 {
      font-family: Sequel100Wide65;
      color: white;
      text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
      font-size: 24px;
      margin-left: 6%;
      margin-right: 6%;
    }
    .success-explain-h2 {
      font-family: Sequel100Wide45;
      font-size: 12px;
      color: white;
      margin-left: 6%;
      margin-right: 6%;
    }
  }
  .dynamic-button {
    margin-bottom: 20px;
  }
}
/* Small devices (portrait tablets and large phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .desktop-wrapper {
    display: flex;
    position: absolute;
  }
  .services-wrapper {
    .dynamic-button {
      margin-bottom: 120px;
    }
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .desktop-wrapper {
    display: flex;
    position: absolute;
  }
  .success-wrapper {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 10%;
    filter: drop-shadow(0px 12px 4px rgba(0, 0, 0, 0.25));
    width: 55%;
    width: 375px;
    height: 730px;
    border: 12px solid white;
    border-radius: 48px;
    overflow: hidden;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .success-wrapper {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 10%;
    filter: drop-shadow(0px 12px 4px rgba(0, 0, 0, 0.25));
    width: 375px;
    height: 730px;
    border: 12px solid white;
    border-radius: 48px;
    overflow: hidden;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .success-wrapper {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 5%;
    filter: drop-shadow(0px 12px 4px rgba(0, 0, 0, 0.25));
    width: 375px;
    height: 730px;
    border: 12px solid white;
    border-radius: 48px;
    overflow: hidden;
  }
}
.ant-modal-header {
  height: 20px;

  .ant-modal-title {
    font-size: 12px !important;
    line-height: 6px;
  }
}
.share-modal-title {
  margin-bottom: 20px;
  padding-left: 12px;
}
.react-responsive-modal-modal {
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  width: 300px;
  border-radius: 8px;
}
.share-component {
  cursor: pointer;
  display: flex;
  border-top: 1px solid rgb(124, 124, 124);
  width: 100%;
  padding: 12px 15px;
  transition: background 150ms linear;
  img {
    width: 20px;
    aspect-ratio: 1;
    margin-right: 12px;
  }
  &:hover {
    background-color: rgb(212, 212, 212);
  }
}
