.button-preview {
  border: 1px solid #d1d1d6;
  border-radius: 24px;
  margin-top: 24px;
  padding: 24px;
  span {
    font-family: ClashGrotesk-Regular;
    font-size: 16px;
    color: #51525c;
  }
  .preview {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .btn-preview-input {
      height: 40px;
      padding: 0px 12px;
      border: 1px solid #d1d1d6;
      border-radius: 60px;
      width: 80%;
      background-color: transparent;
      font-family: ClashGrotesk-Regular;
      font-size: 16px;
    }
    div {
      margin-top: 0px;
      width: fit-content;
      button {
        height: 40px !important;
        width: fit-content;
        font-family: ClashGrotesk-Medium;
      }
    }
  }
}
