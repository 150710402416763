.chat-view-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  .chat-view-scroll {
    overflow-y: auto;
    height: calc(100% - 83px);
    position: relative;
    .chat-separator {
      display: flex;
      align-items: center;
      text-align: center;
      font-family: ClashGrotesk-Regular;
      font-size: 14px;
      color: #51525c;
    }

    .chat-separator::before,
    .chat-separator::after {
      content: "";
      flex: 1;
      border-bottom: 1px solid #0000000f;
    }

    .chat-separator:not(:empty)::before {
      margin-right: 0.25em;
    }

    .chat-separator:not(:empty)::after {
      margin-left: 0.25em;
    }
    .chat-bubble-container {
      padding: 0px 16px;
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      position: relative;

      .chat-bubble {
        background-color: white;
        box-shadow: 0px 2px 2px 0px #00000040;
        width: 60%;
        border-radius: 16px;
        font-family: ClashGrotesk-Regular;
        position: relative;
        min-height: 36px;
        .chat-bubble-main-content {
          padding: 10px 16px;
          padding-right: 30px;
          position: relative;
          padding-bottom: 20px;
        }
        .button-replies-container {
          width: 100%;
          margin-top: 8px;
          .button-reply {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;
            font-size: 16px;
            height: 44px;
            border-top: 0.5px solid #c4c4c6;
            color: #007bff;
          }
          .list-item {
            cursor: pointer;
            transition: all 150ms ease-in;
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
            svg {
              transition: all 150ms ease-in;
            }
            &:hover {
              background-color: #007aff;
              color: white;
              svg {
                fill: white;
              }
            }
          }
        }
      }
      .chat-bubble-img {
        width: calc(100% + 16px);
        margin: 8px 0px;
        background-color: white;
        border-radius: 16px;
        overflow: hidden;
        position: relative;

        .carousel-controls {
          cursor: pointer;
          background-color: #00000066;
          width: 48px;
          aspect-ratio: 1;
          margin: 16px;
          border-radius: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          backdrop-filter: blur(4px);

          img {
            width: 24px;
          }
        }
        .no-items {
          position: absolute;
          background-color: #00000066;
          bottom: 12px;
          right: 12px;
          border-radius: 60px;
          color: white;
          padding: 4px 9px;
          font-family: ClashGrotesk-Regular;
          backdrop-filter: blur(4px);
        }
        .paging-item {
          height: 18px !important;
          margin-top: 0px;
          margin-bottom: 12px;

          svg {
            fill: rgba(255, 255, 255, 0.6) !important;
          }
        }
        .active {
          button {
            fill: #5975ff !important;
          }
        }
        .control-left {
          img {
            transform: rotateZ(180deg);
          }
        }
        img {
          aspect-ratio: 1;
          width: 100%;
          object-fit: cover;
        }
        .raffle-preview {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          aspect-ratio: 1;
          .asset-container {
            position: relative;
            .raffle-img {
              width: 200px;
            }
            .lock-icon-container {
              position: absolute;
              top: 50%;
              left: 50%;
              margin-top: calc(-1 * (88px / 2));
              margin-left: calc(-1 * (88px / 2));
              background: rgba(0, 0, 0, 0.2);
              backdrop-filter: blur(7.499989986419678px);
              width: 88px;
              height: 88px;
              border-radius: 60px;
              display: flex;
              align-items: center;
              justify-content: center;
              svg {
                width: 48px;
                height: 48px;
              }
            }
          }
          b {
            margin-top: 12px;
            font-family: MicrogrammaStd-BoldExtended;
            font-size: 32px;
          }
        }

        box-shadow: 0px 2px 2px 0px #00000040;
        .ant-carousel {
          width: 100%;
          height: 100%;
          .slick-list {
            width: 320px;
            height: 320px;
          }
          img {
            width: 100%;
            height: 100%;
          }
        }
        video {
          width: calc(100% + 16px);
          object-fit: cover;
          margin-bottom: -12px;
        }
      }

      .chat-bubble-date {
        color: #51525c;
        font-family: ClashGrotesk-Regular;
        font-size: 12px;
        position: absolute;
        bottom: 6px;
        right: 12px;
      }
    }
    .chat-my-bubble-container {
      align-items: flex-end;
      .chat-bubble {
        background-color: #5975ff;
        color: white;
      }
    }
  }

  .chat-input-container {
    background-color: #e4e4e7;
    width: 100%;
    height: 83px;
    display: flex;
    align-items: flex-end;
    gap: 20px;
    padding: 16px 20px;
    transition: all 150ms ease-in;
    .chat-input-actions {
      height: 48px;
      display: flex;
      align-items: center;
      width: 12%;
      justify-content: space-between;
      > * {
        cursor: pointer;
      }
    }
    display: flex;
    .input-with-attachments {
      padding: 8px 0px;
      background-color: white !important;
      transition: none !important;
      min-height: 48px;
      border-radius: 24px;
      border: 1px solid #d1d1d6;
      flex: 1;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      transition: all 150ms ease-in;
      .reply-chat-input {
        background-color: transparent;
        border: none;
        outline: none;
        box-shadow: none;
        font-family: ClashGrotesk-Regular;
        font-size: 16px;
      }
      .input-attachments {
        display: flex;
        gap: 8px;
        padding: 4px 8px;

        .reply-attachment {
          width: 96px;
          height: 96px;

          position: relative;
          img {
            width: 100%;
            height: 100%;
            border-radius: 16px;
            object-fit: cover;
          }
          .input-attachment-action {
            width: 28px;
            height: 28px;
            background-color: #000000;
            position: absolute;
            top: 4px;
            right: 4px;
            border-radius: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
        }
      }
    }

    .chat-send {
      width: 48px;
      height: 48px;
      box-sizing: border-box;
      border-radius: 60px;
      border-width: 0px;
      background-color: #f3ec00;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 150ms ease-in;
    }
    .chat-send-disabled {
      opacity: 0.4;
    }
  }
  .chat-header {
    background-color: #e4e4e7;
    width: 100%;
    height: 67px;
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 16px 20px;
    img {
      width: 44px;
      height: 44px;
    }
    .chat-header-content {
      font-size: 18px;
      font-family: ClashGrotesk-Medium;
      line-height: 18px;
    }
    .chat-header-number {
      font-size: 16px;
      font-family: ClashGrotesk-Regular;
      color: #787878;
      line-height: 18px;
    }
  }
  .with-attachments {
    height: 220px;
  }
  .emoji-picker {
    position: absolute;
    bottom: 80px;
    left: 0px;
  }
  .hot-corner {
    height: 100px;
    width: 100px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 20px;
    top: 70px;
    right: 0px;
    position: absolute;
    z-index: 1;
    transition: all 150ms ease-in;
    &:hover {
      opacity: 1 !important;
    }

    .chat-navigate-btn {
      background-color: #ebebeb;
      display: flex;
      width: 30px;
      height: 30px;
      align-items: center;
      justify-content: center;
      border-radius: 60px;
      font-size: 20px;
      cursor: pointer;
      box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.37);
      transition: all 150ms ease-in;
      &:hover {
        background-color: #f3ec00;
      }
    }
  }
  .bottom-hot-corner {
    bottom: 0px;
    top: unset;
    align-items: flex-end;
  }
}
.whatsapp-ui {
  background-color: #ede8df;
  .chat-my-bubble-container {
    .chat-bubble {
      background-color: #e8fed8 !important;
      color: #18181b !important;
    }
  }
  .chat-bubble {
    box-shadow: none !important;
  }
}
.instagram-ui {
  background-color: #f9f9f9;
  border-left: 1px solid #dcdcdc !important;
  .chat-my-bubble-container {
    .chat-bubble {
      background-color: #5197e9 !important;
      color: #fff !important;
      .chat-bubble-date {
        color: white !important;
      }
    }
  }
  .chat-bubble {
    box-shadow: 0px 1px 1px 0px #0000001a !important;
    background-color: #efefef !important;
  }
}

.whatsapp-list-option-modal {
  .whatsapp-list-option-modal-close {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #eeeeef;
    width: 40px;
    height: 40px;
    border-radius: 360px;
    margin-top: 20px;
  }
  .ant-modal-content {
    border-radius: 12px;
  }
  h1 {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 20px;
    border-bottom: 1px solid #eeeeef;
  }
  .whatsapp-list-row {
    border-bottom: 1px solid #eeeeef;
    padding: 12px 0px;
  }
}
