.your-number-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0px;
  right: 0px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 32%;
  padding: 24px;
  border-radius: 20px;
  max-width: 430px;
  color: #18181b;
 
  b {
    font-family: MicrogrammaStd-BoldExtended;
    font-size: 18px;
    width: 100%;
  }
  p {
    font-family: ClashGrotesk-Regular;
    font-size: 14px;
    margin-bottom: 0px;
  }
  .actual-number {
    display: flex;
    margin: 20px 0px;
    width: 100%;
    .copy-btn {
      border-radius: 60px;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 44px;
      height: 44px;
      cursor: pointer;
      transition: all 150ms ease-in;
      svg {
        width: 24px;
        height: 24px;
        path,
        line {
          transition: all 150ms ease-in;
        }
      }
      &:first-of-type {
        margin-left: 20px;
      }
      &:last-of-type {
        margin-left: 8px;
      }
      &:hover {
        background-color: #f3ec00;
      }
    }

    .number {
      display: flex;
      align-items: center;
      font-size: 16px;
    }
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px 12px;
      cursor: pointer;
      font-family: MicrogrammaStd-BoldExtended;
      font-size: 14px;
      width: 45%;
      img {
        width: 17px;
        aspect-ratio: 1;
        margin-right: 10px;
      }
      div {
        width: 60%;
        display: flex;
        align-items: center;
      }
    }
  }
}