.add-collectible {
  margin-right: 6px;
  border: 1.5px solid #d1d1d6;
  width: 250px;
  aspect-ratio: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  cursor: pointer;
  .icon-holder {
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e4e4e7;
    border-radius: 60px;
  }
  b {
    margin-top: 12px;
    font-family: ClashGrotesk-Regular;
    font-size: 14px;
  }
}

.collectionid-card-wrapper {
  margin: 0px 6px;
  margin-bottom: 20px;
  .nft-card-wrapper {
    width: 250px;

    position: relative;
    .delete-btn {
      border-radius: 60px;
      border: none;
      cursor: pointer;
      position: absolute;
      top: 12px;
      right: 5px;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgb(239, 239, 239);
      backdrop-filter: blur(6px);
      transition: all 150ms ease-in;

      svg {
        fill: #000;
      }
      &:hover {
        background-color: #5975ff;
      }
    }
  }
  .play-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(6px);
    border-radius: 60px;
    position: absolute;
    cursor: pointer;
    left: 50%;
    margin-left: -25px;
    top: 50%;
    margin-top: -50px;
    width: 50px;
    height: 50px;
  }
  .play-btn-img {
    width: 32px;
    height: 32px;
  }
  .nft-card-artwork-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    aspect-ratio: 1;
    background-color: white;
    border-radius: 12px;
  }

  .nft-img {
    width: 50%;
    aspect-ratio: 1;
    border-radius: 12px;
    object-fit: cover;
  }
  .nft-card-footer {
    padding: 10px 0px;
    position: relative;
    .share-btn {
      border-radius: 60px;
      border: none;
      cursor: pointer;
      position: absolute;
      top: 12px;
      right: 5px;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 150ms ease-in;
      svg {
        transition: all 150ms ease-in;
        color: #18181b;
      }
      &:hover {
        background-color: #5975ff;
        svg {
          fill: #fff;
        }
      }
    }
  }

  .nft-title {
    font-family: MicrogrammaStd-BoldExtended;
    color: #18181b;
    margin-bottom: 4px;
    font-size: 14px;
  }
  .nft-author {
    color: #18181b;
    font-family: ClashGrotesk-Regular;
    font-size: 16px;
    display: flex;
    align-items: center;
  }
  .nft-edition {
    color: #a0a0ab;
    font-family: ClashGrotesk-Regular;
    font-size: 12px;
  }
}
