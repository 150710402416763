.chatview-wrapper {
  .chat-header {
    padding: 20px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #00000033;
    .contact {
      height: 15;
      margin-left: 12px;
      font-family: MicrogrammaStd-BoldExtended;
      font-size: 16px;
    }
  }
  .chat-body {
    transition: all 150ms linear;
    overflow-y: auto;
    height: 70%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    .my-bubble {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      .bubble-container {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .images-msg {
          width: 320px;
          height: 320px;
          border-radius: 24px;
          margin-top: 8px;
          position: relative;
          img {
            width: 100%;
            aspect-ratio: 1;
            object-fit: cover;
            border-radius: 24px;
            cursor: pointer;
          }
          span {
            position: absolute;
            bottom: 12px;
            right: 12px;
            background-color: #00000066;
            border-radius: 24px;
            padding: 4px 9px;
            display: flex;
            align-items: center;
            justify-content: center;
            backdrop-filter: blur(4px);
            color: white;
          }
        }
      }
      .bubble {
        background-color: #5975ff;
        color: white;
        font-family: ClashGrotesk-Regular;
        font-size: 16px;
        padding: 12px;
        border-radius: 16px;
      }
      margin-bottom: 16px;
    }
    .others-bubble {
      width: 100%;
      .bubble-container {
        width: 50%;
      }
      .bubble {
        background-color: #0000000f;
        color: black;
        font-family: ClashGrotesk-Regular;
        font-size: 16px;
        padding: 12px;
        border-radius: 16px;
      }
      margin-bottom: 16px;
    }
  }
  .body-images {
    height: 55%;
  }
  .chat-footer {
    transition: all 150ms linear;
    height: 15%;
    display: flex;
    align-items: center;
    padding: 20px;
    .emoji-picker {
      position: absolute;
      bottom: 100px;
    }
    .tool-icon {
      margin-right: 24px;
      cursor: pointer;
    }
    .chatinput-container {
      min-height: 48px;
      border-radius: 16px;
      border: 1px solid #d1d1d6;
      background: transparent;
      width: 78%;
      font-family: ClashGrotesk-Regular;
      font-size: 16px;
      padding: 0 14px;
      input {
        min-height: 48px;
        outline: none;
        border: none;
        background-color: transparent;
        width: 100%;
      }
    }
    div[class^="Button"] {
      margin-top: 0px;
      margin-left: 8px;
      button {
        aspect-ratio: 1;
        width: 48px;
      }
    }
  }
  .footer-images {
    height: 30%;
  }
}
