@import "../../App.scss";

.landing-template-1-styles {
  .mute-unmute-wrapper {
    position: absolute;
    top: 5%;
    right: 5%;
    z-index: 9;
    .mute-unmute-button {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: all 350ms linear;
      &:hover {
        background-color: #141414;
      }
    }
  }
  .overlay-loading {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 999999;
  }
  .landing-template-1-background {
    width: 100%;
    height: 100vh;
    background-color: var(--background-color);
    transition: all 350ms linear;

    .landing-template-1-wrapper {
      background-repeat: no-repeat;
      background-size: cover !important;
      display: flex;
      flex-direction: column;
      height: 100vh;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
      align-items: center;
      position: relative;
      min-height: 750px;
      .artist-logo {
        margin-top: var(--logo-top);
        width: var(--logo-width);
        height: 100px;
        z-index: 9;
        object-fit: contain;
      }
      .heading-1 {
        text-align: var(--heading-1-align);
        font-family: var(--heading-1-font);
        color: var(--heading-1-color);
        text-shadow: var(--heading-1-text-shadow);
        z-index: 9;
        width: var(--heading-1-width);
        font-size: var(--heading-1-font-size);
        line-height: 33px;
        margin-top: var(--text-part-margin-top);
      }
      .heading-2 {
        text-align: var(--heading-2-align);
        font-family: var(--heading-2-font);
        color: var(--heading-2-color);
        font-size: var(--heading-2-font-size);
        z-index: 9;
        width: var(--heading-2-width);
        line-height: 33px;
      }
      .content-wrapper {
        width: 100%;
        position: absolute;
        bottom: 12%;
        .buttons-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-left: 25px;
          margin-right: 25px;
          margin-bottom: 50px;
        }
        .text-wrapper {
          color: white;
          padding: 0px 25px;
          h1 {
            font-family: Sequel100Wide65;
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            line-height: 27px;
            text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
            margin: 5px 0px;
          }
          h2 {
            width: 100%;
            font-family: UniversNextPro-BoldExt;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 17px;
            color: #ffffff;
            text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
            margin: 5px 0px;
          }
          h3 {
            font-family: Univers;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 17px;
            margin: 5px 0px;
          }
          margin-bottom: 25px;
        }
      }
      .error-message {
        line-height: 12px;
        font-family: Sequel100Wide45;
        color: #d80027;
        font-size: 12px;
        margin-left: 30px;
      }
    }
    .copyright {
      margin-top: 8px;
      p {
        color: var(--heading-1-color);
        font-family: var(--primary-font);
        font-size: 10px;
        text-align: center;
        a {
          color: var(--heading-2-color) !important;
        }
      }
    }
    .dynamic-button {
    }
    .input-container {
    }
  }
  .desktop-wrapper {
    display: none;
    z-index: -1;
    position: absolute;
    min-height: 100vh;
    width: 100%;
    background-size: cover !important;
    background-position: center !important;
    filter: none !important;
    video {
      z-index: -1;
      height: 100vh;
    }
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    .desktop-wrapper {
      display: flex;
      position: absolute;
    }
    .landing-template-1-wrapper {
      background: none !important;
      height: 80vh !important;
      #video-phone {
        display: block;
      }
    }
    .landing-template-1-background {
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      top: 10%;
      filter: none;
      width: 375px;
      height: 90% !important;
      border: 0px solid white;
      border-radius: 48px;
      overflow: hidden;
      overflow-y: auto;
      background: none !important;
      background-color: transparent !important;
      // padding: 10px;
      .landing-template-1-wrapper {
        padding-bottom: 25px;
      }
      .text-wrapper {
        bottom: 85px !important;
      }
      .copyright {
        width: 100%;
        position: absolute;
        bottom: 8px;
      }
    }
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    .landing-template-1-wrapper {
      background: none !important;
      height: 80vh !important;
    }
    .landing-template-1-background {
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      top: 10%;
      filter: none !important;
      width: 375px;
      border: 0px solid white;
      border-radius: 48px;
      overflow: hidden;
      overflow-y: auto;
      background: none !important;
      background-color: transparent !important;
      height: 100%;
      // padding: 10px;
      .landing-template-1-wrapper {
        padding-bottom: 25px;
      }
      .copyright {
        width: 100%;
        position: absolute;
        bottom: 8px;
      }
    }
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    .landing-template-1-wrapper {
      background: none !important;
      height: 93vh !important;
    }
    .landing-template-1-background {
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      top: 5%;
      filter: none !important;
      width: 375px;
      background-color: transparent !important;
      border-radius: 48px;
      overflow: hidden;
      overflow-y: auto;
      background-color: transparent;
      height: 100%;
      // padding: 10px;
      .landing-template-1-wrapper {
        padding-bottom: 5px;
        .text-wrapper {
          margin-bottom: 150px !important;
        }
      }

      .copyright {
        width: 100%;
        position: absolute;
        bottom: -30px;
      }
    }
  }
}
