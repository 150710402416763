.tree-view {
  width: calc(100% + 80px);
  margin-left: -26px;
  h3 {
    font-family: MicrogrammaStd-BoldExtended;
    font-size: 18px;
  }
  .branch {
    transition: all 150ms linear;
    color: black;
    width: 100%;
    margin-left: -14px;
    height: 42px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    gap: 5px;
    font-family: ClashGrotesk-Medium;
    font-size: 20px;
    svg {
      font-size: 12px;
    }
  }
  .active-branch {
    color: #fff;
    background-color: #7a91ff;
  }
  ul {
    list-style: none;
    padding: 0;
    width: 100%;
    li {
      padding-left: 14px;
    }
  }
}
