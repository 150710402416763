.message-popup-wrapper {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100vh;
  left: 0px;
  top: 0px;
  background-color: #00000033;
  z-index: 1009;
  align-items: center;
  justify-content: center;
  @keyframes popUp {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .message-popup {
    position: relative;
    animation: popUp linear 150ms;
    background-color: #f9f9f9;
    width: 960px;
    height: 600px;
    border-radius: 20px;
    box-shadow: 0px 24px 48px -12px rgba(16, 24, 40, 0.18);
    display: flex;
    .column-1 {
      display: flex;
      flex-direction: column;
      width: 520px;
      padding: 32px;
      border-right: 1px solid #e4e4e7;

      .message-header {
        span {
          font-family: MicrogrammaStd-BoldExtended;
          font-size: 24px;
        }
        div {
          font-family: ClashGrotesk-Regular;
          border-bottom: 1px solid #e4e4e7;
          padding-bottom: 16px;
        }
      }
      .message-body {
        overflow-y: auto;
        margin-top: 16px;
        font-family: ClashGrotesk-Regular;
        font-size: 20px;
        height: 70%;
        border-bottom: 1px solid #e4e4e7;
        .carousel {
          width: 100%;
          .slide {
            .carousel-slide {
              img {
                object-fit: cover;
              }
            }
          }
          ul {
            top: 0px;
          }
          .carousel-controls {
            cursor: pointer;
            background-color: #00000066;
            width: 48px;
            aspect-ratio: 1;
            margin: 16px;
            border-radius: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            backdrop-filter: blur(4px);
            img {
              width: 24px;
            }
          }
          .control-left {
            img {
              transform: rotateZ(180deg);
            }
          }
          .paging-item {
            height: 24px !important;
            margin-top: 0px;
            margin-bottom: 12px;
            button {
              fill: rgba(255, 255, 255, 0.6) !important;
            }
          }
          .active {
            button {
              fill: #5975ff !important;
            }
          }
          img {
            width: 100%;
            aspect-ratio: 1;
          }
        }
      }
      .message-footer {
        display: flex;
        align-items: center;
        height: 13%;
        .tile-stat {
          div {
            display: flex;
            font-family: ClashGrotesk-Regular;
            font-size: 16px;
            align-items: center;
          }

          font-family: MicrogrammaStd-BoldExtended;
          font-size: 20px;
          img {
            width: 16px;
            margin-right: 4px;
          }
          margin-right: 80px;
          &:last-of-type {
            margin-right: 0px;
          }
        }
      }
    }
    .column-2 {
      width: 440px;
      padding: 32px;
      display: flex;
      flex-direction: column;
      .message-header {
        border-bottom: 1px solid #e4e4e7;
      }
      .message-body {
        overflow-y: auto;
        padding-right: 16px;
        .reply {
          padding: 16px 0px;
          border-bottom: 1px solid #e4e4e7;
          display: flex;
          align-items: flex-start;
          .replies-icon {
            margin-top: 12px;
            width: 5%;
            cursor: pointer;
          }
          .reply-body {
            width: 80%;
            margin-right: 24px;
            margin-left: 8px;
            color: #3f3f46;
            span {
              font-family: ClashGrotesk-Bold;
              font-size: 14px;
            }
            div {
              font-family: ClashGrotesk-Regular;
            }
          }
        }
      }
    }
    .message-popup-close {
      position: absolute;
      top: 24px;
      right: 24px;
      width: 28px;
      cursor: pointer;
    }
  }
}
