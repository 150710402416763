.insights-wrapper {
  width: 80vw;
  .sub-heading {
    font-family: "MicrogrammaStd-BoldExtended";

    font-size: 20px;
    line-height: 30px;
    margin-top: 44px;
    display: block;
    span {
      font-family: "ClashGrotesk-Regular";
      font-size: 14px;
      font-weight: normal;
      margin-left: 12px;
      color: #51525c;
    }
  }
  .insight-cards {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    margin-top: 24px;
  }
}
