.links-editor {
  margin-top: 18px;
  label {
    font-family: NON-Natural-Grotesk-Bold;
    color: rgba(0, 0, 0, 0.4);
    font-size: 14px;
  }
  .links {
    margin-top: 5px;
    .link-item {
      height: 35px;
      display: flex;
      align-items: center;
      margin-top: 12px;
      &:last-of-type {
        border: 0px;
      }

      input {
        height: 35px;
        border-radius: 20px;
        border: 1px solid #b7b7b7;
        width: 350px;
        padding-left: 15px;
        padding-right: 15px;
        &::placeholder {
          color: #b7b7b7c9;
        }
      }
    }
  }
}
