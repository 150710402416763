.raffle-form-preview {
  .ant-modal-content {
    overflow-y: auto;
    height: 80vh;
  }
}
.raffle-form-preview .ant-modal-body {
  padding: 0px;
  pointer-events: none;
}
