.wrapper {
  background-color: #f9f9f9;
  height: 100vh;
  width: 100vw;

  .row {
    display: flex;
    position: relative;
    height: calc(100vh - 112px);
    justify-content: space-between;
    border-top: 1px solid black;

    .leftCol {
      width: 26.39%;
      transition: all 150ms linear;
      height: calc(100vh - 112px);
      .step {
        height: 90px;
        cursor: pointer;
        display: flex;
        align-items: center;
        b {
          background-color: #f3ec00;
          width: 40px;
          min-width: 40px;
          height: 40px;
          border-radius: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: black;
          font-family: MicrogrammaStd-BoldExtended;
          margin-left: 40px;
          margin-right: 16px;
        }
        div {
          font-family: "ClashGrotesk-Medium";
          font-weight: 500;
          white-space: nowrap;
        }
        &.active {
          background-color: black;
          color: white;
        }
      }
      .signOut {
        margin-left: 40px;
        font-family: MicrogrammaStd-BoldExtended;
        text-transform: uppercase;
        position: absolute;
        bottom: 40px;
        font-size: 16px;
        cursor: pointer;
      }
      .signOutIcon {
        cursor: pointer;
        position: absolute;
        bottom: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #eaeaea;
        width: 56px;
        aspect-ratio: 1;
        border-radius: 60px;
        left: 40px;
        margin-left: -24px;
        img {
          width: 21px;
        }
      }
      .miniHeader {
        flex-direction: row;
        b {
          font-weight: 500;
        }
      }
    }

    .leftMinimised {
      @media only screen and (max-width: 1024px) {
        display: none;
      }
      width: 10%;
      max-width: 90px;
      display: flex;
      flex-direction: column;
      .minimiseIcon {
        transform: rotateZ(180deg);
      }
      .step {
        justify-content: center;
        align-items: center;
        display: flex;
        b {
          margin: 0px;
        }
        div {
          display: none;
        }
      }
      .miniHeader {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row !important;
        b {
          display: none;
        }
      }
    }
    .middleCol {
      position: relative;
      width: 38.89%;
      height: calc(100vh - 112px);
      border-left: 1px solid black;
      transition: all 150ms linear;
    }
    .middleMinimised {
      width: calc(65.28% - 90px);
      @media only screen and (max-width: 1024px) {
        width: 100%;
      }
    }
    .rightCol {
      @media only screen and (max-width: 1024px) {
        display: none;
      }
      width: 34.72%;
      background-color: #f3ec00;
      border-left: 1px solid black;
      height: calc(100vh - 112px);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .miniHeader {
    display: flex;
    flex-direction: column;
    padding: 20px 40px;
    justify-content: space-between;
    align-items: flex-start;
    min-height: 70px;

    .label {
      font-family: MicrogrammaStd-BoldExtended;
      font-size: 24px;
      width: 70%;
    }
    .miniLabel {
      font-family: "ClashGrotesk-Regular";
      font-size: 16px;
    }
    .blueMiniLabel {
      color: #7a91ff;
    }
    div[class^="react-switch-bg"] {
      border: 1px solid black;
      width: 40px !important;
      height: 24px !important;
    }
    div[class*="checked"] {
      div[class^="react-switch-handle"] {
        left: -8px;
      }
    }
    div[class^="react-switch-handle"] {
      width: 16px !important;
      height: 16px !important;
      top: 4px !important;
      left: 2px;
    }
    img {
      cursor: pointer;
    }
    .switchComponent {
      display: flex;
      align-items: center;
      margin-top: 10px;
      margin-bottom: 12px;
      b {
        font-family: "ClashGrotesk-Regular";
        text-transform: none;
        margin-left: 8px;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }
}
