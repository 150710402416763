.insight-card {
  width: calc(380px - 30px);
  border: 1px solid #d1d1d6;
  padding: 18px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 16px;
  margin-bottom: 16px;

  justify-content: space-between;
  .insight-card-heading {
    font-family: "MicrogrammaStd-BoldExtended";
    font-size: 20px;
    margin-bottom: 20px;
    svg {
      margin-left: 8px;
    }
  }
  .insight-chart-data {
    position: relative;
    width: 100%;
    .semicircle-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .goal-data {
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      margin-left: auto;
      margin-right: auto;
      width: fit-content;
      font-family: "ClashGrotesk-Regular";
      font-size: 12px;
    }
    .main-circle-data {
      font-family: "MicrogrammaStd-BoldExtended";
      font-size: 30px;
      position: absolute;
      bottom: 10px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      width: fit-content;
    }
    .compare-data {
      position: absolute;
      display: flex;
      align-items: center;
      color: #3f3f46;
      font-size: 12px;
      position: absolute;
      bottom: 0px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      width: fit-content;
      font-family: "ClashGrotesk-Medium";
      .icon {
        transform: rotate(0deg);
        margin-top: 3px;
        margin-right: 4px;
      }
    }
    .down-arrow {
      .icon {
        margin-top: -5px;
        transform: rotate(180deg);
      }
    }
    .good {
      color: #087443;
    }
    .bad {
      color: #bc1b06;
    }
    .circle-data {
      font-family: "ClashGrotesk-Regular";
      font-size: 12px;
      max-width: 250px;
      display: flex;
      justify-content: space-between;
      margin-left: auto;
      margin-right: auto;
    }
    .insight-chart-data-2 {
      position: absolute;
      top: 0px;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
    }
  }
  .insight-card-body {
    font-family: "ClashGrotesk-Regular";
    font-size: 14px;
    margin-top: 36px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 120px;
    .recomend-descr {
      display: flex;
      flex-direction: column;
      align-items: center;
      .recomend {
        margin-top: 14px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
    .description-inner-left{
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;
      .label {
      }
      .value {
        color: #18181b;
        font-size: 18px;
        font-family: "MicrogrammaStd-BoldExtended";
      }
    }
  }
  .insight-card-actions {
    div {
      width: fit-content;
      padding-bottom: 10px;
      margin-top: 30px;
      button {
        font-size: 14px;
      }
    }
  }
}
