.confirm-broadcast-modal-wrapper {
  display: flex;
  position: fixed;
  z-index: 1300;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  opacity: 1;
  animation: fade 150ms linear;
  /* Background blur/md */

  backdrop-filter: blur(8px);
  align-items: center;
  justify-content: center;
  .confirm-broadcast-modal {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #f9f9f9;
    padding: 24px;
    width: 25%;
    border-radius: 12px;
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
      0px 8px 8px -4px rgba(16, 24, 40, 0.03);
    @media only screen and (max-width: 768px) {
      width: 90%;
    }
    h3 {
      font-family: MicrogrammaStd-BoldExtended;
      font-size: 18px;
      margin-bottom: 0px;
    }
    div {
      font-family: ClashGrotesk-Regular;
      font-size: 14px;
    }
    img {
      cursor: pointer;
      position: absolute;
      right: 24px;
    }
    .cb-modal-footer {
      display: flex;
      div {
        margin-top: 24px;

        width: 50%;
        margin-right: 8px;
        &:last-of-type {
          margin-right: 0px;
        }
      }
    }
  }
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
