.pause-backdrop {
  animation: modal-appear 150ms ease-in;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 1;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .pause-modal {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 12px;
    width: 40%;

    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
      0px 8px 8px -4px rgba(16, 24, 40, 0.03);
    position: relative;
    padding: 32px;
    .close-icon {
      position: absolute;
      top: 32px;
      right: 32px;
      cursor: pointer;
      svg {
        width: 12px;
        height: 12px;
        path {
          fill: #70707b;
        }
      }
    }
    .pause-icon {
      width: 48px;
      height: 48px;
      display: flex;
      background-color: #fee4e2;
      align-items: center;
      justify-content: center;
      border-radius: 60px;
      path {
        fill: #b42318;
      }
    }
    h4 {
      margin-top: 20px;
      margin-bottom: 8px;
      font-family: "MicrogrammaStd-BoldExtended";
      font-size: 24px;
    }
    h6 {
      font-family: ClashGrotesk-Regular;
      font-size: 16px;
    }
    .pause-modal-action {
      display: flex;
      width: 70%;
      margin-left: 30%;
      div[class^="Button_"] {
        margin-top: 32px;
        &:first-of-type {
          margin-right: 12px;
        }
      }
    }
  }
}
