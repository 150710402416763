@import "../../App.scss";
.landing-email-sent-styles {
  .overlay-loading {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 999999;
  }
  .landing-email-sent-background {
    width: 100%;
    height: 100vh;
    background-color: #141414;
    transition: all 350ms linear;

    .landing-email-sent-wrapper {
      background-repeat: no-repeat;
      background-size: cover !important;
      background-position: center !important;
      display: flex;
      flex-direction: column;
      height: 100vh;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
      position: relative;
      .content-wrapper {
        width: 100%;
        position: absolute;
        bottom: 10px;
        .text-wrapper {
          color: var(--primary-color);
          padding: 0px 25px;
          h1 {
            font-family: var(--primary-font);
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            line-height: 27px;
            text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
            margin: 5px 0px;
            width: 110%;
          }
          h2 {
            width: 100%;
            font-family: var(--secondary-font) t;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 17px;
            color: var(--primary-color);
            text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
            margin: 5px 0px;
          }
          h3 {
            font-family: var(--secondary-font) t;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 18px;
            margin: 5px 0px;
          }
          margin-bottom: 85px !important;
        }
      }
      .error-message {
        line-height: 12px;
        font-family: Sequel100Wide45;
        color: #d80027;
        font-size: 12px;
        margin-left: 30px;
      }
    }
    .copyright {
      cursor: pointer;
      margin-top: -8px;
      display: flex;
      justify-content: center;
      p {
        color: var(--primary-color);
        font-family: var(--primary-font);
        font-size: 14px;
        text-align: center;
      }
    }
    .dynamic-button {
      margin-left: 25px;
    }
    .input-container {
      margin-left: 25px;
    }
  }
  .desktop-wrapper {
    display: none;
    z-index: -1;
    position: absolute;
    min-height: 100vh;
    width: 100%;
    background-size: cover !important;
    background-position: center !important;
    filter: none !important;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    .desktop-wrapper {
      display: flex;
      position: absolute;
    }
    .landing-email-sent-wrapper {
      background: none !important;
      height: 80vh !important;
    }
    .landing-email-sent-background {
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      top: 10%;
      filter: none;
      width: 375px;
      height: 90% !important;
      border: 0px solid white;
      border-radius: 48px;
      overflow: hidden;
      background: none !important;
      background-color: transparent !important;
      // padding: 10px;
      .landing-email-sent-wrapper {
        padding-bottom: 25px;
        .content-wrapper {
          h3 {
            width: 85% !important;
            margin-left: 7.5% !important;
          }
        }
      }
      .copyright {
        width: 100%;
        position: absolute;
        bottom: 8px;
      }
    }
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 600px) {
    .landing-email-sent-wrapper {
      background: none !important;
      height: 80vh !important;
    }
    .landing-email-sent-background {
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      top: 10%;
      filter: none !important;
      width: 375px;
      border: 0px solid white;
      border-radius: 48px;
      overflow: hidden;
      background: none !important;
      background-color: transparent !important;
      height: 100%;
      // padding: 10px;
      .landing-email-sent-wrapper {
        padding-bottom: 25px;
        .content-wrapper {
          bottom: 20% !important;
          width: 100%;
          text-align: center;
          .text-wrapper {
            h1 {
              font-size: 36px;
              line-height: 40px;
              width: 100%;
            }
            h3 {
              font-size: 22px;
              font-family: Swiss721BT;
              margin-top: 30px;
              text-align: center;
              width: 50%;
              margin-left: 25%;
            }
          }
        }
      }
      .copyright {
        width: 100%;
        position: absolute;
        bottom: 8px;
      }
    }
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    .landing-email-sent-wrapper {
      background: none !important;
      height: 93vh !important;
    }
    .landing-email-sent-background {
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      top: 5%;
      filter: none !important;
      width: 450px;
      background-color: transparent !important;
      border-radius: 48px;
      overflow: hidden;
      background-color: transparent;
      height: 100%;
      // padding: 10px;
      .landing-email-sent-wrapper {
        padding-bottom: 5px;
        .content-wrapper {
          bottom: 20% !important;
          width: 100%;
          text-align: center;
          .text-wrapper {
            h1 {
              font-size: 36px;
              line-height: 40px;
              width: 100%;
            }
            h3 {
              font-size: 22px;
              font-family: Swiss721BT;
              margin-top: 30px;
              text-align: center;
              width: 50%;
              margin-left: 25%;
            }
          }
        }
      }
      .copyright {
        width: 100%;
        position: absolute;
        bottom: 8px;
      }
    }
  }
}
