.popUpWrapper {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: #00000066;
  z-index: 1;
  .popUp {
    position: absolute;
    width: 40%;
    height: 30vh;
    background-color: #f3ec00;
    top: 50%;
    margin-top: -220px;
    left: 50%;
    margin-left: -20%;
    padding: 28px 28px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    h1 {
      font-family: MicrogrammaStd-BoldExtended;
      text-transform: uppercase;
      font-size: 25px;
      width: 60%;
    }
    .phoneNrAndCopy {
      display: flex;
      align-items: center;
      img {
        cursor: pointer;
        margin-left: 8px;
      }
    }
    .phoneNr {
      font-family: MicrogrammaStd-BoldExtended;
      text-transform: uppercase;
      font-size: 20px;
      border-bottom: 1px dotted black;
      padding: 0px 5px;
    }
    h2 {
      font-family: "NON-Natural-Grotesk-Regular";
      font-size: 18px;
    }
    .arrowBtn {
      border: 1px solid black;
      cursor: pointer;
      background-color: #f3ec00;
      border-radius: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: auto;
      height: 30px;
      font-family: MicrogrammaStd-BoldExtended;
      text-transform: uppercase;
      font-size: 20px;
      img {
        margin-left: 12px;
        height: 20px;
      }
    }
  }
  .spikey {
    position: absolute;
    top: 48px;
    right: 48px;
  }
  .x {
    position: absolute;
    top: 24px;
    right: 24px;
    cursor: pointer;
  }
  .error {
    position: absolute;
    width: 50%;
    height: 40vh;
    background-color: #f9f9f9;
    top: 50%;
    margin-top: -250px;
    left: 50%;
    margin-left: -25%;
    padding: 28px 28px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    h1 {
      font-family: MicrogrammaStd-BoldExtended;
      text-transform: uppercase;
      font-size: 25px;
      width: 80%;
    }
    .phoneNrAndCopy {
      display: flex;
      align-items: center;
      img {
        cursor: pointer;
        margin-left: 8px;
      }
      .phoneNr {
        font-family: MicrogrammaStd-BoldExtended;
        text-transform: uppercase;
        font-size: 20px;
        border-bottom: 1px dotted black;
        padding: 0px 5px;
      }
    }
    h2 {
      font-family: "NON-Natural-Grotesk-Regular";
      font-size: 18px;
      width: 80%;
      a {
        color: #5975ff;
      }
    }
    img {
      position: absolute;
      right: 28px;
      top: 14%;
    }
    .arrowBtn {
      border: 1px solid black;
      cursor: pointer;
      background-color: #f3ec00;
      border-radius: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: auto;
      height: 30px;
      font-family: MicrogrammaStd-BoldExtended;
      text-transform: uppercase;
      font-size: 20px;
      img {
        margin-left: 12px;
        height: 20px;
      }
    }
  }
}
