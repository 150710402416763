.whatsapp-bubble-row {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  position: relative;
  .whatsapp-bubble {
    background-color: #f9f9f9;
    box-shadow: 0px 1px 1px 0px #00000033;
    max-width: 75%;
    border-radius: 10px;
    position: relative;
    margin-top: 17px;
    &:after {
      content: "";
      position: absolute;
      display: block;
      width: 0;
      z-index: -1;
      border-style: solid;
      border-width: 0 0 13px 14px;
      border-color: transparent transparent #f9f9f9 transparent;
      top: 85%;
      left: -8px;
      margin-top: -6.5px;
      box-shadow: 0px 1px 1px 0px #00000033;
    }

    .whatsapp-message {
      font-family: ClashGrotesk-Regular;
      padding: 3px 6px;
    }
    .bubble-vector {
      position: absolute;
      bottom: -2px;
      left: -5px;
    }

    .whatsapp-message-options {
      &:last-of-type {
        border-radius: 0 0 10px 10px;
      }
      border-top: 0.5px solid #c4c4c6;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 150ms linear;
      &:hover {
        background-color: #fff;
      }
      div {
        display: flex;
        align-items: center;
        gap: 4px;
        color: #007bff;
        font-family: ClashGrotesk-Regular;
      }
    }
    .active-whatsapp-option {
      background-color: #007aff;

      div {
        color: #fff;
      }
      &:hover {
        background-color: #007aff;
      }
    }
    cursor: pointer;
  }

  .bubble-options-modal {
    position: absolute;
    background-color: #ececed;
    z-index: 3;
    width: 80%;
    bottom: -130px;
    min-width: 160px;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    .options-modal-row {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 9px 13px;
      font-family: ClashGrotesk-Regular;
      &:nth-of-type(2) {
        border-top: 0.5px solid black;
        border-bottom: 0.5px solid black;
      }
    }
  }
  .bubble-with-zindex {
    z-index: 3;
  }
  .bubble-options-modal-positioned-top {
    top: -130px;
    bottom: inherit;
  }
}
.whatsapp-bubble-row-receiver {
  justify-content: flex-end;
  .whatsapp-bubble-receiver {
    background-color: #dcf8c7;
    box-shadow: 0px 1px 1px 0px #00000033;
    max-width: 75%;
    min-width: 50%;
    border-radius: 10px;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      display: block;
      width: 0;
      z-index: -1;
      border-style: solid;
      border-width: 13px 0 0 14px;
      border-color: transparent transparent transparent #dcf8c7;
      top: 85%;
      left: calc(100% - 8px);
      margin-top: -10.5px;
    }
  }
}
.edit-modal-backdrop {
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: 2;
  backdrop-filter: blur(4px);
}
