.instruction-overlay {
  background-color: rgba($color: #000000, $alpha: 0.7);
  position: absolute;
  width: 120%;
  height: 100%;
  z-index: 3;
  top: 0px;
  left: -10%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
  opacity: 1;
  .indicator {
    width: 50%;
    height: 95%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .left-indicator {
    border-right: 2px dashed white;
  }
  .right-indicator {
  }
}
