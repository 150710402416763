.popUpWrapper {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: #f9f9f9;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  .popUp {
    width: 80%;
    aspect-ratio: 1.5;
    max-width: 720px;
    max-height: 528px;
    background-color: #f9f9f9;
    padding: 56px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media only screen and (min-width: 767px) {
      width: 50%;
    }

    h1 {
      font-family: MicrogrammaStd-BoldExtended;
      font-size: 48px;
      margin-bottom: 40px;
      text-align: center;
    }
    .phoneNrAndCopy {
      display: flex;
      align-items: center;
      margin-bottom: 40px;
      img {
        cursor: pointer;
        margin-left: 8px;
      }
      .phoneNr {
        font-family: MicrogrammaStd-BoldExtended;
        text-transform: uppercase;
        font-size: 24px;
        border-bottom: 1px dotted black;
        padding: 0px 5px;
      }
    }
    h2 {
      font-family: "ClashGrotesk-Regular";
      font-size: 20px;
      font-weight: 500;
      text-align: center;
      margin-bottom: 40px;
    }
    div[class^="Button_wrapper"] {
      width: 170px;
      margin-top: 40px;
    }
  }
  .error {
    position: absolute;
    width: 50%;
    height: 40vh;
    background-color: #f9f9f9;
    top: 50%;
    margin-top: -250px;
    left: 50%;
    margin-left: -25%;
    padding: 28px 28px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    h1 {
      font-family: MicrogrammaStd-BoldExtended;
      text-transform: uppercase;
      font-size: 25px;
      width: 80%;
    }
    .phoneNrAndCopy {
      display: flex;
      align-items: center;
      img {
        cursor: pointer;
        margin-left: 8px;
      }
      .phoneNr {
        font-family: MicrogrammaStd-BoldExtended;
        text-transform: uppercase;
        font-size: 20px;
        border-bottom: 1px dotted black;
        padding: 0px 5px;
      }
    }
    h2 {
      font-family: "ClashGrotesk-Regular";
      font-size: 20px;
      width: 80%;
      a {
        color: #5975ff;
      }
    }
    img {
      position: absolute;
      right: 28px;
      top: 14%;
    }
    .arrowBtn {
      bottom: 48px;
      position: absolute;
      border: 1px solid black;
      cursor: pointer;
      background-color: #f3ec00;
      border-radius: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: auto;
      height: 48px;
      font-family: MicrogrammaStd-BoldExtended;
      text-transform: uppercase;
      font-size: 20px;
      align-items: center;
      justify-content: center;
      img {
        margin-left: 12px;
        height: 20px;
      }
    }
  }
}
