.collection-modal {
  width: 640px !important;
  .ant-modal-header {
    background-color: #f9f9f9;
  }
  .ant-modal-content {
    border-radius: 16px;
    padding: 20px 0;
    background-color: #f9f9f9;

    label {
      font-family: ClashGrotesk-Regular;
      color: #3f3f46;
      font-size: 16px;
      margin-top: 20px;
      margin-bottom: 6px;
      font-weight: 600;
      &:first-of-type {
        margin-top: 0px;
      }
    }
    .avatar-uploader {
      > div {
        width: 160px;
        height: 160px;
      }
      .collection-icon {
        width: 100% !important;
        height: 100% !important;
        object-fit: cover;
      }
    }
    .upload-btn {
      max-width: 104px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .upload-text {
        font-family: ClashGrotesk-Regular;
        font-size: 9px;
      }
      .plus-icon-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 41px;
        height: 41px;
        border-radius: 360px;
        background-color: #e4e4e7;
      }
    }
    .buttons {
      display: flex;
      > div {
        margin-top: 30px;

        &:first-of-type {
          margin-right: 12px;
        }
      }
    }
    input,
    textarea {
      padding: 10px 14px;
      font-family: ClashGrotesk-Regular;
      border-radius: 20px;
      border: 1px solid #d1d1d6;
      line-height: 14px;
      background-color: #f9f9f9;
    }
    textarea {
      height: 90px;
    }
    .explanatory {
      font-family: ClashGrotesk-Regular;
      font-weight: normal;
      font-size: 14px;
    }
    .ant-modal-header {
      border: none;
      > div {
        font-family: MicrogrammaStd-BoldExtended;
        font-size: 20px !important;
      }
    }
    .ant-modal-body {
      display: flex;
      flex-direction: column;
    }
  }
}
