$sidebar-bg-color: #f9f9f9;
$icon-bg-color: #f9f9f9 !default;

@import "~react-pro-sidebar/dist/scss/styles.scss";

.dashboard-wrapper {
  position: relative;
  background-color: #f9f9f9;
  height: 100vh;
  .pro-sidebar {
    border-right: 1px solid black;
    @media only screen and (max-width: 767px) {
      display: none;
    }
    .pro-sidebar-header,
    .pro-sidebar-footer {
      padding: 20px 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .pro-sidebar-footer {
      position: absolute;
      bottom: 0px;
      max-width: 100px;
      width: 100%;
      box-sizing: border-box;
      cursor: pointer;
      img {
        width: 40px;
        aspect-ratio: 1;
        border-radius: 60px;
      }
    }

    .pro-menu-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .pro-inner-item {
        padding: 20px 0px;
        .pro-icon-wrapper {
          margin-right: 0px;
        }
        .my-menu-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 14px;
          line-height: 14px;
          font-family: "ClashGrotesk-Medium";
          color: #00000099;

          transition: all 150ms linear;
          svg {
            path {
              fill: #00000099;
              fill-opacity: 1;
            }
            margin-bottom: 8px;
          }
        }
        .special {
          color: #5975ff;
          opacity: 1;
        }
        .my-active {
          opacity: 1;
          display: flex;
          color: #494cfd;

          svg {
            path {
              fill: #494cfd;
              fill-opacity: 1;
            }
            margin-bottom: 8px;
          }
        }
      }
    }
  }
  .pro-sidebar.collapsed {
    width: 100px;
  }
  .row {
    display: flex;
    height: 100%;
    .panel {
      display: flex;
      flex-direction: column;
      padding-left: calc(15% - 100px);
      padding-top: 32px;
      overflow-y: auto;
      h1 {
        font-family: "MicrogrammaStd-BoldExtended";
        text-transform: none;
      }
      h2 {
        font-size: 30px;
        font-family: "MicrogrammaStd-BoldExtended";
        text-transform: none;
      }
      #footer {
        margin-left: -15%;
      }
    }
  }
}
