.update-bot-wrapper {
  height: 80vh;
  width: 75vw;
  margin-top: 24px;
  border: 1px solid black;
  border-radius: 24px;
  overflow: hidden;
  div[class^="MessageFlowBuilder_wrapper"] {
    width: 100%;
  }
  div[class^="MessageFlowBuilder_row_"] {
    border-top: 0px;
    width: 100%;
    .inner-nft {
      .picture-part {
        width: 40% !important;
      }
      .loader-part {
        width: 60% !important;
        padding-left: 20px;
      }
    }
  }
  .phone-sim {
    width: 270px;
    height: 550px;
    margin-top: -50px;
    .imessage-top-part {
      .row {
        height: 70px;
      }
    }
    .imessage-bottom-part {
      height: 35px;
    }
  }
}
