.replies-modal-body {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #f9f9f9;

  .replies-sidebar {
    width: 40%;
    background-color: #f9f9f9;
    height: 100%;
    display: flex;
    flex-direction: column;

    .replies-top {
      border-bottom: 1px solid #d9d9d9;
      padding: 20px;
      .is-flow-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .is-flow-actions {
          display: flex;
          gap: 24px;
          svg {
            cursor: pointer;
          }
        }
      }
      .smart-view-switch {
        margin-top: 20px;
        font-family: ClashGrotesk-Regular;
        display: flex;
        align-items: center;
      }
      h3 {
        font-family: MicrogrammaStd-BoldExtended;
        font-size: 16px;
        margin-bottom: 0px;
        max-width: 70%;
      }
      b {
        font-family: ClashGrotesk-Medium;
        font-weight: 500;
        color: #7a91ff;
      }
      .replies-search {
        display: flex;
        gap: 20px;
        margin-top: 20px;
        position: relative;

        .replies-search-input {
          border-radius: 60px;
          background-color: transparent;
        }
        .replies-total-badge {
          position: absolute;
          right: -10px;
          background-color: #5975ff;
          color: white;
          padding: 0px 6px;
          font-size: 12px;
          border-radius: 60px;
          line-height: 18px;
        }
      }
    }
    .replies-number-list {
      display: flex;
      flex-direction: column;
      overflow: auto;
      align-items: center;
      margin-top: 10px;
    }
  }
  .chat-view {
    background-color: #f0f2ff;
    width: 60%;
    height: 100%;
    border-top-left-radius: 10px;
  }
}
.replies-modal {
  width: 80vw !important;
  max-width: 1000px !important;
  height: 90%;
  top: 50px !important;
  .ant-modal-content {
    border-radius: 20px;
    height: 100%;
  }
  .ant-modal-body {
    padding: 0px;
    height: 60vh !important;
    border-radius: 20px;
    overflow: hidden;
    height: 100% !important;
  }
  .ant-modal-close {
    right: -65px !important;
  }
  .replies-modal-close {
    background-color: #00000040;
    border-radius: 60px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 4px;
    backdrop-filter: blur(4px);
  }
}

.message-replies-dropdown-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 190px;
  div {
    display: flex;
    align-items: center;
  }
  span {
    margin-left: 8px;
  }
  .message-replies-dropdown-badge {
    background-color: #ececed;
    font-family: ClashGrotesk-Regular;
    padding: 1px 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 60px;
  }
}
