.text-el-builder {
  label {
    font-family: NON-Natural-Grotesk-Bold;
    color: rgba(0, 0, 0, 0.4);
    font-size: 14px;
  }
  .row {
    display: flex;
    align-items: center;

    .text-inp {
      height: 35px;
      border-radius: 20px;
      border: 1px solid #b7b7b7;
      width: 350px;
      padding-left: 15px;
      padding-right: 15px;
      margin-top: 5px;
      margin-bottom: 5px;
      margin-right: 5px;
    }

    div:nth-of-type(1) {
      height: 35px;
      border-radius: 20px;
      border: 1px solid #b7b7b7;
      width: 150px;
      margin-top: 5px;
      margin-bottom: 5px;
      margin-right: 5px;
      box-shadow: none;

      button {
        background-color: transparent !important;
        &:focus {
          background-color: transparent !important;
        }
      }
    }
    .color-abs {
      position: relative;
      width: 35px;
      height: 35px;
      input {
        width: 35px;
        height: 35px;
        border-radius: 20px;
        border: 1px solid #b7b7b7;
        width: 35px;
      }
      div {
        margin: 0px;
        border: 0px;
      }
      .img-abs {
        cursor: pointer;
        width: 35px;
        height: 35px;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        left: 0px;
        top: 0px;
        img {
          width: 20px;
        }
      }
    }
    .color-picker {
      width: 35px;
      background-color: transparent;
      padding: 0px;
      &::-webkit-color-swatch-wrapper {
        padding: 0px;
      }
      &::-webkit-color-swatch {
        border: none;
        border-radius: 50%;
        width: 42px;
      }
    }
  }
}
