.fields {
  display: flex;
  flex-direction: column;
  height: calc(100% - 243px);
  overflow-y: auto;

  padding-bottom: 150px;
  .moreInfo {
    font-family: "ClashGrotesk-Regular";
    font-weight: normal;
  }
  .field,
  .innerField {
    display: flex;
    flex-direction: column;
    padding: 0px 40px;
    &:first-of-type {
      label {
        margin-top: 0px;
      }
    }

    label {
      font-family: "NON-Natural-Grotesk-Regular";
      font-weight: bold;
      color: #3f3f46;
      font-size: 14px;
      margin-bottom: 12px;
      margin-top: 40px;
    }
    h5 {
      font-family: "MicrogrammaStd-BoldExtended";
      color: black;
      font-size: 18px;
      margin-bottom: 12px;
      margin-top: 40px;
    }
    .textarea,
    input {
      color: black !important;
      -webkit-text-fill-color: #000000 !important;
      &::placeholder {
        color: rgba($color: #000000, $alpha: 0.4) !important;
        -webkit-text-fill-color: #00000040 !important;
      }
      border: 1px solid rgba($color: #000000, $alpha: 0.1) !important;
      border-radius: 16px;
      font-size: 18px;
      font-family: "NON-Natural-Grotesk-Regular";
      font-weight: bold;
      padding: 8px;
      outline-color: #000;
    }
    .textarea {
      height: 95px;
    }
    input {
      height: 48px;
    }
    div {
      border: none;
      outline: none;
      &:focus-within {
        box-shadow: none;
      }
      span {
        background-color: transparent;
        font-family: "NON-Natural-Grotesk-Regular";
        font-weight: bold;
        button {
          height: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 3px;
        }
        span {
          border: none !important;
        }
      }
    }
  }
  .innerField {
    padding: 0px;
    &:first-of-type {
      margin-top: 24px;
    }
  }
}
