.whatsapp-builder-wrapper {
  margin-top: 20px;
  display: flex;
  min-height: 90vh;

  .whatsapp-sidebar-open {
    width: 20%;
    b {
      position: absolute;
      left: 40px;
      font-family: MicrogrammaStd-BoldExtended;
      font-size: 24px;
      animation: fade 150ms ease-in;
    }
    > svg {
      transform: rotateZ(180deg);
      right: 40px;
    }
    .whatsapp-sidebar-paths {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 50px;
      padding: 0px 40px;
    }
  }
  .whatsapp-section {
    flex: 1;
    background: rgb(74, 103, 99);
    background: linear-gradient(
      34deg,
      rgba(74, 103, 99, 1) 0%,
      rgba(71, 84, 103, 1) 100%
    );
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 500px;
    .whatsapp-UI-section {
      width: 25%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        position: absolute;
        width: 100%;
        z-index: 0;
      }
    }
  }
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.whatsapp-sidebar {
  background-color: #f9f9f9;
  width: 80px;
  height: 88vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 150ms ease-in;
  position: relative;
  > svg {
    top: 8px;
    transition: all 150ms ease-in;
    cursor: pointer;
    position: absolute;
  }
}
