.raffle-form-editor {
  .appearance-editor,
  .raffle-details,
  .not-editable-fields {
    border: 1px solid #d1d1d6;
    border-radius: 24px;
    margin-top: 24px;
    padding: 24px;
    h3 {
      font-family: MicrogrammaStd-BoldExtended;
      font-size: 20px;
    }
    .appearance-fields {
      .appearance-field {
        margin-top: 16px;
        display: flex;
        b {
          width: 33%;
          font-family: ClashGrotesk-Medium;
          color: #3f3f46;
        }
        .field-type {
          width: 60%;

          .color-picker {
            position: relative;
            border-radius: 24px;
            padding: 10px 14px;
            cursor: pointer;
            display: flex;
            border: 1px solid #d1d1d6;
            height: 44px;
            align-items: center;
            font-family: ClashGrotesk-Medium;
            font-size: 16px;
            .color-circle {
              margin-right: 8px;
              border-radius: 60px;
              width: 24px;
              aspect-ratio: 1;
              border: 1px solid #d1d1d6;
            }
            .color-picker-sketch {
              position: absolute;
              top: 100%;
              z-index: 9;
            }
          }
          .raffle-input {
            border-radius: 24px;
            padding: 10px 14px;
            display: flex;
            border: 1px solid #d1d1d6;
            height: 44px;
            align-items: center;
            font-family: ClashGrotesk-Medium;
            font-size: 16px;
            background-color: transparent;
            width: 100%;
          }
        }
        div[class^="Button"] {
          width: fit-content;
          margin-top: 0px;
          button {
            height: 40px !important;
            font-family: ClashGrotesk-Medium;
          }
        }
      }
    }
    .access-page-info {
      border: 1px solid #d1d1d6;
      border-radius: 24px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      .avatar-uploader,
      .ant-upload {
        width: 70px;
        height: 70px;
        margin-bottom: 16px;
        border-radius: 60px;
        position: relative;

        img {
          width: 70px;
          height: 70px;
          object-fit: cover;
          border-radius: 60px;
        }
        .remove-access-img {
          width: 32px;
          height: 32px;
          background-color: #18181b;
          position: absolute;
          top: -10px;
          right: -10px;
          border-radius: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          svg {
            width: 20px;
            height: 20px;
            path {
              stroke: white;
            }
          }
        }
      }
      .access-title {
        height: 52px;
        margin-bottom: 16px;
        background-color: transparent;
        border: 1px solid #d1d1d6;
        padding: 10px 16px;
        border-radius: 60px;
        font-family: MicrogrammaStd-BoldExtended;
        font-size: 24px;
      }
      .DraftEditor-editorContainer {
        padding: 10px 16px;
        border: 1px solid #d1d1d6;
        border-radius: 20px;
        display: flex;
        align-items: center;
        height: 154px;
        padding: 16px;
      }
      .public-DraftEditorPlaceholder-root {
        padding: 16px;
      }
      .public-DraftEditor-content {
        padding: 16px 0px;
        font-family: ClashGrotesk-Regular;
        font-size: 16px;
        width: 100%;
        height: 154px;
        overflow-y: scroll;
      }
      .richtext-raffle {
        .toolbar {
          & > div {
            border: none;
            background-color: transparent;
            box-shadow: none;
          }
          & > div > div > div > button {
            background-color: transparent;
          }
        }
      }
      .richtext-raffle-blacked{
        cursor: not-allowed;
      }
    }

    .not-editable-field {
      display: flex;
      align-items: center;
      svg {
        width: 24px;
        height: 24px;
        margin-right: 12px;
      }
      .placeholder {
        border: 1px solid #d1d1d6;
        width: 100%;
        height: 40px;
        border-radius: 60px;
        display: flex;
        align-items: center;
        padding: 0px 12px;
        font-family: ClashGrotesk-Regular;
        font-size: 16px;
        cursor: not-allowed;
      }
    }
  }
}
