@import "../../App.scss";

.landing-email-styles {
  .overlay-loading {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 9999999;
  }
  .landing-email-background {
    width: 100%;
    height: 100vh;
    min-height: 800px;
    background-color: #141414;
    transition: all 350ms linear;
    .landing-email-wrapper {
      background-repeat: no-repeat;
      background-size: cover !important;
      background-position: var(--background-position) !important;
      display: flex;
      flex-direction: column;
      height: 100%;
      position: relative;
      align-items: center;
      .video-background-email {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .overlay-gradient {
        position: absolute;

        width: 100%;
        height: 100%;
        background: transparent;
        background: linear-gradient(
          180deg,
          rgba(204, 0, 0, 0) 0%,
          rgba(12, 12, 12, 1) 100%
        );
      }
      .new-landing-content {
        z-index: 2;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        .tabs {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 15px;
          .tab {
            width: 95px;
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #000000;
            border-radius: 60px;
            color: #fff;
            font-family: var(--secondary-font);
            cursor: pointer;
            transition: all 150ms ease-in;
          }
          .active-landing {
            background-color: var(--tab-color);
            color: #000000;
          }
        }
      }
      h1 {
        font-family: var(--heading-1-font);
        color: var(--heading-1-color);
        width: 90%;
        margin-bottom: 18px;
        text-align: center;
        font-size: 22px;
      }
      h2 {
        font-family: ClashGrotesk-Regular;
        color: var(--heading-2-color);
        font-size: var(--heading-2-font-size);
        margin-bottom: 0px;
        padding: 5px 30px;
      }
      .artist-logo {
        margin-top: var(--logo-top);
        width: var(--logo-width);
        z-index: 2;
      }
      .mute-unmute-wrapper {
        position: absolute;
        top: 5%;
        right: 5%;
        z-index: 9;
        .mute-unmute-button {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          background-color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          transition: all 350ms linear;
          &:hover {
            background-color: #141414;
          }
        }
      }
      .content-wrapper {
        width: 100%;
        margin-top: 20px;
        z-index: 2;
        .buttons-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-left: 25px;
          margin-right: 25px;
          div[class^="Button"] {
            button {
              justify-content: center;
            }
          }
          .us-button-holder {
            display: flex;
            flex-direction: column;
            width: 100%;
            align-items: center;
            // margin-bottom: 40px;
            margin-bottom: 10px;
            div[class^="Button"] {
              margin-top: 10px;
              width: 87%;
              button {
                justify-content: center;
              }
            }
          }
        }
        .text-wrapper {
          color: var(--primary-color);
          padding: 0px 25px;
          h1 {
            font-family: var(--primary-font);
            font-style: normal;
            font-weight: normal;
            font-size: 22px;
            line-height: 16px;
            margin: 5px 0px;
          }
          h2 {
            width: 100%;
            font-family: var(--primary-font);
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 17px;
            color: var(--primary-color);
            margin: 5px 0px;
          }
          h3 {
            font-family: var(--secondary-font) t;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            margin: 5px 0px;
          }
          margin-bottom: 50px;
        }
      }
      .error-message {
        line-height: 12px;
        font-family: Sequel100Wide45;
        color: #d80027;
        font-size: 14px;
        margin-left: 30px;
      }
    }
    .copyright-container {
      color: var(--heading-1-color);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;

      svg {
        height: 20px;
        width: 30px;
      }
    }
    .copyright {
      padding: 0px 25px;
      // margin-top: 8px;
      text-align: center;
      p {
        color: var(--heading-1-color);
        font-family: var(--primary-font);
        font-size: 10px;
        a {
          color: var(--heading-1-color);
        }
      }
    }
  }
  .desktop-wrapper {
    display: none;
    z-index: -1;
    position: absolute;
    min-height: 100vh;
    width: 100%;
    background-size: cover !important;
    background-position: center !important;
    filter: none !important;
    video {
      z-index: -1;
      height: 100vh;
    }
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 480px) {
    .overlay-gradient {
      border-radius: 12px;
    }

    .desktop-wrapper {
      display: flex;
      position: absolute;
    }
    .landing-email-wrapper {
      // background: none !important;
      height: 720px;
      max-height: 720px;
      border-radius: 12px;
      .video-background-email {
        border-radius: 12px;
      }

      #video-phone {
        display: block;
      }
    }

    .landing-email-background {
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      top: 10%;
      filter: none;
      width: 375px;
      height: 720px;
      border: 0px solid white;
      overflow: hidden;
      // background: none !important;
      background-color: transparent !important;
      // padding: 10px;
      .landing-email-wrapper {
        padding-bottom: 25px;
      }
      .text-wrapper {
        bottom: 85px !important;
      }
      .copyright {
        width: 100%;
      }
    }
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    .landing-email-wrapper {
      // background: none !important;
      // height: 80vh !important;
    }
    .landing-email-background {
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      top: 10%;
      filter: none !important;
      width: 630px;
      border: 0px solid white;
      overflow: hidden;
      // background: none !important;
      background-color: transparent !important;
      // padding: 10px;
      .landing-email-wrapper {
        padding-bottom: 25px;
        .text-wrapper {
          h1 {
            font-size: 24px;
          }
        }
        h2 {
          font-size: 18px !important;
        }
        div[class^="Button"] {
          width: 55% !important;
        }
        .input-container {
          width: 55%;
        }
      }
      .copyright {
        width: 100%;
        p {
          font-size: 14px !important;
        }
      }
    }
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    .landing-email-wrapper {
      // background: none !important;
      // height: 93vh !important;
    }

    .landing-email-background {
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      top: 140px;
      filter: none !important;
      width: 630px;
      background-color: transparent !important;
      overflow: hidden;
      background-color: transparent;
      // padding: 10px;
      .landing-email-wrapper {
        padding-bottom: 5px;
        max-height: 800px;
        .text-wrapper {
          margin-bottom: 70px !important;
        }
      }

      .copyright {
        width: 100%;
      }
    }
  }
}
