.activation-with-actions {
  display: flex;
  width: 100%;
  gap: 20px;
  .activation-actions {
    width: 20%;
    div[class*="Button"] {
      margin-top: 20px;
      padding-left: 0px;
      width: 100%;
      button {
        gap: 8px;
        justify-content: center;
        font-size: 14px;
        text-align: center;
      }
    }
    .activation-filter {
      width: 100%;
      height: 44px;
      padding: 0px 18px;
      display: flex;
      align-items: center;
      font-family: ClashGrotesk-Medium;
      font-size: 18px;
      cursor: pointer;
      color: #70707b;
      transition: all 150ms ease-in;
      border-radius: 8px;
      &:hover {
        background-color: #a9b7fd;
        color: white;
      }
    }
    .active-filter {
      color: white;
      background-color: #7a91ff;
      &:hover {
        color: white;
        background-color: #7a91ff;
      }
    }
  }
  .activation-tiles {
    flex: 1;
    display: flex;
    flex-direction: column;
    .activation-tile {
      position: relative;
      cursor: pointer;
      padding: 24px;
      margin-top: 16px;
      border-radius: 16px;
      border: 1px solid #e4e4e7;
      width: 80%;
      .status-and-type {
        display: flex;
        gap: 6px;
        margin-top: 10px;
        .conversation-type {
          font-family: ClashGrotesk-Regular;
          border-radius: 60px;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 30px;
          height: 30px;
        }
      }
      .tile-header {
        gap: 50px;
        display: flex;
        align-items: start;

        .avatar-stats {
          max-width: 50%;

          span,
          input {
            font-family: MicrogrammaStd-BoldExtended;
            font-size: 18px;
            width: 100%;
          }
          div {
            font-family: ClashGrotesk-Medium;
            color: #18181b;
          }
        }
        .is-editing-avatar-stats {
          max-width: 80%;
          width: 80%;
        }
        .activation-edit {
          background-color: #0000000f;
          width: 25px;
          height: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 60px;
          transition: all 150ms ease-in;

          img {
            width: 20px;
            height: 20px;
          }
        }

        &:hover {
          .activation-edit {
            background-color: #f3ec00;
          }
        }
      }
      .tile-body-row {
        display: flex;
      }
      .tile-body {
        margin-top: 12px;
        margin-left: 62px;
        font-family: ClashGrotesk-Regular;
        font-size: 18px;
        padding-bottom: 12px;
      }
      .carousel {
        margin-left: 62px;
        width: 80%;
        aspect-ratio: 1;
        margin-top: 10px;
        margin-bottom: 20px;
        .carousel-controls {
          cursor: pointer;
          background-color: #00000066;
          width: 48px;
          aspect-ratio: 1;
          margin: 16px;
          border-radius: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          backdrop-filter: blur(4px);

          img {
            width: 24px;
          }
        }
        .paging-item {
          height: 18px !important;
          margin-top: 0px;
          margin-bottom: 12px;
          button {
            fill: rgba(255, 255, 255, 0.6) !important;
          }
        }
        .active {
          button {
            fill: #5975ff !important;
          }
        }
        .control-left {
          img {
            transform: rotateZ(180deg);
          }
        }
        img {
          aspect-ratio: 1;
          width: 100%;
          object-fit: cover;
        }
      }
      .tile-footer {
        display: flex;

        margin-top: 16px;
        align-items: center;
        font-family: ClashGrotesk-Medium;
        font-size: 16px;
        img {
          margin-right: 8px;
        }
        .tile-stat {
          margin-right: 28px;
          font-family: ClashGrotesk-Medium;
          font-size: 16px;
          display: flex;
          align-items: center;
          img {
            margin-right: 8px;
          }
        }
      }
      .activation-delete {
        background-color: #0000000f;
        position: absolute;
        top: 24px;
        right: 24px;
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 60px;
        transition: all 150ms ease-in;
        img {
          width: 20px;
          height: 20px;
        }
      }
      .activation-edit:hover,
      .activation-delete:hover {
        background-color: #f3ec00;
      }
    }
  }
}
