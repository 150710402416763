.model-viewer {
  div {
    width: 180px !important;
    height: 180px !important;
    canvas {
      width: 100% !important;
      height: 100% !important;
    }
  }
}
