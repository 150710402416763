.popUpWrapper {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: #00000066;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  .popUp {
    position: relative;
    width: 90%;
    aspect-ratio: 1.5;
    max-width: 685px;
    max-height: 456px;
    background-color: #f9f9f9;
    padding: 18px 28px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 40px;
    @media only screen and (min-width: 1024px) {
      width: 50%;
    }
    .poll-progressbar-wrapper
      .poll-progressbar-cell-clickable
      .poll-progressbar-cell {
      background-color: #d6d6d6;
      height: 4px;
    }
    .poll-progressbar-wrapper .poll-progressbar-cell-clickable .previous {
      background-color: #5975ff !important;
    }
    .pause-button {
      display: none;
    }
    h1 {
      font-family: MicrogrammaStd-BoldExtended;

      font-size: 36px;
      width: 73%;
      line-height: 44px;
      margin-bottom: 0px;
    }
    h2 {
      font-family: "ClashGrotesk-Regular";
      font-size: 18px;
      font-weight: bold;
      width: 73%;
      line-height: 28px;
    }
    .spikey {
      position: absolute;
      right: 25px;
      top: 20%;
      @media only screen and (max-width: 768px) {
        display: none;
      }
    }
    .arrowBtn {
      border: 1px solid black;
      cursor: pointer;
      background-color: #f3ec00;
      border-radius: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 130px;
      height: 48px;
      font-family: MicrogrammaStd-BoldExtended;
      text-transform: uppercase;
      min-width: 150px;
      font-size: 16px;
      padding-left: 12px;
      img {
        margin-left: 12px;
        height: 20px;
      }
    }
  }
}
