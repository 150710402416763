.whatsapp-ui-container {
  width: 92%;
  background: url("../../../../../assets/whatsapp-background.png");
  background-size: 250px;
  height: 96%;
  border-radius: 40px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  z-index: 1;
  position: relative;
  .whatsapp-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 80px;
    background-color: #f6f6f6;
    border-bottom: 1px solid #a4a39e;
    padding: 0px 10px;
    .status-bar {
      padding: 5px 5px 0px 15px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      b {
        font-size: 12px;
      }
      svg {
        height: 10px;
      }
    }
    .whatsapp-contact-header {
      padding-bottom: 8px;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      width: 100%;
      svg {
        width: 24px;
        height: 24px;
        color: #007aff;
      }
      .whatsapp-profile-picture {
        width: 36px;
        height: 36px;
        border-radius: 60px;
        overflow: hidden;
        position: relative;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .whatsapp-profile-name {
        display: flex;
        flex-direction: column;
        justify-content: center;
        h3 {
          font-size: 16px;
          padding: 0px;
          margin-bottom: 0px;
          font-family: ClashGrotesk-Medium;
          line-height: 16px;
          display: flex;
          align-items: center;
          svg {
            margin-left: 4px;
            width: 18px;
            height: 18px;
          }
        }
        b {
          font-size: 12px;
          font-weight: 200;
          color: #898989;
          line-height: 12px;
          font-family: ClashGrotesk-Medium;
        }
      }
    }
  }

  .whatsapp-bubble-holders {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 15px;
    overflow-y: auto;
  }
  .whatsapp-inputs {
    min-height: 70px;
    max-height: 150px;
    transition: all 150ms ease-in;
    background-color: #f6f6f6;
    border-top: 1px solid #a4a39e;
    padding: 0px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .whatsapp-input-tools {
      display: flex;
      width: 100%;
      align-items: flex-end;
      gap: 10px;
      margin-top: 10px;
      margin-bottom: 20px;
      .whatsapp-action {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 7px;
        svg {
          box-sizing: border-box;
          width: 18px;
          height: 18px;
        }
      }
      .pill-shape-send {
        display: flex;
        align-items: center;
        gap: 4px;
        background-color: #519ef6;
        padding: 8px;
        border-radius: 60px;
        position: relative;
        svg {
          cursor: pointer;
        }
        .sender-receiver-modal {
          position: absolute;
          width: max-content;
          background-color: white;
          right: 0px;
          top: -250%;
          z-index: 9;
          box-shadow: -2px 10px 23px -5px rgba(0, 0, 0, 0.43);
          border-radius: 12px;
          font-family: ClashGrotesk-Medium;
          color: #3f3f46;
          overflow: hidden;

          div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 10px;
            height: 35px;
            padding: 11px 16px;
            cursor: pointer;
            transition: all 100ms ease-in;
            &:hover {
              background-color: #e6e6e6;
            }
          }
        }
      }
      .receiver-pill {
        background-color: #25d366;
      }
      textarea {
        min-height: 30px;
        height: fit-content;
        width: min-content;
        border: 1px solid #dedfe0;
        border-radius: 16px;
        padding: 0px 10px;
        padding-top: 2px;
        flex: 1;
        resize: none;
        max-height: 112px;
        transition: none !important;
        background-color: white !important;
      }
    }
    .attach-options {
      display: flex;
      width: 100%;
      justify-content: space-around;
      margin-bottom: 20px;
      .attach-option {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: ClashGrotesk-Medium;
        color: #455154;
        .attach-icon {
          width: 44px;
          height: 44px;
          background-color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 60px;
        }
      }
    }
    .iphone-gesture-bar {
      width: 40%;
      background-color: black;
      height: 5px;
      position: absolute;
      border-radius: 60px;
      bottom: 5px;
    }
  }
  .sender-receiver-backdrop {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.22);
    width: 100%;
    height: 100%;
  }
}
.reply-options-modal {
  position: absolute !important;
  right: 50px;
  top: 35% !important;
  width: 370px !important;
  .ant-modal-content {
    border-radius: 20px;
  }
  h3 {
    font-family: MicrogrammaStd-BoldExtended;
    font-size: 20px;
  }
  .reply-options {
    display: flex;
    gap: 20px;
    margin-top: 40px;
    .reply-option {
      width: 100px;
      padding: 2px 24px;
      background-color: #f4f4f5;
      border-radius: 60px;
      font-family: ClashGrotesk-Regular;
      cursor: pointer;
      text-transform: uppercase;
      font-size: 12px;
      transition: all 150ms ease-in;
      text-align: center;
    }
    .active-reply-option {
      background-color: black;
      color: #fff;
    }
  }
  .reply-pathways {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .reply-path {
      display: flex;
      gap: 12px;
      input {
        flex: 1;
        border-radius: 60px;
        border: 1px solid #d1d1d6;
        padding: 0px 14px;
      }
      .reply-path-icon {
        display: flex;
        width: 40px;
        height: 40px;
        align-items: center;
        justify-content: center;
        background-color: #ececed;
        border-radius: 60px;
        cursor: pointer;
      }
    }
  }
  .reply-path-save {
    display: flex;
    margin-top: 20px;

    div[class*="Button"] {
      margin-top: 0px;
      width: min-content;
    }
  }
}
