.wrapper {
  background-color: #f9f9f9;
  width: 100vw;
  height: 100vh;
  .row {
    display: flex;
    width: 100%;
    padding-left: 15%;
    box-sizing: border-box;
    flex-flow: wrap-reverse;
    @media only screen and (max-width: 1024px) {
      padding-left: 8%;
    }
  }
  .signupForm {
    width: 100%;
    margin-top: 0.5%;
    h1 {
      font-family: MicrogrammaStd-BoldExtended;
      font-size: 32px;
      margin-bottom: 18px;
      line-height: 32px;
    }
    h6 {
      margin-top: -8px;
      font-family: "ClashGrotesk-Regular";
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 40px;
    }
    @media only screen and (min-width: 768px) {
      width: 50%;
    }
  }
  .inputs {
    width: 90%;
    div[class^="Button"] {
      margin-top: 40px;
    }
    button {
      width: fit-content;
    }
    b {
      font-family: "ClashGrotesk-Regular";
      font-size: 14px;
      font-weight: normal;
      position: absolute;
      bottom: 148px;
      a {
        color: #5975ff;
        text-decoration: none;
      }
    }
    div {
      margin-top: 8px;
    }
    label {
      font-family: "ClashGrotesk-Medium";
      font-weight: 500;
      font-size: 14px;
    }
    @media only screen and (min-width: 768px) {
      width: 70%;
    }
  }
  .illustration {
    margin-top: 7%;
    margin-left: auto;
    margin-right: 22%;
    width: 100%;
    img {
      max-width: 150px;
    }
    @media only screen and (min-width: 768px) {
      width: 320px;
      margin-left: 0px;
      margin-right: 0%;
      img {
        max-width: 320px;
      }
    }
  }
}
.fullName {
  display: flex;
  width: 100%;
}
.firstName {
  margin-right: 16px;
  width: 50%;
}
.lastName {
  width: 50%;
}

.loadingWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
