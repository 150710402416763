.get-verified {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .get-verified-content {
    color: inherit;
    width: 340px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    .get-verified-img {
      width: 100px;
      aspect-ratio: 1;
      object-fit: cover;
      border-radius: 60px;
    }
    h1 {
      color: inherit;
      font-size: 24px;
      font-family: MicrogrammaStd-BoldExtended;
      margin-top: 40px;
      margin-bottom: 20px;
    }
    b {
      margin-bottom: 40px;
    }
    b,
    a {
      color: inherit;
      font-family: ClashGrotesk-Regular;
      text-align: center;
    }
    a {
      text-decoration: underline;
    }
    .copyright-text {
      margin-top: 12px;
    }
    .phone-number-wrapper .PhoneInput {
      border-radius: 60px !important;
      border-width: 1px !important;
    }
    .phone-number-wrapper .PhoneInput .PhoneInputInput,
    .phone-number-wrapper .country-code {
      font-family: ClashGrotesk-Regular;
    }
    .phone-number-wrapper .country-code {
      //   left: 12px;
    }
    .PhoneInputCountryIconImg {
      // margin-left: -200%;
    }
    .PhoneInputCountryIcon--border {
      box-shadow: none;
    }
    div[class^="Button"] {
      margin-top: 20px;
    }
  }
}
.error-not-a-member {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  svg {
    width: 65px;
    height: 65px;
    margin-bottom: 48px;
    path {
      fill: inherit !important;
    }
  }
  h1 {
    font-family: MicrogrammaStd-BoldExtended;
    color: inherit;
  }
  b {
    font-family: ClashGrotesk-Regular;
    width: 80%;
    text-align: center;
  }
  .not-a-member-actions {
    div[class^="Button"] {
      width: fit-content;
      min-width: 340px;
    }
    div:last-of-type {
      // margin-top: 16px !important;
    }
  }
}
.error-not-valid {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  svg {
    width: 65px;
    height: 65px;
    margin-bottom: 48px;
    path {
      fill: inherit !important;
    }
  }
  h1 {
    font-family: MicrogrammaStd-BoldExtended;
    color: inherit;
  }
  b {
    font-family: ClashGrotesk-Regular;
    width: 80%;
    text-align: center;
  }
  .not-a-member-actions {
    div[class^="Button"] {
      width: fit-content;
      min-width: 340px;
    }
    div:last-of-type {
      // margin-top: 16px !important;
    }
  }
}
