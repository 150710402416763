.collection-id-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  div[class^="Button"] {
    margin-top: 0px;
    width: fit-content;
  }
  .collectionid-header {
    margin-top: 48px;
    display: flex;
    align-items: center;
    img {
      width: 60px;
      height: 60px;
      border-radius: 12px;
      object-fit: cover;
    }
    .collectionid-description {
      display: flex;
      flex-direction: column;
      margin-left: 32px;

      b {
        font-size: 30px;
        font-family: "MicrogrammaStd-BoldExtended";
      }
    }
  }
  .card-container {
    margin-top: 24px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
  }
}
