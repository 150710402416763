.collection-card-wrapper {
  border: 1.5px solid #d1d1d6;
  width: 364px;
  height: 252px;
  border-radius: 20px;
  margin-right: 16px;

  margin-bottom: 16px;
  cursor: pointer;
  .create-collection {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    b {
      margin-top: 12px;
      color: #18181b;
      font-family: ClashGrotesk-Regular;
      font-size: 14px;
    }
  }
  .collection-data {
    height: 100%;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .collection-header {
      display: flex;
      flex-direction: column;
      img {
        width: 60px;
        height: 60px;
        border-radius: 12px;
        object-fit: cover;
      }
      b {
        margin-top: 12px;
        font-size: 30px;
      }
    }
    .collection-footer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .tags {
        display: flex;
        flex-direction: row;
        .tag {
          background-color: #eff8ff;
          margin-right: 12px;
          padding: 2px 10px;
          border-radius: 60px;
          font-family: ClashGrotesk-Medium;
        }
      }
      .more-options {
        svg {
          color: black;
        }
      }
    }
  }
}
