@font-face {
  font-family: "Sequel100Wide45";
  src: local("Sequel100Wide45"),
    url("./assets/Fonts/Sequel 100 Wide 45 Wide.otf") format("truetype");
}
@font-face {
  font-family: "Sequel100Wide46";
  src: local("Sequel100Wide46"),
    url("./assets/Fonts/Sequel 100 Wide 46 Wide.otf") format("truetype");
}
@font-face {
  font-family: "Sequel100Wide56";
  src: local("Sequel100Wide56"),
    url("./assets/Fonts/Sequel 100 Wide 56 Wide.otf") format("truetype");
}
@font-face {
  font-family: "Sequel100Wide65";
  src: local("Sequel100Wide65"),
    url("./assets/Fonts/Sequel 100 Wide 65 Wide.otf") format("truetype");
}
@font-face {
  font-family: "Sequel100Wide66";
  src: local("Sequel100Wide66"),
    url("./assets/Fonts/Sequel 100 Wide 66 Wide.otf") format("truetype");
}
@font-face {
  font-family: "Sequel100Wide75";
  src: local("Sequel100Wide75"),
    url("./assets/Fonts/Sequel 100 Wide 75 Wide.otf") format("truetype");
}
@font-face {
  font-family: "Sequel100Wide76";
  src: local("Sequel100Wide76"),
    url("./assets/Fonts/Sequel 100 Wide 76 Wide.otf") format("truetype");
}
@font-face {
  font-family: "Sequel100Wide85";
  src: local("Sequel100Wide85"),
    url("./assets/Fonts/Sequel 100 Wide 85 Wide.otf") format("truetype");
}
@font-face {
  font-family: "Sequel100Wide86";
  src: local("Sequel100Wide86"),
    url("./assets/Fonts/Sequel 100 Wide 86 Wide.otf") format("truetype");
}
@font-face {
  font-family: "Sequel100Wide95";
  src: local("Sequel100Wide95"),
    url("./assets/Fonts/Sequel 100 Wide 95 Wide.otf") format("truetype");
}
@font-face {
  font-family: "Sequel100Wide96";
  src: local("Sequel100Wide96"),
    url("./assets/Fonts/Sequel 100 Wide 96 Wide.otf") format("truetype");
}
@font-face {
  font-family: "Swiss721BT";
  src: local("Swiss721BT"),
    url("./assets/Fonts/Swis721_Cn_BT_Roman.ttf") format("truetype");
}
@font-face {
  font-family: "Univers";
  src: local("UniversNextPro-Regular"),
    url("./assets/Fonts/UniversNextPro-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "UniversNextPro-MediumExt";
  src: local("UniversNextPro-MediumExt"),
    url("./assets/Fonts/UniversNextPro-MediumExt.ttf") format("truetype");
}
@font-face {
  font-family: "UniversNextPro-BoldExt";
  src: local("UniversNextPro-BoldExt"),
    url("./assets/Fonts/UniversNextPro-BoldExt.ttf") format("truetype");
}
@font-face {
  font-family: "BookmanJFPro-Regular";
  src: local("BookmanJFPro-Regular"),
    url("./assets/Fonts/BookmanJFPro-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "BookmanJFPro-Italic";
  src: local("BookmanJFPro-Italic"),
    url("./assets/Fonts/BookmanJFPro-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "FuturaPTBook";
  src: local("FuturaPTBook"),
    url("./assets/Fonts/FuturaPTBook.otf") format("truetype");
}
@font-face {
  font-family: "Poppins-Regular";
  src: local("Poppins-Regular"),
    url("./assets/Fonts/Poppins-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "MicrogrammaStd-BoldExtended";
  src: local("MicrogrammaStd-BoldExtended"),
    url("./assets/Fonts/MicrogrammaStd-BoldExtended.otf") format("truetype");
}
@font-face {
  font-family: "NON-Natural-Grotesk-Regular";
  src: local("NON-Natural-Grotesk-Regular"),
    url("./assets/Fonts/NON-Natural-Grotesk-Regular.otf") format("truetype");
}
@font-face {
  font-family: "NON-Natural-Grotesk-Bold";
  src: local("NON-Natural-Grotesk-Bold"),
    url("./assets/Fonts/NON-Natural-Grotesk-Bold.otf") format("truetype");
}
@font-face {
  font-family: "Plain-Medium";
  src: local("Plain-Medium"),
    url("./assets/Fonts/Plain-Medium.otf") format("truetype");
}
@font-face {
  font-family: "DOVELOVE";
  src: local("DOVELOVE"), url("./assets/Fonts/DOVELOVE.otf") format("truetype");
}
@font-face {
  font-family: "KINKEE";
  src: local("KINKEE"), url("./assets/Fonts/KINKEE.otf") format("truetype");
}
@font-face {
  font-family: "ClashGrotesk-Variable";
  src: url("./assets/Fonts/ClashGrotesk-Variable.woff2") format("woff2"),
    url("./assets/Fonts/ClashGrotesk-Variable.woff") format("woff"),
    url("./assets/Fonts/ClashGrotesk-Variable.ttf") format("truetype");
  font-weight: 200 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "ClashGrotesk-Extralight";
  src: url("./assets/Fonts/ClashGrotesk-Extralight.woff2") format("woff2"),
    url("./assets/Fonts/ClashGrotesk-Extralight.woff") format("woff"),
    url("./assets/Fonts/ClashGrotesk-Extralight.ttf") format("truetype");
  font-weight: 200;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "ClashGrotesk-Light";
  src: url("./assets/Fonts/ClashGrotesk-Light.woff2") format("woff2"),
    url("./assets/Fonts/ClashGrotesk-Light.woff") format("woff"),
    url("./assets/Fonts/ClashGrotesk-Light.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "ClashGrotesk-Regular";
  src: url("./assets/Fonts/ClashGrotesk-Regular.woff2") format("woff2"),
    url("./assets/Fonts/ClashGrotesk-Regular.woff") format("woff"),
    url("./assets/Fonts/ClashGrotesk-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "ClashGrotesk-Medium";
  src: url("./assets/Fonts/ClashGrotesk-Medium.woff2") format("woff2"),
    url("./assets/Fonts/ClashGrotesk-Medium.woff") format("woff"),
    url("./assets/Fonts/ClashGrotesk-Medium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "ClashGrotesk-Semibold";
  src: url("./assets/Fonts/ClashGrotesk-Semibold.woff2") format("woff2"),
    url("./assets/Fonts/ClashGrotesk-Semibold.woff") format("woff"),
    url("./assets/Fonts/ClashGrotesk-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "ClashGrotesk-Bold";
  src: url("./assets/Fonts/ClashGrotesk-Bold.woff2") format("woff2"),
    url("./assets/Fonts/ClashGrotesk-Bold.woff") format("woff"),
    url("./assets/Fonts/ClashGrotesk-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
