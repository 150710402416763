.nft-mainContainer {
  display: flex;
  margin: 0;
  padding: 0;
  transition: transform 0.5s ease;
  flex-direction: column;
  align-items: center;

  .nftItem {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 300px;
    transform: scale(-0.9);
    opacity: 0;
    left: 0px;
    transition: all 0.5s ease-in-out;
    background-size: cover;
    background-position: center;
    width: 100%;
  }

  .nftItem.active {
    transform: scale(1);
    opacity: 1;
  }
}
