.empty-playbook-wrapper {
  margin-top: 32px;
  width: 100%;
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #d1d1d6;
  img {
    width: 105px;
    height: 105px;
  }
  h1 {
    margin-top: 20px;
    margin-bottom: 0px;
  }
  span {
    font-family: ClashGrotesk-Regular;
    text-align: center;
  }
}
