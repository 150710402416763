.community-wrapper {
  width: 75vw;

  @media only screen and (max-width: 768px) {
    padding: 24px;
    width: 100%;
  }

  .sub-header {
    display: flex;
    align-items: center;
    .back-action {
      margin-right: 16px;
      margin-top: 12px;
      cursor: pointer;
      svg {
        width: 18px;
        height: 20px;
      }
      path {
        fill: black;
      }
    }
    h2 {
      margin-bottom: 0px;
    }
    .header-items {
      margin-top: 6px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 55%;
      margin-left: 8px;
      flex: 1;
      .header-items-right {
        display: flex;
        align-items: center;
        .is-saving {
          width: 40px;
          height: 40px;
          margin-right: 24px;
        }
        .restore {
          width: 40px;
          height: 40px;
          flex: 0 0 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #8e1919;
          border-radius: 60px;
          cursor: pointer;
          margin-right: 12px;
          path:last-of-type {
            fill: white;
          }
        }
        .pause {
          width: 40px;
          height: 40px;
          flex: 0 0 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: rgba(0, 0, 0, 0.06);
          border-radius: 60px;
          cursor: pointer;
          margin-right: 12px;
        }
        button {
          width: 120px;
          height: 40px;
        }
      }
    }
    div[class^="Button"] {
      margin-top: 0;
    }
  }
  .tabs-header {
    display: flex;
    margin-top: 24px;
    border-bottom: 1px solid #e4e4e7;
    padding-bottom: 24px;
    margin-bottom: 12px;
    .tab {
      padding: 10px 16px;
      margin-right: 8px;
      border-radius: 60px;
      transition: all 150ms linear;
      font-family: "MicrogrammaStd-BoldExtended";
      color: black;
      cursor: pointer;
    }
    .active-tab {
      color: white;
      background-color: black;
    }
  }
  .messages {
    margin-top: 5%;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    b {
      text-transform: uppercase;
      font-family: "MicrogrammaStd-BoldExtended";
    }
    .community-row {
      display: flex;
      box-sizing: border-box;
      div {
        margin-right: 24px;
        width: 300px;
        &:last-of-type {
          margin-right: 0px;
          width: 290px;
        }
      }
    }
  }
  .logo-and-logout {
    @media only screen and (min-width: 768px) {
      display: none;
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
    .ant-dropdown-trigger {
      width: 40px;
      border-radius: 60px;
    }
  }
  .broadcast {
    display: flex;
    height: 67vh;
    overflow-y: auto;

    flex-direction: column;

    @media only screen and (max-width: 768px) {
      height: 60vh;

      .message-compiler-wrapper {
        width: 100%;
      }
      .your-number-container {
        position: relative;
        width: 100%;
      }
      .message-tiles {
        width: 100%;
        margin-bottom: 24px;
      }
    }
  }
  .activations {
    height: 75vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }
}
.pannel-move-left {
  margin-left: calc(-1 * calc(15vw - 100px));
  width: calc(100vw - 100px);
  .back-action {
    margin-left: 24px;
  }
  .whatsapp-builder-header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    input {
      font-family: MicrogrammaStd-BoldExtended;
      font-size: 30px;
      padding: 0px;
      background-color: transparent;
      border: 2px solid transparent;
      transition: all 150ms linear;
      border-radius: 4px;
    }
    .whatsapp-save {
      position: absolute;
      right: 24px;
    }
    .active-flow-name-input {
      border: 2px solid #7a91ff;
    }
    .editflowname-icon {
      width: 40px;
      height: 40px;
      background-color: #ececed;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 60px;
      font-size: 20px;
      cursor: pointer;
    }
  }
}
