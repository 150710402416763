.ant-dropdown-menu {
  border-radius: 12px !important;
  .tile-dropdown-item {
    width: auto;
    display: flex;
    align-items: center;
    font-family: ClashGrotesk-Regular;
    svg {
      margin-right: 8px;
    }
  }
}
.playbook-tile-wrapper {
  height: 252px;
  width: 364px;
  padding: 24px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  .more-options {
    position: absolute;
    right: 20px;
    width: 24px;
    bottom: 24px;
    a {
      svg {
        width: 24px;
        height: 24px;
        path {
          fill: #000;
        }
      }
    }
  }
  .playbook-icon {
    width: 40px;
    height: 40px;

    svg {
      path {
        fill: #000 !important;
      }
    }
  }
  h3 {
    font-family: ClashGrotesk-Medium;
    font-size: 30px;
  }
  .chip-holder {
    display: flex;
    .chip {
      margin-right: 12px;
      // background-color: white;
      width: fit-content;
      padding: 2px 10px;
      border-radius: 16px;
      display: flex;
      align-items: center;
      svg {
        margin-right: 4px;
      }
    }
  }
}
.blacked {
  .chip-holder {
    display: flex;
    .chip {
      margin-right: 12px;
      background-color: #F4F4F5 !important;
      color: #3F3F46 !important;
      width: fit-content;
      padding: 2px 10px;
      border-radius: 16px;
      display: flex;
      align-items: center;
      svg {
        margin-right: 4px;
        color: #70707B !important;
      }
    }
  }
  div {
    // color: #fff !important;
    h3,
    span {
      color: #fff;
    }
    .playbook-icon {
      svg {
        path {
          fill: #fff !important;
        }
      }
    }
    svg:not(.green-svg) {
      path {
        fill: #fff !important;
      }
    }
  }
}
.disabled-playbook {
  cursor: not-allowed;
  

}
