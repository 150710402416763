.raffle-form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 32px;
  padding-top: 32px;

  .raffle-items {
    min-height: 100vh;
    width: 340px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .header-image {
      width: 100%;
      justify-content: center;
      align-items: center;
      display: flex;
      .raffle-img {
        width: 128px;
        height: 128px;
        object-fit: cover;
        border-radius: 12px;
      }
    }
    h1 {
      color: inherit;
      font-family: MicrogrammaStd-BoldExtended;
      font-size: 24px;
      line-height: 24px;
      margin-top: 32px;
    }
    .raffle_description {
      font-family: ClashGrotesk-Regular;
    }
    hr {
      width: 100%;
    }
    .raffle-field {
      margin-top: 16px;
      label {
        font-family: ClashGrotesk-Regular;
        font-size: 14px;
      }
      .field-container {
        position: relative;
        .simple-input {
          width: 100%;
          margin-top: 8px;
          height: 44px;
          border-radius: 60px;
          border: none;
          padding: 14px;
          outline: none;
          &::placeholder {
            color: var(--placeholder-color);
          }
        }
        .dropdown-raffle {
          margin-top: 8px;
          width: 100%;
          border-radius: 60px;
          .ant-select-selection-placeholder {
            color: var(--placeholder-color);
          }
          .ant-select-arrow {
            right: 0px;
            margin-top: -8px;
          }

          .ant-select-selector {
            height: 44px;
            display: flex;
            align-items: center;
            border-radius: 60px;
            background-color: inherit;
            border: none;
          }
          .ant-select-selection-search {
            height: 44px;
            display: flex;
            align-items: center;
          }
          input {
            height: 44px;
            font-family: ClashGrotesk-Regular;
          }
        }
        svg {
          position: absolute;
          right: 16px;
          top: 50%;
          margin-top: -6px;
          width: 20px;
          height: 20px;
        }
      }
      b {
        font-family: ClashGrotesk-Regular;
        font-size: 14px;
        font-weight: normal;
        opacity: 0.8;
      }
      .question-options {
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .ant-checkbox-wrapper + .ant-checkbox-wrapper {
          margin-left: 0px;
        }
        .ant-checkbox-wrapper {
          margin-bottom: 8px;
        }
        span {
          font-family: ClashGrotesk-Regular;
        }
      }
    }
    .disabled-raffle {
      opacity: 0.8;
      cursor: not-allowed;
      .field-container {
        .simple-input {
          cursor: not-allowed;
          pointer-events: none;
        }
        .simple-input::placeholder {
          color: var(--placeholder-color);
        }
      }
    }
    div[class^="Button"] {
      margin-top: 24px;
      margin-bottom: 24px;
      width: fit-content;

      button {
        width: fit-content;
        font-family: MicrogrammaStd-BoldExtended;
      }
    }
  }
}
.success-raffle-entry {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    font-family: MicrogrammaStd-BoldExtended;
    color: inherit;
  }
  b {
    font-family: ClashGrotesk-Regular;
  }
  .powered-by {
    margin-top: 20%;
  }
}
