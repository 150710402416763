.wrapper {
  margin-bottom: 20px;
  position: relative;
  input {
    box-sizing: border-box;
    background-color: transparent;
    align-items: center;
    display: flex;
    height: 56px;
    font-family: "ClashGrotesk-Regular";
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 60px;
    width: 100%;
    padding: 0px 20px;
    font-size: 16px;
    outline: none;
    &:focus {
      border: 1px solid rgba(0, 0, 0, 1);
    }
    &::placeholder {
      color: rgba(0, 0, 0, 0.4);
    }
  }
  .leftIcon {
    position: absolute;
    top: 15px;
    left: 15px;
  }
}
.withLeftIcon {
  input {
    padding-left: 50px;
  }
}
