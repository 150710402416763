.access-page-preview .ant-modal-body {
  padding: 0px;
  pointer-events: none;
  margin-top: -50px;
  .unlock-access-wrapper {
    min-height: 80vh !important;
    height: 80vh !important;
    padding-top: 222px;
    overflow-y: auto;
  }
}
