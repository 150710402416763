.message-compiler-wrapper {
  width: 66%;
  border: 1px solid #e4e4e7;
  border-radius: 16px;
  display: flex;
  height: 104px;
  transition: all 150ms linear;
  .row {
    display: flex;
    width: 100%;
    padding: 24px;
    .col {
      display: flex;
      flex-direction: column;
      width: calc(100% - 48px);
      position: relative;
      .segment-data {
        border-radius: 60px;
        height: 40px;
        min-height: 40px;
        margin-bottom: 12px;
        margin-left: 12px;
        padding: 0 12px;
        border: 1px solid #e4e4e7;
        display: flex;
        align-items: center;
        font-family: ClashGrotesk-Regular;
        width: calc(100% - 155px);
        cursor: pointer;
        position: relative;
        .csv-import-btn {
          position: absolute;
          // top: 0px;
          left: calc(100% + 12px);
          width: max-content;
          div {
            margin-top: 0px;
            button {
              font-size: 14px;
            }
          }
        }
        > .tag {
          background-color: #f3ec00;
          display: flex;
          align-items: center;
          border-radius: 60px;
        }
        > .tag-manual {
          border: 1px solid #000000;
          margin-right: 6px;
          padding: 2px 10px;
          border-radius: 60px;
          display: flex;
          align-items: center;
          svg {
            margin-left: 4px;
            cursor: pointer;
          }
        }
        .segment-icon {
          width: 24px;
          aspect-ratio: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 60px;
        }
        .segment-icon-dropdown {
          width: 24px;
          aspect-ratio: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 60px;
          position: absolute;
          right: 12px;
        }
        b {
          margin-right: 8px;
          font-family: ClashGrotesk-Regular;
        }
      }
      .title {
        background-color: transparent;
        border: 1px solid #e4e4e7;
        border-radius: 60px;
        height: 56px;
        font-family: "ClashGrotesk-Regular";
        font-size: 16px;
        margin-left: 12px;
        padding: 0 24px;
        outline: none;
        transition: all 150ms linear;

        &::placeholder {
          color: #00000099;
        }
      }
    }
  }
}
.is-composing {
  height: auto;

  .row {
    .col {
      .title {
        border-radius: 60px;
        height: 48px;
        margin-bottom: 12px;
        padding: 0 12px;
      }
      .broadcast-message {
        position: relative;
        padding: 16px;
        border-radius: 12px;
        margin-left: 12px;
        border: 1px solid #e4e4e7;
        height: calc(100% - 56px);
        transition: all 150ms linear;
        textarea {
          padding-right: 38px;
        }
        .carousel {
          width: 100%;
          .slide {
            .carousel-slide {
              position: relative;
              .broadcast-indicator {
                width: 28px;
                aspect-ratio: 1;
                cursor: pointer;
                background-color: #000000;
                border-radius: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 4px;
                right: 4px;
                .closeIcon {
                  width: 12px !important;
                }
              }
              img {
                object-fit: cover;
              }
            }
          }
          ul {
            top: 0px;
          }
          .carousel-controls {
            cursor: pointer;
            background-color: #00000066;
            width: 48px;
            aspect-ratio: 1;
            margin: 16px;
            border-radius: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            backdrop-filter: blur(4px);

            img {
              width: 24px;
            }
          }
          .control-left {
            img {
              transform: rotateZ(180deg);
            }
          }
          .paging-item {
            height: 18px !important;
            margin-top: 0px;
            margin-bottom: 12px;
            button {
              fill: rgba(255, 255, 255, 0.6) !important;
            }
          }
          .active {
            button {
              fill: #5975ff !important;
            }
          }
          img {
            width: 100%;
            aspect-ratio: 1;
          }
        }
        .char-counter {
          width: 40px;
          aspect-ratio: 1;
          position: absolute;
          top: 8px;
          right: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          .ant-progress-text {
            color: #000 !important;
            font-family: "ClashGrotesk-Regular" !important;
            font-size: 12px !important;
          }
        }

        .broadcast {
          transition: all 150ms linear;
          background-color: transparent;
          border: 0px;
          font-family: "ClashGrotesk-Regular";
          font-size: 16px;
          outline: none;
          height: 80px;
          width: 100%;
          resize: none;
          &::placeholder {
            color: #00000099;
          }
        }
        .emoji-picker {
          position: absolute;
          margin-left: 30px;
          margin-top: -40px;
          z-index: 999999999;
        }
        @keyframes popUp {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
        .send-later-popup {
          animation: popUp linear 150ms;
          opacity: 1;
          position: absolute;
          margin-top: 2px;
          background-color: white;
          width: 80%;
          box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
            0px 4px 6px -2px rgba(16, 24, 40, 0.03);
          border-radius: 12px;
          padding: 24px;
          .ant-picker .ant-picker-input span.ant-picker-suffix,
          .ant-picker.ant-picker-range span.ant-picker-suffix {
            margin-left: 1px;
            margin-right: 10px;
            order: -1;
          }
          .ant-select-arrow {
            left: 12px;
          }
          .ant-picker,
          .ant-select:not(.ant-select-customize-input) .ant-select-selector {
            border: 1px solid #d1d1d6;
            border-radius: 8px;
          }
          .ant-picker,
          .ant-select {
            width: 33%;
            height: 44px;

            input {
              color: #70707b;
              font-size: 16px;
            }
          }
          .ant-select {
            .ant-select-selection-placeholder,
            .ant-select-selection-item {
              margin-left: 25px;
            }
            .ant-select-selector,
            span,
            input {
              display: flex;
              align-items: center;
              height: 44px;
              color: #70707b;
              font-size: 16px;
            }
            .ant-select-arrow {
              top: 0px;
              margin-top: 0px;
            }
          }
          .popup-title {
            display: flex;
            justify-content: space-between;
            img {
              cursor: pointer;
              margin-top: -8px;
              width: 24px;
              opacity: 0.6;
            }
            h4 {
              font-family: MicrogrammaStd-BoldExtended;
              font-size: 16px;
              line-height: 24px;
            }
          }
          .datepicker {
            display: flex;
            margin-top: 20px;
            font-family: ClashGrotesk-Regular;
            color: #70707b !important;
            font-size: 16px;
          }
          .popup-footer {
            display: flex;
            justify-content: flex-end;
            div {
              width: min-content;
              margin-top: 24px;
            }
          }
        }
        .tools {
          display: flex;
          align-items: center;
          margin-top: 12px;
          justify-content: space-between;
          .left-actions {
            display: flex;
            align-items: center;

            div {
              margin-top: -3px;
            }
            img {
              cursor: pointer;
              margin-right: 20px;
            }
          }
          .right-actions {
            display: flex;
            img {
              cursor: pointer;
              margin-right: 18px;
            }
            div[class^="Button"] {
              margin-top: 0px;
              height: 44px;
              button {
                height: 44px;
              }
            }
          }
        }
      }
      .total-members-info {
        font-family: ClashGrotesk-Regular;
        font-size: 14px;
        color: #51525c;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}
