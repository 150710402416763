.navBtnBack {
  background-color: #f9f9f9;
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding-bottom: 24px;
  .navBtn {
    display: flex;
    width: 50%;
    padding-left: 40px;
    @media only screen and (max-width: 767px) {
      width: 60%;
    }
    div {
      margin-top: 0px;
      &:last-of-type {
        margin-left: 8px;
        width: 200px;
      }
    }
  }
}
