.wrapper {
  align-items: center;
  display: flex;
  input {
    margin-left: 8px;
  }
  div:nth-of-type(2) {
    margin-bottom: 0px;
    width: 100%;
  }
  .CountryList {
    font-family: MicrogrammaStd-BoldExtended;
    font-size: 18px;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    .myControlClassName {
      background-color: transparent;
      border-radius: 60px;
      height: 56px;
      display: flex;
      align-items: center;
      padding-left: 15px;
    }
    .myArrowClassName {
      top: 24px;
    }
  }
  .arrowBtn {
    border: 1px solid black;
    cursor: pointer;
    right: 12px;
    top: 14px;
    position: absolute;
    background-color: #f3ec00;
    border-radius: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 30px;
  }
}
