@import "../../App.scss";

.back-position {
  background-position: var(--hotline-background-image-position) !important;
}
.textmesuccess-wrapper {
  transition: all 350ms linear;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  width: 100%;
  background-size: cover !important;
  .textmesuccess-text-part {
    margin-top: 65%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .textmesuccess-explain-h1 {
      font-family: var(--hotline-heading-1-font);
      color: var(--hotline-heading-1-color);
      text-shadow: var(--hotline-heading-1-text-shadow);
      font-size: var(--hotline-heading-1-font-size);
      line-height: var(--hotline-heading-1-font-size);
      width: var(--hotline-heading-1-width);
      text-align: var(--hotline-heading-1-align);
    }
    .textmesuccess-explain-h2 {
      font-family: var(--hotline-heading-2-font);
      font-size: var(--hotline-heading-2-font-size);
      text-shadow: var(--hotline-heading-2-text-shadow);
      width: var(--hotline-heading-2-width);
      text-align: var(--hotline-heading-2-align);
      color: var(--hotline-heading-2-color);
      margin-top: 0px;
    }
  }
  .copyright {
    margin-top: -16px;
    p {
      color: #919297;
      font-family: var(--hotline-heading-1-font);
      font-size: 14px;
      text-align: center;
      color: var(--hotline-heading-1-color);
      cursor: pointer;
    }
    position: absolute;
    bottom: 20px;
  }
  .dynamic-button {
    margin-bottom: 20px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .desktop-wrapper {
    display: flex;
    position: absolute;
  }
  .services-wrapper {
    .dynamic-button {
      margin-bottom: 120px;
    }
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .back-position {
    background-position: center center !important;
  }
  .desktop-wrapper {
    display: flex;
    position: absolute;
  }
  .textmesuccess-wrapper {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 10%;
    // filter: drop-shadow(0px 12px 4px rgba(0, 0, 0, 0.25));
    width: 55%;
    width: 375px;
    height: 730px;
    overflow: hidden;
    background-size: cover;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .textmesuccess-wrapper {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 10%;
    // filter: drop-shadow(0px 12px 4px rgba(0, 0, 0, 0.25));
    width: 375px;
    height: 730px;
    overflow: hidden;
    background-size: cover;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .textmesuccess-wrapper {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 5%;
    // filter: drop-shadow(0px 12px 4px rgba(0, 0, 0, 0.25));
    width: 375px;
    height: 730px;
    overflow: hidden;
  }
}
.ant-modal-header {
  height: 20px;

  .ant-modal-title {
    font-size: 12px !important;
    line-height: 6px;
  }
}
.share-modal-title {
  margin-bottom: 20px;
  padding-left: 12px;
}
.react-responsive-modal-modal {
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  width: 300px;
  border-radius: 8px;
}
.share-component {
  cursor: pointer;
  display: flex;
  border-top: 1px solid rgb(124, 124, 124);
  width: 100%;
  padding: 12px 15px;
  transition: background 150ms linear;
  img {
    width: 20px;
    aspect-ratio: 1;
    margin-right: 12px;
  }
  &:hover {
    background-color: rgb(212, 212, 212);
  }
}
.tab-close {
  position: absolute;
  right: 24px;
  top: 24px;
  cursor: pointer;
}
