.ant-notification {
  z-index: 999999 !important;
  .copy-to-clip-notif,
  .wrong-media {
    padding: 0px 12px !important;
    height: 64px !important;
    .ant-notification-notice-description {
      margin-left: 0px;
    }
    .ant-notification-notice-with-icon {
      display: flex;
      align-items: center;
      height: 64px;
    }
    .ant-notification-notice-message {
      color: white;
      font-family: ClashGrotesk-Regular;
      font-size: 14px;
      margin-bottom: 0px;
    }
    .copy-notif-icon {
      background-color: #494cfd66;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 60px;
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}
#form-file-upload-nft {
  position: relative;
  .absolute-container {
    cursor: pointer;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
  }
}

#input-file-upload {
  display: none;
}
.icon-img {
  width: 60px;
  aspect-ratio: 1;
  object-fit: cover;
}
.radius {
  border-radius: 60px;
  width: 100% !important;
}
div[class^="react-switch-bg"] {
  border: 1px solid black !important;
  width: 40px !important;
  height: 24px !important;
}
div[class*="checked"] {
  div[class^="react-switch-handle"] {
    left: -8px;
  }
}
div[class^="react-switch-handle"] {
  width: 16px !important;
  height: 16px !important;
  top: 4px !important;
  left: 2px;
}

.switchComponent {
  display: flex;
  margin-top: 24px;
  margin-bottom: 12px;
  b {
    font-family: "ClashGrotesk-Regular";
    text-transform: none;
    margin-left: 8px;
    font-size: 16px;
    font-weight: 500;
    font-weight: normal;
    &:last-of-type {
      font-size: 14px;
      color: #51525c;
    }
  }
  // box-shadow: ;
  div[class^="react-switch-bg"] {
    border: none !important ;
  }
}
.enabled-switch {
  div[class^="react-switch-handle"] {
  }
  div[class^="react-switch-bg"] {
    border: none !important;
  }
}
#label-file-upload {
  cursor: pointer;

  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-radius: 1rem;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.1);
  background-color: #f8fafc;
  margin-top: 0px;
  .left-part {
    display: flex;
    align-items: center;
    .upl-type-label {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-family: "ClashGrotesk-Medium";
      font-weight: 500;
      color: #3f3f46;
      font-size: 14px;
      @media only screen and (max-width: 767px) {
        width: 100px;
      }
      div {
        font-size: 12px;
        font-weight: normal;
        color: #51525c;
      }
    }
  }
  .inner {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
    .icon-bck {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 60px;
      background-color: #eaeaea;
      width: 56px;
      aspect-ratio: 1;
      margin-right: 12px;
      img {
        width: 21px;
        aspect-ratio: 1;
      }
    }
  }
  .inner-nft {
    display: flex;

    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: 0px 20px;
    .picture-part {
      width: 35%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 20px;
      position: relative;
      img,
      video {
        width: 180px;
        aspect-ratio: 1;
        object-fit: cover;
      }
      .audio-play-btn {
        position: absolute;
        bottom: 50px;
        left: 150px;
        background: rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(3.375px);
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 60px;
        cursor: pointer;
        img {
          width: 25px;
        }
      }
    }
    .loader-part {
      width: 65%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      height: 80%;
      div[class^="Button_"] {
        margin-top: 12px;
        width: 190px;
        button {
          border: none;
          height: 40px;
        }
      }
      .progress-and-number {
        display: flex;
        align-items: center;
        font-family: ClashGrotesk-Regular;
        color: #3f3f46;
        .progress {
          width: 280px;
          display: flex;
          margin-right: 12px;
        }
      }
    }
    h3 {
      font-family: MicrogrammaStd-BoldExtended;
      font-size: 18px;
      margin: 8px 0px;
    }
    .upl-type-label {
      font-family: ClashGrotesk-Regular;
      font-size: 14px;
      width: 70%;
      text-align: center;
    }
    .icon-bck {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 60px;
      background-color: #eaeaea;
      width: 56px;
      aspect-ratio: 1;
      margin-right: 12px;
      img {
        width: 25px;
        aspect-ratio: 1;
      }
    }
    .left-part {
      display: flex;
      flex-direction: column;
    }
  }
}
#label-file-upload {
  div[class^="Button"]:hover {
    button {
      background-color: #f3ec00 !important;
    }
  }
  &:not(.no-hover):hover .inner-nft {
    .icon-bck {
      background-color: #f3ec00 !important;
    }
    .loader-part {
      > div:first-of-type {
        button {
          background-color: #f3ec00 !important;
        }
      }
    }
  }
}
.upload-error {
  background-color: #fffbfa !important;
  border: 1px solid #912018 !important;
  div,
  span {
    color: #b42318 !important;
  }
  .icon-bck {
    background-color: #fee4e2 !important;
  }
}

#label-file-upload.drag-active {
  background-color: #ffffff;
}

.upload-button {
  font-size: 1rem;
  border: none;
  font-family: "Oswald", sans-serif;
  background-color: transparent;
  display: flex;
  align-items: center;
  margin-top: 0px;
  span {
    width: 140px;
  }
  div {
    margin-top: 0px;
    width: fit-content;
    @media only screen and (max-width: 767px) {
      margin-left: -15px;
      transform: scale(0.6);
    }

    button {
      text-transform: uppercase;
      font-size: 16px;
      width: fit-content;
      height: 40px;
      width: 150px;
      border: none;
    }
  }
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
.tags {
  .rti--container {
    background-color: transparent;
  }
  span {
    border: 1px solid rgba($color: #000000, $alpha: 0.1) !important;
    border-radius: 60px;
    padding: 0px 5px;
  }
  input {
    border-radius: 60px !important;
    height: 40px !important;
  }
}
.disabled-fields {
  opacity: 0.3;
}
.nft-upload {
  form {
    height: 280px !important;
  }
  .upload-button {
    display: none !important;
  }
  div[class^="RenderFields"] {
    label {
      margin-top: 0px;
    }
    .nft-version-label{
      color: #7A91FF
    }
  }
  .nft-descr-span {
    padding-left: 40px;
    color: #51525c;
    font-family: ClashGrotesk-Regular;
  }
}
