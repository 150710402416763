.import-csv-modal {
  width: 640px !important;
  .ant-modal-content {
    .modal-body {
      .error-csv-import {
        background-color: #fef3f2;
        border: 1px solid #fda29b;
        margin-bottom: 24px;
        border-radius: 8px;
        padding: 16px;
        display: flex;
        position: relative;
        svg {
          width: 17px;
          height: 17px;
          margin-top: 3px;
          path {
            stroke: #d92d20;
          }
          &:last-of-type {
            position: absolute;
            right: 16px;
            cursor: pointer;
          }
        }
        div {
          margin-left: 14px;
        }
        b {
          font-size: 14px;
          color: #912018;
          font-family: ClashGrotesk-Medium;
        }
        p {
          font-size: 14px;
          font-family: ClashGrotesk-Regular;
          margin-bottom: 0px;
          color: #912018;
        }
      }
    }
    .ant-modal-close {
      height: 72px;
    }
    .ant-modal-close-x {
      color: black;
    }
    .ant-modal-header {
      height: 72px;
      display: flex;
      align-items: center;
      .ant-modal-title {
        font-family: MicrogrammaStd-BoldExtended;
        font-size: 20px !important;
      }
    }
    .ant-modal-footer {
      height: 72px;
    }
    .import-csv-footer {
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
      justify-content: space-between;
      a {
        color: #494cfd;
      }
      .import-csv-modal-buttons {
        display: flex;

        > div {
          margin-top: 0px;
          &:first-of-type {
            margin-right: 12px;
          }
          button {
            height: 40px !important;
            font-size: 14px;
          }
        }
      }
    }
    .import-csv-footer-name {
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
      justify-content: flex-end;
      a {
        color: #494cfd;
      }
      .import-csv-modal-buttons {
        display: flex;

        > div {
          margin-top: 0px;
          &:first-of-type {
            margin-right: 12px;
          }
          button {
            height: 40px !important;
            font-size: 14px;
          }
        }
      }
    }
    .csv-import-form {
      height: 200px !important;
    }
    .smaller {
      height: auto !important;
    }
    .inner-csv-import {
      transition: all 150ms ease-in;
      display: flex;
      flex-direction: column;
      align-items: center;
      b {
        font-family: MicrogrammaStd-BoldExtended;
        font-size: 16px;
      }
      p {
        font-size: 14px;
        margin-top: 8px;
        margin-bottom: 16px;
      }
      .upload-button {
        button {
          height: 40px !important;
          width: 155px;
          font-size: 14px;
        }
      }
    }
    .row-direction {
      width: calc(100% - 48px);
      flex-direction: row;
      justify-content: space-between;
      padding: 34px 0;
      .file-wrapper {
        display: flex;
        align-items: center;
        .file-info {
          margin-left: 12px;
          display: flex;
          align-items: flex-start;
          flex-direction: column;

          b {
            font-size: 16px;
            font-family: ClashGrotesk-Medium;
          }
          p {
            margin-top: 0px;
            margin-bottom: 0px;
            font-family: ClashGrotesk-Regular;
          }
        }
        .file-icon {
          background-color: #f4f4f5;
          width: 48px;
          aspect-ratio: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 60px;
          svg {
            width: 24px;
            height: 24px;
          }
        }
      }
      .upload-button {
        button {
          width: 175px;
        }
      }
    }
  }
}
.import-csv-modal-name {
  width: 400px !important;
  .logo > img {
    width: 65px;
    height: 65px;
  }
}
.segment-name-modal {
  h1 {
    font-family: MicrogrammaStd-BoldExtended;
    margin-top: 20px;
    margin-bottom: 0px;
    font-size: 20px;
  }
  p {
    font-family: ClashGrotesk-Regular;
    font-size: 14px;
  }
  input {
    height: 44px;
    border-radius: 60px !important;
    width: 100%;
    font-family: ClashGrotesk-Regular;
    padding: 14px;
    font-size: 16px;
    border: 1px solid #d1d1d6;
  }
}
