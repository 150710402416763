.automatically-close-wrapper {
  border: 1px solid #d1d1d6;
  border-radius: 24px;
  margin-top: 24px;
  padding: 24px;
  .descriptive-part {
    justify-content: space-between;
    align-items: center;
    display: flex;
    .text-part {
      h3 {
        font-family: MicrogrammaStd-BoldExtended;
        font-size: 20px;
        margin-bottom: 0px;
      }
      .subtext {
        font-family: ClashGrotesk-Regular;
      }
    }
    .switch-container {
      .switchComponent {
        margin: 0px;
        // box-shadow: ;
        div[class^="react-switch-bg"] {
          border: 0px;
        }
        div[class^="react-switch-handle"] {
          transform: translateX(1px);
        }
      }
      .enabled-switch {
        div[class^="react-switch-handle"] {
          transform: translateX(19px) !important;
        }
        div[class^="react-switch-bg"] {
          border: none !important;
        }
      }
    }
  }
  .date-time-part {
    margin-top: 16px;
    .ant-picker {
      background: transparent;
      width: 300px;
      height: 40px;
      border-radius: 60px;
    }
  }
}
