.powered-by {
  margin-top: 20px;
  padding-bottom: 20px;
  align-items: center;
  display: flex;
  font-family: ClashGrotesk-Regular;
  .powered-by-b {
    margin-bottom: 0px !important;
  }
  .powered-by-svg {
    margin-bottom: 0px !important;
    margin-left: 8px;
    fill: currentColor;

    path {
      fill: currentColor;
    }
  }
}
