.mute-unmute-btn {
  background-color: rgba($color: #000000, $alpha: 0.5);
  aspect-ratio: 1;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 60px;
}
