.image-selector {
  width: 100px;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #ededed;
  border-radius: 20px;
  transition: all 150ms linear;
  margin-right: 20px;
  cursor: pointer;
  label {
    cursor: pointer;
  }
  i {
    font-size: 30px;
  }

  &:hover {
    background-color: #c1c1c1;
  }
}
