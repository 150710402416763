.raffle-submitted {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .raffle-submitted-content {
      color: inherit;
      width: 340px;
      height: fit-content;
      display: flex;
      flex-direction: column;
      align-items: center;
      .raffle-submitted-img {
        width: 100px;
        aspect-ratio: 1;
        object-fit: cover;
        border-radius: 60px;
      }
      h1 {
        color: inherit;
        font-size: 24px;
        font-family: MicrogrammaStd-BoldExtended;
        margin-top: 40px;
        margin-bottom: 20px;
      }
      h3 {
        color: #fff;
        font-family: ClashGrotesk-Regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; 
      }
     
      div[class^="Button"] {
        margin-top: 48px;
        margin-bottom: 110px;
      }
    }
  }