.lightbox-wrapper {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1280;
  display: flex;
  align-items: center;
  justify-content: center;
  .lightbox-close {
    position: absolute;
    top: 12px;
    right: -4px;
    cursor: pointer;
    background-color: #00000066;
    width: 48px;
    aspect-ratio: 1;
    margin: 16px;
    border-radius: 60px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 12px;
    }
  }
  .carousel {
    width: 100%;
    .slide {
      .carousel-slide {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          object-fit: contain;
        }
      }
    }
    ul {
      top: 0px;
    }
    .carousel-controls {
      cursor: pointer;
      background-color: #00000066;
      width: 48px;
      aspect-ratio: 1;
      margin: 16px;
      border-radius: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      backdrop-filter: blur(4px);

      img {
        width: 24px;
      }
    }
    .control-left {
      img {
        transform: rotateZ(180deg);
      }
    }
    .paging-item {
      height: 18px !important;
      margin-top: 0px;
      margin-bottom: 12px;
      button {
        fill: rgba(255, 255, 255, 0.6) !important;
      }
    }
    .active {
      button {
        fill: #5975ff !important;
      }
    }
    img {
      width: 100%;
      max-width: 730px;
      aspect-ratio: 1;
    }
  }
}
