.landing-background {
  width: 100%;
  height: 100vh;
  background-color: #141414;
  transition: all 350ms linear;
  .overlay-loading {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 999999;
  }
  .landing-wrapper {
    background-repeat: no-repeat;
    background-size: cover !important;
    display: flex;
    flex-direction: column;
    height: 87%;
    border-bottom-right-radius: 40px;
    border-bottom-left-radius: 40px;
    .text-wrapper {
      margin-top: 400px;
      color: white;
      padding: 0px 25px;
      h1 {
        font-family: Sequel100Wide65;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 27px;
        text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
      }
      h2 {
        width: 95%;
        font-family: Sequel100Wide45;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        color: #ffffff;
        text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
      }
    }
    .error-message {
      line-height: 12px;
      font-family: Sequel100Wide45;
      color: #d80027;
      font-size: 12px;
      margin-left: 30px;
    }
  }
  .copyright {
    margin-top: -8px;
    p {
      color: #919297;
      font-family: Sequel100Wide45;
      font-size: 10px;
      text-align: center;
      a {
        color: #aefc4e;
      }
    }
  }
  .dynamic-button {
    margin-left: 25px;
  }
  .input-container {
    margin-top: 10px;
    margin-bottom: 8px;
    margin-left: 25px;
  }
}
.desktop-wrapper {
  display: none;
  z-index: -1;
  position: absolute;
  min-height: 100vh;
  width: 100%;
  background-size: cover !important;
  // filter: blur(18px);
  // -webkit-filter: blur(18px);
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .desktop-wrapper {
    display: flex;
    position: absolute;
  }
  .landing-background {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 10%;
    filter: drop-shadow(0px 12px 4px rgba(0, 0, 0, 0.25));
    width: 375px;
    height: 730px;
    border: 12px solid white;
    border-radius: 48px;
    overflow: hidden;
    background-color: black;
    // padding: 10px;
    .landing-wrapper {
      padding-bottom: 25px;
    }
    .copyright {
      width: 100%;
      position: absolute;
      bottom: 8px;
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .landing-background {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 10%;
    filter: drop-shadow(0px 12px 4px rgba(0, 0, 0, 0.25));
    width: 375px;
    border: 12px solid white;
    border-radius: 48px;
    overflow: hidden;
    background-color: black;
    height: 730px;
    // padding: 10px;
    .landing-wrapper {
      padding-bottom: 25px;
    }
    .copyright {
      width: 100%;
      position: absolute;
      bottom: 8px;
    }
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .landing-background {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 5%;
    filter: drop-shadow(0px 12px 4px rgba(0, 0, 0, 0.25));
    width: 375px;
    border: 12px solid white;
    border-radius: 48px;
    overflow: hidden;
    background-color: black;
    height: 730px;
    // padding: 10px;
    .landing-wrapper {
      padding-bottom: 5px;
    }
    .copyright {
      width: 100%;
      position: absolute;
      bottom: 8px;
    }
  }
}
