.switch-container {
    margin-bottom: 6px;
    .switchComponent {
      margin: 0px;
      .switch-label{
        margin: 0 6px;
      }
      .switch-label-opacity {
        opacity: 0.5;
        margin: 0 6px;

      }
      .ant-switch.react-switch.ant-switch-checked {
        background-color: #f3ec00;
        .ant-switch-handle:before {
          background-color: #000000d9;
        }
      }
      // box-shadow: ;
      div[class^="react-switch-bg"] {
        border: 0px;
      }
      div[class^="react-switch-handle"] {
        transform: translateX(1px);
      }
    }
    .enabled-switch {
      div[class^="react-switch-handle"] {
        transform: translateX(19px) !important;
      }
      div[class^="react-switch-bg"] {
        border: none !important;
      }
    }
  }