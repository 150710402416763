.artists-links-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  width: 100%;
  position: relative;
  .links-header {
    color: var(--heading-1-color);
    font-family: var(--heading-1-font);
  }
  .links {
    margin-top: 16px;
    overflow-x: auto;
    display: flex;
    padding-left: 10px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 110%;
  }
  .center {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .artist-link-item {
    min-width: 57px;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
    b {
      margin-top: 8px;
      font-size: 9px;
      color: var(--heading-1-color);
      font-family: var(--heading-2-font);
    }
    .artist-link-item-logo {
      cursor: pointer;
      aspect-ratio: 1;
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 60px;
    }
  }
}
