.ios-like-loader {
  background-color: #1e1e1e33;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  left: 0px;

  .blured-container {
    background-color: #1e1e1e33;
    width: 180px;
    height: 180px;
    border-radius: 16px;
    background: rgba(30, 30, 30, 0.2);
    backdrop-filter: blur(24px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 30px 6px;
    position: relative;

    .loading-animationTick {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
      margin-top: 20px;
    }
    .nft-container {
      display: flex;
      flex-direction: row;
      overflow-x: auto;
      margin: 15px 0px;
      .nft-card {
        width: 136px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 8px;
        border-radius: 12px;
        img,
        video {
          width: 120px;
          height: 120px;
          object-fit: cover;
          border-radius: 12px;
        }
        video {
          width: 125px;
          height: 125px;
        }
        b {
          margin-top: 12px;
          font-family: ClashGrotesk-Medium;
        }
      }
    }
    .scrollable {
      padding: 0px 45px;
      max-width: 100vw;
      .nft-card {
        margin: 0 4px;
      }
    }
    .loading-animation {
      padding-top: 30px;
      .error-state {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .loading-body {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 20px;
        /****** center box
        width: 300px;
        height: 300px;
        border: solid 1px #aaa;
        ******/
      }
      .loader-square-10 {
        position: relative;
        width: 55px;
        height: 55px;
      }
      .loader-square-10::before {
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        width: 15px;
        height: 15px;
        content: "";
        box-shadow: 20px 0 0 transparent, 40px 0 0 transparent,
          0 -20px 0 transparent, 20px -20px 0 transparent,
          40px -20px 0 transparent, 0 -40px transparent, 20px -40px transparent,
          40px -40px transparent;
        animation: anm-sq-10-loader 3s linear forwards infinite;
      }
      .loader-square-10::after {
        position: absolute;
        bottom: 10px;
        left: 0;
        display: block;
        width: 15px;
        height: 15px;
        background-color: #fff;
        opacity: 0;
        content: "";
        animation: anm-sq-10-base 3s linear forwards infinite;
      }
      @keyframes anm-sq-10-base {
        0% {
          bottom: 10px;
          opacity: 0;
        }
        5%,
        50% {
          bottom: 0;
          opacity: 1;
        }
        55%,
        100% {
          bottom: -10px;
          opacity: 0;
        }
      }
      @keyframes anm-sq-10-loader {
        0% {
          box-shadow: 20px -10px transparent, 40px 0 transparent,
            0 -20px transparent, 20px -20px transparent, 40px -20px transparent,
            0 -40px transparent, 20px -40px transparent, 40px -40px transparent;
        }
        5% {
          box-shadow: 20px -10px transparent, 40px 0 transparent,
            0 -20px transparent, 20px -20px transparent, 40px -20px transparent,
            0 -40px transparent, 20px -40px transparent, 40px -40px transparent;
        }
        10% {
          box-shadow: 20px 0 white, 40px -10px transparent, 0 -20px transparent,
            20px -20px transparent, 40px -20px transparent, 0 -40px transparent,
            20px -40px transparent, 40px -40px transparent;
        }
        15% {
          box-shadow: 20px 0 white, 40px 0 white, 0 -30px transparent,
            20px -20px transparent, 40px -20px transparent, 0 -40px transparent,
            20px -40px transparent, 40px -40px transparent;
        }
        20% {
          box-shadow: 20px 0 white, 40px 0 white, 0 -20px white,
            20px -30px transparent, 40px -20px transparent, 0 -40px transparent,
            20px -40px transparent, 40px -40px transparent;
        }
        25% {
          box-shadow: 20px 0 white, 40px 0 white, 0 -20px white,
            20px -20px white, 40px -30px transparent, 0 -40px transparent,
            20px -40px transparent, 40px -40px transparent;
        }
        30% {
          box-shadow: 20px 0 white, 40px 0 white, 0 -20px white,
            20px -20px white, 40px -20px white, 0 -50px transparent,
            20px -40px transparent, 40px -40px transparent;
        }
        35% {
          box-shadow: 20px 0 white, 40px 0 white, 0 -20px white,
            20px -20px white, 40px -20px white, 0 -40px white,
            20px -50px transparent, 40px -40px transparent;
        }
        40% {
          box-shadow: 20px 0 white, 40px 0 white, 0 -20px white,
            20px -20px white, 40px -20px white, 0 -40px white, 20px -40px white,
            40px -50px transparent;
        }
        45%,
        55% {
          box-shadow: 20px 0 white, 40px 0 white, 0 -20px white,
            20px -20px white, 40px -20px white, 0 -40px white, 20px -40px white,
            40px -40px #fff;
        }
        60% {
          box-shadow: 20px 10px transparent, 40px 0 white, 0 -20px white,
            20px -20px white, 40px -20px white, 0 -40px white, 20px -40px white,
            40px -40px #fff;
        }
        65% {
          box-shadow: 20px 10px transparent, 40px 10px transparent,
            0 -20px white, 20px -20px white, 40px -20px white, 0 -40px white,
            20px -40px white, 40px -40px #fff;
        }
        70% {
          box-shadow: 20px 10px transparent, 40px 10px transparent,
            0 -10px transparent, 20px -20px white, 40px -20px white,
            0 -40px white, 20px -40px white, 40px -40px #fff;
        }
        75% {
          box-shadow: 20px 10px transparent, 40px 10px transparent,
            0 -10px transparent, 20px -10px transparent, 40px -20px white,
            0 -40px white, 20px -40px white, 40px -40px #fff;
        }
        80% {
          box-shadow: 20px 10px transparent, 40px 10px transparent,
            0 -10px transparent, 20px -10px transparent, 40px -10px transparent,
            0 -40px white, 20px -40px white, 40px -40px #fff;
        }
        85% {
          box-shadow: 20px 10px transparent, 40px 10px transparent,
            0 -10px transparent, 20px -10px transparent, 40px -10px transparent,
            0 -30px transparent, 20px -40px white, 40px -40px #fff;
        }
        90% {
          box-shadow: 20px 10px transparent, 40px 10px transparent,
            0 -10px transparent, 20px -10px transparent, 40px -10px transparent,
            0 -30px transparent, 20px -30px transparent, 40px -40px #fff;
        }
        95%,
        100% {
          box-shadow: 20px 10px transparent, 40px 10px transparent,
            0 -10px transparent, 20px -10px transparent, 40px -10px transparent,
            0 -30px transparent, 20px -30px transparent, 40px -30px transparent;
        }
      }
    }
    b {
      text-align: center;
      font-family: ClashGrotesk-Regular;
      text-align: center;
      color: white;
    }
  }

  .isNft {
    width: 271px;
    height: 280px;
    border-radius: 16px;
    background: rgba(30, 30, 30, 0.2);
    backdrop-filter: blur(24px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 30px 6px;
    .loading-animationTick {
      margin-top: 60px;
    }
  }
}
