.ant-notification {
  z-index: 999999 !important;
  .copy-to-clip-notif {
    padding: 0px 12px !important;
    height: 64px !important;
    .ant-notification-notice-with-icon {
      display: flex;
      align-items: center;
      height: 64px;
    }
    .ant-notification-notice-message {
      color: white;
      font-family: ClashGrotesk-Regular;
      font-size: 14px;
    }
    .copy-notif-icon {
      background-color: #494cfd66;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 60px;
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}
.playbook-wrapper {
  width: 83vw;
  @media only screen and (max-width: 768px) {
    padding: 24px;
    width: 100%;
  }
  .sub-header {
    display: flex;
    align-items: center;
    .back-action {
      margin-right: 16px;
      margin-top: 12px;
      cursor: pointer;
      svg {
        width: 18px;
        height: 20px;
      }
      path {
        fill: black;
      }
    }
    h2 {
      margin-bottom: 0px;
    }
    .header-items {
      margin-top: 6px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 55%;
      .header-items-right {
        display: flex;
        align-items: center;
        .is-saving {
          width: 40px;
          height: 40px;
          margin-right: 24px;
        }
        .restore {
          width: 40px;
          height: 40px;
          flex: 0 0 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #8e1919;
          border-radius: 60px;
          cursor: pointer;
          margin-right: 12px;
          path:last-of-type {
            fill: white;
          }
        }
        .pause {
          width: 40px;
          height: 40px;
          flex: 0 0 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: rgba(0, 0, 0, 0.06);
          border-radius: 60px;
          cursor: pointer;
          margin-right: 12px;
        }
        button {
          width: 120px;
          height: 40px;
        }
      }
    }
    div[class^="Button"] {
      margin-top: 0;
    }
  }
  .tabs-header {
    display: flex;
    margin-top: 24px;
    padding-bottom: 12px;
    margin-bottom: 12px;
    .tab {
      height: 36px;
      font-size: 14px;
      padding: 0px 16px;
      margin-right: 8px;
      border-radius: 60px;
      transition: all 150ms linear;
      font-family: "ClashGrotesk-Medium";
      color: black;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .active-tab {
      color: white;
      background-color: black;
    }
  }
  .explore-wrapper {
    display: flex;
    flex-wrap: wrap;
    div.playbook-tile-wrapper {
      margin-right: 16px;
      margin-bottom: 24px;
    }
  }
}
.playbook-success {
  width: 400px !important;
  margin-top: 10% !important;
  .ant-modal-content {
    border-radius: 20px !important;
    overflow: hidden;
  }
  img {
    width: 64px;
  }
  h3 {
    font-family: MicrogrammaStd-BoldExtended;
    margin-top: 20px;
    font-size: 20px;
  }
  b {
    font-size: 16px;
    color: #51525c;
    font-family: ClashGrotesk-Regular;
    font-weight: normal;
  }
  .link-and-copy {
    margin-top: 20px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    b {
      height: 48px;
      border: 1px solid #d1d1d6;
      padding: 12px 14px;
      border-radius: 60px;
      font-size: 16px;
    }
    .copy-to-clipboard {
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ececed;
      border-radius: 60px;
      cursor: pointer;

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
}
