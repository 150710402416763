.status-tags-container {
  margin: 0px;
  padding: 0px;
  .status-tags {
    font-family: ClashGrotesk-Regular;
    padding: 4px 12px;
    border-radius: 60px;
    font-size: 14px;
  }
  .active,
  .ongoing {
    background-color: #ecfdf3;
    color: #027a48;
  }
  .draft {
    background-color: #eff8ff;
    color: #175cd3;
  }
  .paused,
  .stopped {
    background-color: #eff8ff;
    color: #175cd3;
  }
  .archived {
    background-color: #fef3f2;
    color: #b42318;
  }
}
