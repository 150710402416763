.broadcast-images {
  display: flex;
  .broadcast-image {
    margin-right: 8px;
    width: 96px;
    aspect-ratio: 1;
    padding-bottom: 12px;
    position: relative;
    img {
      width: 100%;
      aspect-ratio: 1;
      object-fit: cover;
      border-radius: 16px;
    }
    .broadcast-indicator {
      width: 28px;
      aspect-ratio: 1;
      cursor: pointer;
      background-color: #000000;
      border-radius: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 4px;
      right: 4px;
      img {
        width: 12px;
      }
    }
  }
}
