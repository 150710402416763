.wrapper {
  background-color: #f9f9f9;
  height: 68px;
  display: flex;
  align-items: center;
  position: sticky;
  width: 100%;
  bottom: 0px;
  flex-wrap: wrap;
  justify-content: space-between;
  z-index: 9;
  .left {
    display: flex;
    width: 35%;
    font-family: "ClashGrotesk-Medium";
    font-weight: 500;
    margin-left: 0%;
    @media only screen and (min-width: 768px) {
      margin-left: 15%;
    }
  }
  .right {
    width: 35%;
    display: flex;
    justify-content: flex-end;
    margin-right: 0%;
    @media only screen and (min-width: 768px) {
      margin-right: 15%;
    }
    a {
      font-size: 14px;
      font-family: "ClashGrotesk-Medium";
      font-weight: 500;
      text-decoration: none;
      color: black;
      &:first-of-type {
        margin-right: 20px;
      }
    }
  }
  @media only screen and (max-width: 600px) {
    .left,
    .right {
      width: 100%;
      justify-content: center;
    }
  }
}
.noBackground {
  background-color: #f9f9f9;
}
