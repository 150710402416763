.wrapper {
  display: flex;
  align-items: center;
  padding-left: 48px;
  padding-top: 28px;
  padding-bottom: 25px;
  img {
    z-index: 9;
  }
  b {
    font-family: "MicrogrammaStd-BoldExtended";
    font-size: 16px;
    margin-left: calc(17% - 120px);
    width: 80%;
    display: flex;
    align-items: center;
    img {
      margin-left: 20px;
      cursor: pointer;
    }
    @media only screen and (max-width: 767px) {
      padding-left: 8%;
      font-size: 14px;

      margin-left: calc(30% - 120px);
      width: 50%;
    }
  }
  div {
    margin-top: 0px;
    button {
      float: right;
      width: 200px;
      margin-top: 0px;
      margin-right: 24px;
    }
  }
}
