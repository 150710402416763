.message-tiles {
  width: 66%;
  .tile {
    cursor: pointer;
    padding: 24px;
    margin-top: 16px;
    border-radius: 16px;
    border: 1px solid #e4e4e7;
    .tile-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      .avatar-and-stats {
        display: flex;
        max-width: 80%;
        > span {
          aspect-ratio: 1;
        }
        .avatar-stats {
          margin-left: 12px;
          max-width: 80%;

          span {
            font-family: MicrogrammaStd-BoldExtended;
            font-size: 18px;
          }
          div {
            font-family: ClashGrotesk-Regular;
            color: #18181b;
          }
        }
      }
      .tile-replies {
        background-color: #5975ff;
        border-radius: 60px;
        font-family: ClashGrotesk-Regular;
        padding: 2px 6px;
        color: white;
        cursor: pointer;
      }
    }
    .tile-body-row {
      display: flex;
    }
    .tile-body {
      margin-top: 12px;
      margin-left: 62px;
      font-family: ClashGrotesk-Regular;
      font-size: 18px;
      padding-bottom: 12px;
    }
    .carousel {
      margin-left: 62px;
      width: 80%;
      aspect-ratio: 1;
      margin-top: 10px;
      margin-bottom: 20px;
      .carousel-controls {
        cursor: pointer;
        background-color: #00000066;
        width: 48px;
        aspect-ratio: 1;
        margin: 16px;
        border-radius: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        backdrop-filter: blur(4px);

        img {
          width: 24px;
        }
      }
      .paging-item {
        height: 18px !important;
        margin-top: 0px;
        margin-bottom: 12px;
        button {
          fill: rgba(255, 255, 255, 0.6) !important;
        }
      }
      .active {
        button {
          fill: #5975ff !important;
        }
      }
      .control-left {
        img {
          transform: rotateZ(180deg);
        }
      }
      img {
        aspect-ratio: 1;
        width: 100%;
        object-fit: cover;
      }
      .raffle-preview {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        aspect-ratio: 1;
        .asset-container {
          position: relative;
          .raffle-img {
            width: 200px;
          }
          .lock-icon-container {
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: calc(-1 * (88px / 2));
            margin-left: calc(-1 * (88px / 2));
            background: rgba(0, 0, 0, 0.2);
            backdrop-filter: blur(7.499989986419678px);
            width: 88px;
            height: 88px;
            border-radius: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
              width: 48px;
              height: 48px;
            }
          }
        }
        b {
          margin-top: 12px;
          font-family: MicrogrammaStd-BoldExtended;
          font-size: 32px;
        }
      }
    }
    .tile-footer {
      display: flex;
      margin-left: 62px;
      margin-top: 16px;
      align-items: center;
      font-family: ClashGrotesk-Medium;
      font-size: 16px;
      img {
        margin-right: 8px;
      }
      .tile-stat {
        margin-right: 28px;
        font-family: ClashGrotesk-Medium;
        font-size: 16px;
        display: flex;
        align-items: center;
        img {
          margin-right: 8px;
        }
      }
    }
  }
}

.ant-tooltip-inner {
  padding: 8px 12px !important;
  border-radius: 8px !important;
}
