@import "../../App.scss";

.poll-progressbar-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 30px;
  z-index: 3;
  .pause-button {
    display: none;
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 0px;
    width: 24px;
    height: 24px;
  }
  .poll-progressbar-cell-clickable {
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
    .poll-progressbar-cell {
      width: 100%;
      background-color: #ffffff50;
      height: 2px;
      transition: background-color 0.2s ease-in-out;
    }
    .current {
      .progress-cell {
        width: 100%;
        height: 100%;
        background-color: #5975ff;
        left: 0;
        position: relative;
        display: block;
        top: 0px;
        animation: fillProgress 7s linear;
      }
    }

    .previous {
      background-color: white;
      transition: background-color 350ms linear;
    }
  }
  .animating {
    .poll-progressbar-cell {
      animation: indicate 0.3s ease-in-out infinite;
    }
  }
  .current {
    cursor: default;
  }
}

@keyframes indicate {
  0% {
    background-color: #ffffff50;
  }
  50% {
    background-color: #fff;
  }
  100% {
    background-color: #ffffff50;
  }
}

@keyframes fillProgress {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

@media screen and (min-width: 600px) {
  .poll-progressbar-wrapper {
    .pause-button {
      display: inherit;
    }
  }
}
