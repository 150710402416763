.unlock-access-wrapper {
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  display: flex;
  .content-access {
    min-height: 480px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .artist-picture {
      width: 200px;
      height: 60px;
      margin-bottom: 20px;
      object-fit: contain;
    }
    .asset-to-access {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      .lock-icon-container {
        position: absolute;
        top: 59%;
        left: 50%;
        margin-top: calc(-1 * (88px / 2));
        margin-left: calc(-1 * (88px / 2));
        background: rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(7.499989986419678px);
        width: 88px;
        height: 88px;
        border-radius: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          width: 48px;
          height: 48px;
        }
      }
      img {
        width: 200px;
        aspect-ratio: 1;
        border-radius: 10px;
        object-fit: cover;
      }
    }
    h2 {
      margin-top: 24px;
      font-size: 24px;
      color: inherit;
      font-family: MicrogrammaStd-BoldExtended;
    }
    .asset-sub-text {
      width: 80%;
      max-height: 151px;
      overflow-y: auto;
      font-family: ClashGrotesk-Regular;
      font-size: 16px;
      text-align: center;
    }
    @media only screen and (min-width: 768px) {
      width: 70%;
    }
    @media only screen and (min-width: 1024px) {
      width: 47%;
    }
    .nft-container {
      display: flex;
      flex-direction: row;
      overflow-x: auto;
      margin: 24px 0px;
      .nft-card {
        width: 136px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 8px;
        border-radius: 12px;
        img,
        video {
          width: 120px;
          height: 120px;
          object-fit: cover;
          border-radius: 12px;
        }
        video {
          width: 125px;
          height: 125px;
        }
        b {
          margin-top: 12px;
          font-family: ClashGrotesk-Medium;
        }
      }
    }
    .scrollable {
      padding: 0px 45px;
      max-width: 100vw;
      .nft-card {
        margin: 0 4px;
      }
    }
    div[class^="Button"] {
      margin-top: 24px;
      margin-bottom: 24px;
      width: fit-content;
      min-width: 240px;

      button {
        width: fit-content;
        min-width: 240px;
      }
    }
  }
}
.is-closed-unlock-access {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 48px;
  padding-top: 100px;
  .raffle-img {
    width: 96px;
    height: 96px;
    margin-bottom: 56px;
    object-fit: cover;
    border-radius: 360px;
  }
  h1 {
    font-family: MicrogrammaStd-BoldExtended;
    color: inherit;
    text-align: center;
  }
  b {
    font-family: ClashGrotesk-Regular;
    font-weight: normal;
  }
  .powered-by {
    position: absolute;
    bottom: 90px;
  }
}
.error-unlock-access {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  svg {
    width: 65px;
    height: 65px;
    margin-bottom: 48px;
    path {
      fill: inherit !important;
    }
  }
  h1 {
    font-family: MicrogrammaStd-BoldExtended;
    color: inherit;
  }
  b {
    font-family: ClashGrotesk-Regular;
  }
  div[class^="Button"] {
    width: fit-content;
    min-width: 340px;
  }
}
