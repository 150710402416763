@import "../../App.scss";

.verify {
  .tap-here-anim {
    z-index: 0;
    position: absolute;
    left: -40px;
    top: 8px;
    path {
      fill: var(--otp-input-border-color);
      stroke: var(--otp-input-border-color);
    }
  }
  .relative-div {
    .otp-input {
      z-index: 1;
    }
    z-index: 1;
    position: relative;
  }
  .desktop-wrapper {
    background-color: var(--otp-background);
    filter: none;
  }
  .verify-otp-wrapper {
    background: linear-gradient(
      180deg,
      var(--otp-background) 0%,
      var(--otp-background) 100%
    );
    background-position-y: 0px;
    background-size: 1000% 1000%;
    height: 90vh;
    transition: all 350ms linear;
    padding-top: 30px;
    padding-left: 25px;
    h4 {
      margin-top: 45%;
      color: var(--otp-heading-1-color);
      font-family: var(--otp-heading-1-font);
      font-size: var(--otp-heading-1-font-size);
      width: var(--otp-heading-1-width);
      text-align: var(--otp-heading-1-align);
    }
    h6 {
      margin-top: -10px;
      color: var(--otp-heading-2-color);
      font-family: var(--otp-heading-2-font);
      font-size: var(--otp-heading-2-font-size);
      font-weight: 100;
      width: var(--otp-heading-2-width);
      margin-bottom: 20px;
    }
    .separator {
      width: 15px;
    }
    div {
      input[value=""] {
        border: 2px solid var(--otp-input-border-color) !important;
        background-color: transparent !important;
        border-radius: 14px;
        color: white;
        font-family: var(--otp-input-font) t;
        &:focus-visible {
          outline: none !important;
          color: var(--otp-input-border-color) !important;
          background-color: var(--otp-input-background-color) !important;
          border: 2px solid var(--otp-input-border-color) !important;
        }
      }
      input {
        border: 2px solid var(--otp-input-border-color) !important;
        background-color: var(--otp-input-background-color) !important;
        border-radius: 14px;
        color: var(--otp-input-border-color) !important;
        font-family: var(--otp-input-font) t;
        &:focus-visible {
          outline: none !important;
          color: var(--otp-input-border-color);
          border: 2px solid var(--otp-input-border-color) !important;
        }
        &:focus {
        }
      }
    }
    .resend-code {
      text-align: center;
      width: 87%;
      position: absolute;
      font-size: var(--otp-resend-code-font-size);
      cursor: pointer;
      bottom: 10%;
      color: var(--otp-resend-code-color);
      font-family: var(--otp-resend-code-font);
      text-decoration: underline;
    }
  }
  .success {
    background: linear-gradient(
      180deg,
      var(--otp-background) 0%,
      var(--otp-background) 100%
    );
    background-position-y: 0px;
    background-size: 100% 100%;
    div {
      input[value=""] {
        border: 2px solid #a6d67d !important;
        background-color: #a6d67d25 !important;
        border-radius: 14px;
        color: #a6d67d;
        &:focus-visible {
          outline: none !important;
          color: #a6d67d;
          background-color: rgba(255, 0, 0, 0.15) !important;
        }
      }
      input {
        border: 2px solid #a6d67d !important;
        background-color: #a6d67d25 !important;
        border-radius: 14px;
        color: #a6d67d;
        &:focus-visible {
          outline: none !important;
          color: #a6d67d;
        }
        &:focus {
        }
      }
    }
  }
  .fail {
    background: linear-gradient(
      180deg,
      var(--otp-background) 0%,
      var(--otp-background) 50%,
      var(--otp-background) 100%
    );
    background-position-y: 0px;
    background-size: 100% 100%;
    div {
      input[value=""] {
        border: 2px solid #da756b !important;
        background-color: #da746b38 !important;
        border-radius: 14px;
        color: #da756b;
        animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
        &:focus-visible {
          outline: none !important;
          color: #da756b;
          background-color: rgba(255, 0, 0, 0.15) !important;
        }
      }
      input {
        animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
        border: 2px solid #da756b !important;
        background-color: #da746b38 !important;
        border-radius: 14px;
        color: #da756b;
        &:focus-visible {
          outline: none !important;
          color: #da756b;
        }
        &:focus {
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .otp-input > input {
    width: 30px !important;
    height: 40px !important;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .verify-otp-wrapper {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 10%;
    width: 375px;
    height: 730px;
    overflow: hidden;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .verify-otp-wrapper {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 10%;
    width: 375px;
    height: 730px;
    overflow: hidden;
    // padding: 10px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .verify-otp-wrapper {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 5%;
    width: 375px;
    height: 730px;
    overflow: hidden;
    // padding: 10px;
    .landing-wrapper {
      padding-bottom: 25px;
      .text-wrapper {
        margin-top: 38vh;
      }
    }
  }
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
