.share-hotline {
  margin-top: 150px !important;
  width: 400px !important;
  .link-and-copy {
    display: flex;
    margin-top: 20px;
    align-items: center;
    b {
      width: calc(100% - 60px);
      overflow-y: auto;
    }
    input {
      border-radius: 60px;
      border: 1px solid #d1d1d6;
      padding: 12px 14px;
      width: 80%;
    }
    .next-btn {
      height: 48px;
      width: 72px;
      align-items: center;
      justify-content: center;
      display: flex;
      margin-left: 12px;
      border-radius: 60px;
      border: 1px solid black;
      background-color: #f3ec00;
      cursor: pointer;
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
  .ant-modal-content {
    border-radius: 20px;
    padding-bottom: 20px;
  }
  h3 {
    color: #18181b;
    font-size: 20px;
    font-family: MicrogrammaStd-BoldExtended;
    margin-bottom: 0px;
    margin-top: 20px;
  }
  b {
    width: calc(100% - 60px);
    color: #51525c;
    font-weight: 400;
    font-family: ClashGrotesk-Regular;
  }
  .share-tabs {
    display: flex;
    margin-top: 20px;
    .share-tab {
      background-color: #f4f4f5;
      margin-right: 20px;
      font-family: ClashGrotesk-Regular;
      font-size: 12px;
      padding: 2px 40px;
      transition: all 150ms ease-in;
      cursor: pointer;
      border-radius: 16px;
    }
    .active-share-tab {
      background-color: #18181b;
      color: #fcfcfc;
    }
  }
  .qr-part {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    img {
      width: 150px;
      height: 150px;
      object-fit: cover;
      margin: 20px 0px 12px 0px;
    }
    .qr-download {
      width: 154px;
      border-radius: 60px;
      height: 34px;
      border: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 150ms ease-in;
      svg {
        width: 24px;
        height: 24px;
      }
      &:hover {
        background-color: #f3ec00;
      }
    }
  }
  .copy-btn {
    border-radius: 60px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    cursor: pointer;
    transition: all 150ms ease-in;
    svg {
      width: 24px;
      height: 24px;
      path,
      line {
        transition: all 150ms ease-in;
      }
    }
    &:first-of-type {
      margin-left: 20px;
    }
    &:last-of-type {
      margin-left: 8px;
    }
    &:hover {
      background-color: #f3ec00;
    }
  }
}