.replies-list-item {
  height: 50px;
  display: flex;
  align-items: center;
  width: 95%;
  margin: 4px 0px;
  gap: 12px;
  padding: 6px;
  border-radius: 6px;
  position: relative;
  cursor: pointer;
  transition: all 150ms ease-in;
  &:hover {
    background-color: #e4e4e797;
  }
  .flag-img {
    width: 40px;
    height: 40px;
  }
  .replies-list-item-content {
    display: flex;
    flex-direction: column;
    width: 70%;
    justify-content: space-between;

    .replies-list-item-number {
      font-family: ClashGrotesk-SemiBold;
      font-size: 14px;
    }
    .replies-list-item-message {
      font-family: ClashGrotesk-Regular;
      font-size: 14px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 100%;
    }
  }
  .replies-list-item-badge {
    background-color: #5975ff;
    color: white;
    padding: 0px 6px;
    font-size: 12px;
    border-radius: 60px;
    line-height: 18px;
  }
  .replies-list-item-time {
    position: absolute;
    right: 10px;
    top: 0px;
    color: #787878;
  }
}
.replies-list-item-active {
  background-color: #e4e4e7;
  &:hover {
    background-color: #e4e4e7;
  }
}
