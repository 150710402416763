.wrapper {
  background-color: #f9f9f9;
  width: 100vw;
  height: calc(100vh - 68px);
  overflow: hidden;

  .row {
    height: 80%;
    overflow-y: auto;
    display: flex;
    width: 100%;
    padding-left: 15%;
    box-sizing: border-box;
    justify-content: space-between;
    padding-bottom: 5%;
    @media only screen and (max-width: 1024px) {
      padding-left: 8%;
    }
  }
  .signupForm {
    max-width: 800px;
    width: 90%;
    margin-top: 0.5%;

    h1 {
      font-family: MicrogrammaStd-BoldExtended;
      font-size: 20px;
      margin-bottom: 8px;
    }
    h6 {
      margin-top: 8px;
      font-family: "ClashGrotesk-Regular";
      font-weight: 500;
      width: 90%;
      font-size: 14px;
      margin-bottom: 40px;
    }
    @media only screen and (min-width: 768px) {
      width: 60%;
      h1 {
        font-size: 36px;
      }
      h6 {
        width: 85%;
        font-size: 16px;
      }
    }
  }
  .inputs {
    width: 95%;
    padding-bottom: 68px;
    button {
      margin-bottom: 40px;
    }
    .ClaimBtn {
      width: fit-content;
    }
    b {
      font-family: "NON-Natural-Grotesk-Regular";
      font-size: 14px;
      font-weight: normal;
      a {
        color: #5975ff;
        text-decoration: none;
      }
    }
    .explainDigits {
      font-family: ClashGrotesk-Regular;
      font-size: 14px;
      margin-top: 8px;
      margin-left: 4px;
    }
    .phoneNumberList {
      margin-top: 40px;
      display: flex;
      flex-wrap: wrap;
      @media only screen and (max-width: 1024px) {
        justify-content: center;
      }

      .phoneNrDiv {
        @media only screen and (min-width: 1024px) and (max-width: 1279px) {
          margin-right: 24px;
        }
        width: 33%;
        min-width: 200px;
        font-family: ClashGrotesk-Medium;
        letter-spacing: 0.8px;
        font-size: 18px;
        margin-bottom: 12px;
        label {
          margin-left: 8px;
          cursor: pointer;
        }
        input[type="radio"]:after {
          cursor: pointer;

          width: 15px;
          height: 15px;
          border-radius: 15px;
          top: -5px;
          left: -1px;
          position: relative;
          background-color: transparent;
          content: "";
          display: inline-block;
          visibility: visible;
          border: 1px solid rgba(0, 0, 0, 0.1);
          background-color: #f9f9f9;
        }

        input[type="radio"]:checked:after {
          cursor: pointer;
          width: 15px;
          height: 15px;
          border-radius: 15px;
          top: -5px;
          left: -1px;
          position: relative;
          background-color: black;
          content: "";
          display: inline-block;
          visibility: visible;
          border: 4.5px solid #f3ec00;
        }
      }
    }
  }
  .illustration {
    margin-top: 0%;
    padding-right: 8%;
    @media only screen and (max-width: 767px) {
      display: none;
    }
    .icon {
      display: flex;
      align-items: center;
      font-family: "NON-Natural-Grotesk-Regular";
      font-size: 18px;
      font-weight: bold;
      color: black;
      text-transform: none;
      margin-bottom: 8px;
      .lampBack {
        background-color: #f3ec00;
        border-radius: 60px;
        margin-right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
      }
    }
    .description {
      width: 300px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      font-family: "ClashGrotesk-Regular";
      font-weight: bold;
      text-transform: uppercase;
      color: #5975ff;
      font-size: 18px;
      text-align: right;
      margin-top: 20px;
      border-right: 8px solid #5975ff;
      padding-right: 24px;
      padding-bottom: 8px;
      padding-top: 8px;

      b {
        font-family: MicrogrammaStd-BoldExtended;
        text-transform: uppercase;
        font-size: 28px;
      }
    }
  }
}
