.delete-account-modal{
  width: 55% !important;
  .ant-modal-body{
    padding: 0px !important;
    
  }
  .wrapper {
    background-color: #f9f9f9;
    .row {
      display: flex;
      width: 100%;
     padding: 10%;
      box-sizing: border-box;
      flex-flow: wrap-reverse;
      @media only screen and (max-width: 1024px) {
        padding-left: 8%;
      }
    }
    .descText {
      margin-top: 5%;
      width: 80%;
      display: flex;
      flex-direction: column;
      justify-content: center;
  
      h1 {
        font-family: MicrogrammaStd-BoldExtended;
        font-size: 32px;
        margin-bottom: 12px;
        line-height: 32px;
      }
      h6 {
        margin-top: -8px;
        font-family: "ClashGrotesk-Regular";
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
      }
      @media only screen and (min-width: 768px) {
        width: 50%;
      }
    }
    .inputs {
      width: 100%;
      div[class^="Button"] {
        margin-top: 24px;
        margin-bottom: 24px;
      }
      button {
        width: fit-content;
      }
    }
    .illustration {
      margin-left: auto;
      width: 100%;
      img {
        max-width: 150px;
      }
      @media only screen and (min-width: 768px) {
        width: 320px;
        margin-left: 10%;
        margin-right: 0%;
        img {
          max-width: 320px;
        }
      }
    }
  }
  
}
