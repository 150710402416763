@import "../../App.scss";

.input-container {
  margin-top: 10px;
  width: 87%;
  height: var(--input-height);
  display: -ms-flexbox; /* IE10 */
  display: flex;
  border: 1px solid transparent;
  margin-bottom: 40px;
  background-color: var(--heading-1-color);
  font-family: ClashGrotesk-Medium;
  align-items: center;
  justify-content: space-between;
  border-radius: 60px;
  .icon-left {
    display: none;
  }
  .icon-right {
    display: none;
  }
  .right-icon-btn {
    border-radius: 60px;
    border: none;
    background-color: #e7e7e7;
    height: 36px;
    width: 60px;
    box-sizing: border-box;
    margin-right: 6px;
  }
}
.not-valid {
  // animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  // border: 2px solid #ed3c5c !important;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}
.icon-left {
  padding: 10px;
  margin-left: 8px;
  background: transparent;
  color: white;
  width: 20px;
  text-align: center;
  cursor: pointer;
}
.icon-right {
  padding: 10px;
  margin-right: 8px;
  background: transparent;
  color: white;
  width: 20px;
  text-align: center;
  cursor: pointer;
}

.input-field {
  width: 80%;
  padding: 10px;
  outline: none;
  background-color: transparent;
  border: 0px;
  font-family: var(--primary-font);
  color: var(--primary-color);
  padding-left: 20px;
  font-size: 18px;
}
.input-field::placeholder {
  color: var(--primary-color);
  font-family: var(--primary-font);
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
