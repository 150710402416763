@import "../../App.scss";

.insta-profile-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: -8px;
  z-index: 1;
  img {
    width: 28px;
    aspect-ratio: 1;
    border-radius: 50%;
    object-fit: cover;
    // border: 1.5px solid #fff;
  }
  h5 {
    padding: 0px;
    margin: 2px;
    margin-left: 5px;
    font-family: UniversNextPro-BoldExt;
    font-size: 13px;
    color: var(--primary-color);
    text-transform: lowercase;
  }
}
