@import "../../App.scss";

.polls-desktop-wrapper {
  background-image: linear-gradient(
    var(--poll-background-color),
    var(--poll-background-color) 70%,
    #fff 130%
  );
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  .polls-wrapper {
    background-image: linear-gradient(
      var(--poll-background-color),
      var(--poll-background-color) 70%,
      #fff 130%
    );
    position: relative;
    width: 95%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      font-family: var(--primary-font);
      color: var(--primary-color);
      width: 90%;
      text-align: center;
    }
    .poll-pic {
      position: absolute;
      top: 27%;
      width: 150%;
      height: 65%;
      object-fit: cover;
      animation: slideIn 0.3s ease-in-out;
    }
    .dynamic-button {
      z-index: 5;
      position: absolute;
      bottom: 140px;
    }
    .success-h1 {
      z-index: 1;
      margin-top: 35%;
    }
    .full-bleed {
      top: 0%;
      width: 120%;
      height: 100%;
      left: -10%;
      z-index: 0;
    }
    .img-overlay {
      content: ""; // ::before and ::after both require content
      position: absolute;
      top: 0;
      left: -10%;
      width: 120%;
      height: 100%;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.7) 120%
      );
    }
  }
  .absolute-navigator {
    position: absolute;
    width: 50%;
    height: 40vh;
    z-index: 3;
  }

  .next {
    right: 0px;
  }
  .prev {
    left: 0px;
  }
}

@media only screen and (min-width: 600px) {
  .polls-desktop-wrapper {
    display: flex;
    .polls-wrapper {
      background-image: none;
      display: flex;
      width: 375px;
      height: 730px;
    }
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(150%);
  }
  100% {
    transform: translateX(0);
  }
}
