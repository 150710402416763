.custom-question {
  border: 1px solid #d1d1d6;
  border-radius: 24px;
  margin-top: 24px;
  padding: 24px;
  .title-section {
    display: flex;
    align-items: center;
    .visibility {
      cursor: pointer;
      pointer-events: all;
    }
    .switch-container {
      .switchComponent {
        margin: 0px;
        // box-shadow: ;
        div[class^="react-switch-bg"] {
          border: 0px;
        }
        div[class^="react-switch-handle"] {
          transform: translateX(1px);
        }
      }
      .enabled-switch {
        div[class^="react-switch-handle"] {
          transform: translateX(19px) !important;
        }
        div[class^="react-switch-bg"] {
          border: none !important;
        }
      }
    }
    svg {
      width: 24px;
      height: 24px;
      margin-right: 12px;
    }
    .title-input {
      background-color: transparent;
      border: 1px solid #d1d1d6;
      height: 40px;
      font-family: ClashGrotesk-Regular;
      padding: 0px 12px;
      width: 68%;
      border-radius: 60px;
      margin-right: 12px;
    }
    .vertical-separator {
      height: 32px;
      width: 1px;
      background-color: #d1d1d6;
      margin-left: 12px;
      margin-right: 12px;
    }
  }
  .alternatives {
    padding: 5px;

    .alternative {
      display: flex;
      align-items: center;
      margin-top: 12px;
      justify-content: space-between;

      svg {
        width: 24px;
        height: 24px;
        margin-right: 12px;
      }
      .alternative-input {
        height: 40px;
        border: 1px solid #d1d1d6;
        margin-left: 12px;
        background-color: transparent;
        border-radius: 60px;
        padding: 0px 12px;
        width: 87% !important;
      }
      .radio-btn {
        width: 20px;
        height: 20px;
        border: 1px solid #d1d1d6;
        border-radius: 60px;
        background-color: #f4f4f5;
      }
      button {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        background-color: #ececed;
        border-radius: 60px;
        margin-left: 8px;
        cursor: pointer;
        svg {
          width: 15px;
          height: 15px;
          margin-right: 0px;
        }
      }
    }
    .to-add {
      justify-content: flex-start;
      button {
        width: 87% !important;
        text-align: left;
        display: flex;
        justify-content: flex-start;
        font-family: ClashGrotesk-Regular;
        font-size: 16px;
      }
    }
  }
}
.disabled-section {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}
