@import "../../App.scss";
.phone-number-wrapper {
  margin-top: 10px;
  position: relative;
  width: 100%;
  .country-code {
    color: var(--number-input-font-color);
    position: absolute;
    top: 50%;
    margin-top: -13px;
    font-family: var(--number-input-font);
    font-size: var(--number-input-font-size);
    left: 65px;
  }
  .PhoneInput {
    border: 2px solid var(--number-input-border-color);
    height: var(--input-height);
    border-radius: var(--border-radius);
    padding-left: 20px;
    background-color: var(--number-input-background-color);
    .PhoneInputInput {
      border-width: 0px !important;
      background-color: transparent;
      overflow: hidden;
      height: var(--input-height);
      color: var(--number-input-font-color) !important;
      -webkit-text-fill-color: var(--number-input-font-color) !important;
      width: 100%;
      font-family: var(--number-input-font);
      font-size: var(--number-input-font-size);
      line-height: var(--input-height);
      text-transform: uppercase;
      margin-left: 55px;
      display: flex;
      align-items: center;
      outline: none;
      padding-top: 0px !important;
      @media only screen and (max-width: 425px) {
        padding-top: 0px !important;
      }
      &::placeholder {
        font-size: var(--number-input-placeholder-font-size);
        color: var(--number-input-font-color);
        opacity: 0.5;
        text-transform: uppercase;
      }
      &:focus {
        outline: none;
        &::placeholder {
          opacity: 0;
        }
      }
    }

    .PhoneInputCountrySelectArrow {
      color: var(--number-input-font-color) !important;
      opacity: 1;
      opacity: 1 !important;
      display: block;
      content: "";
      width: var(--PhoneInputCountrySelectArrow-width) !important;
      height: var(--PhoneInputCountrySelectArrow-width) !important;
      margin-left: var(--PhoneInputCountrySelectArrow-marginLeft) !important;
      border-style: solid;
      border-color: var(--number-input-border-color);
      border-top-width: 0;
      border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
      border-left-width: 0;
      border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
      transform: var(--PhoneInputCountrySelectArrow-transform);
    }
  }
}
@media only screen and (min-width: 600px) {
  .PhoneInputInput {
  }
}
::-webkit-autofill,
::-webkit-autofill:hover,
::-webkit-autofill:focus,
::-webkit-autofill:active {
  -webkit-transition-delay: 9999s;
  -webkit-transition: color 9999s ease-out, background-color 9999s ease-out;
  transition: background-color 5000s ease-in-out 0s !important;
  -webkit-box-shadow: 0 0 0px 1000px #1b1b1b inset !important;
  -webkit-text-fill-color: white !important;
  background-color: transparent !important;
  background-clip: content-box !important;
  color: white !important;
  -webkit-appearance: none;
}

textarea,
input.text,
input[type="text"],
input[type="button"],
input[type="submit"],
input[type="tel"],
input[type="number"],
input[type="email"],
.input-checkbox {
  -webkit-appearance: none;
  border-radius: 0;
  background-color: transparent !important;
  color: var(--primary-color) !important;
}
