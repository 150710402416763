.golden-ticket {
  background: linear-gradient(315.37deg, #f3c270 0.74%, #ebbc6b 99.92%);
  box-shadow: -10px -10px 20px 2px rgba(102, 102, 102, 0.5),
    10px 10px 20px rgba(13, 13, 13, 0.5);
  border-radius: 8px;
  padding: 15px;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
    aspect-ratio: 1;
    object-fit: cover;
    border-radius: 4px;
  }
}
